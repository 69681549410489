<template>
  <div>
    <v-card
      class="mx-auto my-4"
      max-width="650"
      outlined
    >

      <v-card-title class="justify-center mb-0">
        <v-img
          height="50"
          class="mt-6 mb-0 op-75"
          contain
          :src="require('../assets/logoBlack.svg')"
        />
      </v-card-title>
      
      <v-card-title class="justify-center primary--text my-0">
        A web-app for faking a text messenger
      </v-card-title>

      <!-- VIDEO -->
      <v-card-title class="justify-center px-0">
        <video
          :controls="false"
          class="fill-width inline-block"
          autoplay muted playsinline loop
        >
          <source :src="require('../assets/illustrations/screencapture.mp4')" type="video/mp4">
        </video>
      </v-card-title>
      
      <v-card-text class="pa-4">
        <p>
          It's a utility for movie specialists, tailored to the needs of the <strong class="primary--text">art department</strong>.
          <br>
          It fakes an instant messaging app on mobile devices, and allows to <strong class="primary--text">pre-record</strong> a chat history and <strong class="primary--text">send instant messages to a prop device</strong>.
        </p>
        <p>
          <!-- It is a live chat system with pre recorded messages. -->
          You can reset the chat anytime (delete messages), instantly, and <strong class="primary--text">begin from the top</strong>.
        </p>

        <v-card-title class="justify-center pt-6 mt-6">
          <v-avatar size="55" color="secondary">
            <v-icon x-large class="white--text">mdi-television-classic</v-icon>
          </v-avatar>
        </v-card-title>
        <!-- COPY LEFT -->

        <p class="mb-12">
          And by the way, the GUI of this tool is <strong class="primary--text">not copyright protected</strong>.
          This  circumvents the problem of copyright infrightment when displaying off-the-shelf apps
          such as Whatsapp or the like in cinema or television.
        </p>

        <v-card-title class="justify-center px-0 pt-6">
        <img
          v-tooltip="'Standard color scheme'"
          width="33%"
          class="px-3"
          contain
          :src="require('../assets/illustrations/design1.png')"
        /><img
          v-tooltip="'Custom colors, group chat and image sending'"
          width="33%"
          class="px-3"
          contain
          :src="require('../assets/illustrations/design2.png')"
        /><img
          v-tooltip="'Background-image, emojis and hide the status bar and dates'"
          width="33%"
          class="px-3"
          contain
          :src="require('../assets/illustrations/design3.png')"
        />
      </v-card-title>


        <v-card-title class="justify-center pt-6 mt-6">
          <v-avatar size="55" color="secondary">
            <v-icon x-large class="white--text">mdi-cloud</v-icon>
          </v-avatar>
        </v-card-title>
        <!-- NO APP / INSTALLATION / WORKFLOW -->
        <p>
          This is <strong class="primary--text">browser-based</strong> (aka "cloud based"),
          so it works on most modern mobile devices: <strong class="primary--text">No installation necessary</strong>.
        </p>
        <p>
          All you need is time to set up the chat (a "convo") as per your script, generate an access code for it,
          visit this site with the prop device and type in the access code.
        </p>
        <p>
          You'll need an account for generating and control the chat,
          but receiving messages is <strong class="primary--text">without login</strong>,
          so no log-in on the prop device. Just enter the access code.
        </p>

        <v-card-title class="justify-center pt-6 mt-6">
          <v-avatar size="55" color="secondary">
            <v-icon x-large class="white--text mb-1">mdi-robot</v-icon>
          </v-avatar>
        </v-card-title>
        <!-- Roboter -->
        <p>
          The actress or the actor does not need to know how to type. Seriously.
          <br>
          The <strong class="primary--text">auto-type mechanic</strong> allows that he or she can type whatever,
          and the pre-recorded message appears on screen regardless of what was being typed.
          <br>
          So no failed takes because of typos.
        </p>

        <v-card-title class="justify-center pt-6 mt-6">
          <v-avatar size="55" color="secondary">
            <v-icon x-large class="white--text mb-1">mdi-cash-multiple</v-icon>
          </v-avatar>
        </v-card-title>
        <!-- MONEY -->
        <p>
          This service is <strong class="primary--text">free for low budget or independend productions</strong>.
          However, if you use this on bigger productions, please <a href="mailto:info@filmkulissen.ch" target="_blank" class="primary--text bolder">get in contact</a>.
          <strong class="primary--text">It's dirt cheap</strong>, compared to
          your nerves trying to tell whatsapp what to do.
          <br>
          But i'll need something. This is why you need an invitation.
        </p>

        <v-card-title class="justify-center pt-6 mt-6">
          <v-avatar size="55" color="secondary">
            <v-icon x-large class="white--text mb-1">mdi-alert</v-icon>
          </v-avatar>
        </v-card-title>
        <!-- WARNING -->
        <p>
          As this is fairly new, it is not thoroughly tested on many devices.
          Please, for the love of science, <strong class="primary--text">play it through on the correct devices
          before you step foot with this on set</strong>.
          On any arising problem, there is an immediate solution:
          <a href="mailto:info@filmkulissen.ch" target="_blank" class="primary--text bolder">Please contact me</a>.
        </p>
        
        <v-divider class="my-6 py-6" inset></v-divider>

        <p>
          Any questions? <a href="mailto:info@filmkulissen.ch" target="_blank" class="primary--text bolder">Get in touch</a>.
        </p>

        <!-- A <strong class="primary--text">Convo</strong> <v-icon color="primary">mdi-frequently-asked-questions</v-icon> is
        one interaction between two or more <strong class="primary--text">Characters</strong> <v-icon color="primary">mdi-account-cowboy-hat</v-icon>.
        It can be viewed from every Characters point of view and be controlled over this website. -->
      </v-card-text>
    </v-card>

  </div>
</template>

<script>
  export default {
    name: 'About',
    
    data () {
      return {
      }
    },

    methods: {
    },
  }
</script>

