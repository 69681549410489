<template>
  <div>
    <!-- SIGNUP FORM -->
    <v-card
      class="mx-auto my-4 pa-4"
      max-width="344"
      outlined
    >
      <v-card-title class="justify-center">Sign up</v-card-title>
      <v-card-text v-if="!this.$route.query.keycode">You need an invitation to get a key code. Don't have an invitation?
        <a :href="'mailto:info@filmkulissen.ch?subject=Invite%20me%20to%20CONVO&body='+mailText">Ask for one.</a>
        <br>
        (<a :href="'mailto:info@filmkulissen.ch?subject=This%20Blows&body='+this.$helpers.toUrlText('Dear Fabian.\n\nThis blows. Please Make this tool available for all.')">This blows</a>)

      </v-card-text>
      <v-form @submit.prevent v-model="valid">
          <v-text-field
            filled background-color="grey lighten-3"
            dense
            v-model.trim="signupForm.keycode"
            type="text"
            placeholder="Keycode"
            id="name"
            :rules="notEmpty"
          ></v-text-field>

          <v-text-field
            filled background-color="grey lighten-3"
            dense
            v-model.trim="signupForm.name"
            type="text"
            placeholder="Name"
            id="title"
            autocomplete="name"
            :rules="notEmpty"
          ></v-text-field>

          <v-text-field
            filled background-color="grey lighten-3"
            dense
            v-model.trim="signupForm.email"
            type="text"
            placeholder="you@email.com"
            id="email2"
            autocomplete="email"
            autocapitalize="off"
            :rules="notEmpty"
          ></v-text-field>

          <v-text-field
            filled background-color="grey lighten-3"
            dense
            v-model.trim="signupForm.password"
            type="password"
            placeholder="Password"
            hint="Minimum 8 characters"
            persistent-hint
            id="password2"
            autocomplete="new-password"
            :rules="passwordRules"
          ></v-text-field>

          <v-card-actions class="pl-0">
            <v-spacer></v-spacer>
              <v-btn type="submit" :loading="loading" :disabled="!valid" color="primary" @click="signup()" rounded>Sign me Up</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>

          <!-- ERROR HANDLING -->
          <div v-if="error" class="error--text caption">
            {{error}}
          </div>
          
          <span class="caption grey--text">
            <router-link to="/login">Back to Log In</router-link>
          </span>
      </v-form>
    </v-card>

  </div>
</template>

<script>
  export default {
    name: 'SignUp',
    
    data () {
      return {
        error: '',
        valid: false,
        loading: false,
        signupForm: {
          name: '',
          keycode: this.$route.query.keycode ? this.$route.query.keycode : '',
          email: this.$route.query.email ? this.$route.query.email : '',
          password: ''
        },
        notEmpty: [
          v => !!v || 'This is required',
        ],
        veryBadPasswords: [
          "123456", "password", "12345678", "qwerty", "12345", "123456789", "letmein", "1234567", "football",
          "iloveyou", "admin", "welcome", "monkey", "login", "abc123", "starwars", "123123", "dragon", "passw0rd",
          "master's degree", "hello", "freedom", "whatever", "qazwsx", "trustno1", "654321", "jordan23", "password1",
          "1234", "robert", "matthew", "jordan", "asshole", "daniel", "andrew", "lakers", "andrea", "buster",
          "joshwa", "1qaz2wsx", "12341234", "ferrari", "cheese", "computer", "corvette", "blahblah", "george",
          "mercedes", "121212", "maverick", "fuckyou", "nicole", "hunter", "sunshine", "tigger", "1989", "merlin",
          "ranger", "solo", "banana", "chelsea", "summer", "1990", "1991", "phoenix", "amanda", "cookie", "ashley",
          "bandit", "killer", "meandyou", "pepper", "jessica", "zaq1zaq1", "jennifer", "test", "hockey", "dallas",
          "password", "fuckyouasshole", "admin123", "pussy", "pass", "asdf", "william", "soccer", "london", "1q2w3e",
          "1992", "biteme", "maggie", "querty", "rangers", "charlie", "martin", "ginger", "yankees", "thunder",
          "Michelle", "aaaaaa"],
        passwordRules: [
          v => !!v || 'A password is required',
          v => v !== '123456' || 'What can I say. This cannot possibly be your password, ever, anywhere - its the number one of most used passwords.',
          v => v.toLowerCase() !== 'password' || 'Seriously? This cannot be your password, ever, anywhere - its the number two of most used passwords.',
          v => v.toLowerCase() !== 'maga2020!' || 'Donald Trump, please go somewhere else. And change your password from time to time. Or, you know, don\'t.',
          v => v.toLowerCase() !== 'admin' || 'I like your thinking. But no.',
          v => v.toLowerCase() !== 'root' || 'I like your thinking. But no.',
          v => !this.veryBadPasswords.includes(v.toLowerCase()) || `This has place ${this.veryBadPasswords.indexOf(v) + 1} of the 100 most used passwords ever. Please choose something safe.`,
          v => v.length >= 8 || 'Password must be longer than 8 characters.',
          v => !/^(.)\1*$/.test(v) || 'Password cannot be only one repeated character.',
        ],
      }
    },

    computed: {
      baseUrl() {
        return window.location.protocol + "//" + window.location.host + '/#'
      },
      mailText() {
        return this.$helpers.toUrlText('Hello Fabian!\n\nI\'d like an invitation to '+this.baseUrl+'. And why do I need this? Is it for commercial use?\nThanks!\n\nYou\'re the best.\nxoxo');
      },
    },

    watch: {
    },

    methods: {
      signup() {
        this.loading = true;
        //console.log(this.signupForm);
        this.$store.dispatch('signup', {
          email: this.signupForm.email,
          password: this.signupForm.password,
          name: this.signupForm.name,
          keycode: this.signupForm.keycode
        }).then(() => {
          this.loading = false;
          console.log('Successful signup.')
          this.error = '';
        }).catch(error => {
          this.loading = false;
          console.log(error);
          console.error(error.message);
          this.error = error.message;
        });
      }
    },

  }
</script>

