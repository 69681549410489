<template>
  <!-- EDIT DIALOG -->
  <v-card v-model="modalDialog"
    class="fixed bottom text-left left bottom pa-2"
    :class="$vuetify.breakpoint.mdAndUp ? 'x-40p mx-2' : 'fill-width'"
    :style="{'marginBottom': $vuetify.breakpoint.smAndDown ? '': '235px'}"
    style="z-index:9999;"
  > 

    <!-- <pre>{{message}}</pre> -->

    <v-card-actions class="pa-0">

      <v-btn-toggle :dense="$vuetify.breakpoint.mdAndUp"  multiple>
        <!-- Sent -->
        <v-btn
          v-if="!isTyping"
          tile
          class="elevation-0 grey lighten-2 ma-0 pa-0"
          :style="{'minWidth': $vuetify.breakpoint.mdAndDown ? '35px': ''}"
          v-tooltip="message.sent ? 'Unsend': 'Send'"
          :disabled="sendMessageImmedietly && !isTyping"
          @click="editMessage('sent', !message.sent)"
          @dblclick="[sendMessageImmedietly = true, setTypeIndicator(false), editMessage('sent', true)]"
        >
          <v-icon class="ma-0 pa-0 maxx-30" :color="message.sent ? '' : 'green'">
            {{message.sent ? 'mdi-message-bulleted-off' : 'mdi-send'}}
          </v-icon>
        </v-btn>
        <v-btn v-else v-tooltip="'Send immediately'" icon class="grey darken-1 white--text"
          @click="[sendMessageImmedietly = true, setTypeIndicator(false), editMessage('sent', true)]"
        >
          <div class="dot-typing inline-block op-50 mb-0"></div>
        </v-btn>

        <!-- Read -->
        <v-btn
          v-tooltip="'Check message as \'read\''" tile
          class="elevation-0 grey lighten-2"
          :style="{'minWidth': $vuetify.breakpoint.mdAndDown ? '35px': ''}"
          @click="editMessage('seen', !message.seen)"
        >
          <v-icon :color="message.seen ? 'green' : ''">
            {{message.seen ? 'mdi-check-all' : 'mdi-check-bold'}}
          </v-icon>
        </v-btn>

        <!-- Edit image -->
        <ImageUpload
          v-tooltip="message.image ? 'Change image' : 'Upload image'"
          class="inline-block"
          btnClasses="grey lighten-2 ma-0 elevation-0 tile"
          :tile="true"
          labelClasses="grey--text text--darken-2 body-1"
          :labelText="''"
          :imagesOnly="true"
          icon="mdi-paperclip"
          :maxFileSizeMb="8"
          :imageSize="200"
          @base64="saveImage($event)"
          @error="errorImageUpload = $event.message"
        ></ImageUpload>

        <!-- Lock history -->
        <v-btn
          v-tooltip="'Set History anchor.<br>When you unsend messages, this is the last message not to be removed.'"
          tile
          class="elevation-0 grey lighten-2"
          :style="{'minWidth': $vuetify.breakpoint.mdAndDown ? '35px': ''}"
          @click="editMessage('lockHistory', !message.lockHistory)"
        >
          <v-icon :color="message.lockHistory ? 'green' : ''">mdi-anchor</v-icon>
        </v-btn>

        <!-- Edit date -->
        <v-btn
          v-if="showDates"
          v-tooltip="'Edit Date'" tile class="elevation-0 grey lighten-2"
          :style="{'minWidth': $vuetify.breakpoint.mdAndDown ? '35px': ''}"
          @click="editDate = !editDate"
        >
          <v-icon>mdi-calendar-refresh</v-icon>
        </v-btn>

        <!-- Delete message -->
        <v-btn v-tooltip="{ content: 'Delete this message', classes: 'error' }" tile
          class="error white--text"
          :style="{'minWidth': $vuetify.breakpoint.mdAndDown ? '35px': ''}"
          @click="editMessage('disabled', true)"
        >
          <v-icon>mdi-trash-can</v-icon>
        </v-btn>
      </v-btn-toggle>

      <v-spacer></v-spacer>

      <!-- Close dialog btn -->
      <v-btn v-tooltip="'Close dialog'"
        icon small x-small class="black--text ml-2 mb-3 pa-0"
        @click="closeDialog()"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>

    </v-card-actions>

    <!-- Date change details -->
    <v-card-title v-if="editDate" class="justify-center body-1 my-2 pa-0 grey lighten-2 rounded relative">
      <DateEdit
        label=""
        btnColor="grey lighten-3"
        btnTextColor="black--text"
        class="py-2"
        :lastDate="this.$moment.unix(message.sentDate.seconds)"
        :refDay="refDay"
        @newDate="delaySaving('sentDate', $event.fbTimestamp)"
      />
    </v-card-title>

    <p v-if="errorImageUpload" class="my-3 pl-0 error--text">
      {{errorImageUpload}}
    </p>

    <!-- IMAGE DISPLAY -->
    <v-img
      v-if="message.image"
      class="grey my-2"
      :src="message.image"
      color=""
      contain
      height="120"
    >
      <v-btn v-tooltip="'Remove image'"
        fab x-small absolute top right class="black--text mt-7 mr-0 pa-0"
        @click="[message.image='', saveImage('')]"
      ><v-icon small>mdi-trash-can</v-icon>
      </v-btn>
    </v-img >

    <!-- EDIT MESSAGE -->
    <v-textarea
      filled
      class="my-2 maxy-300 scroll-y"
      auto-grow
      rows="1"
      hide-details
      v-model="message.message"
      name="Message"
      label="Message text"
      :value="message.message"
      @input="delaySaving('message', message.message)"
      :rules="notEmpty"
    ></v-textarea>

    <!-- Change sender -->
    <v-radio-group label="Sent by:" hide-details row class="ml-1 mt-0 mb-1 inline-block" v-model="message.character" @change="editMessage('character', message.character)">
      <v-radio
        mandatory
        v-for="character in characters"
        :key="character.uid"
        :label="character.name"
        :value="character.uid"
        :selected="character.uid === message.character"
      ></v-radio>
    </v-radio-group>
  </v-card>
</template>

<script>
import ImageUpload from '@/components/ImageUpload'
import DateEdit from '@/components/DateEdit'

export default {
  name: 'MessageEditDialog',
  props: {
    message: Object,
    convoId: String,
    abortMessageSending: String,
    refDay: Object,
    showTypeIndicator: Boolean,
    showDates: Boolean,
    characters: Array,
    lastElementWithHistoryLock: String,
  },
  components: {
    ImageUpload, DateEdit
  },
  data () {
    return {
      modalDialog: true,
      isTyping: false,
      waitTosafe: false,
      editDate: false,
      errorImageUpload: '',
      sendMessageImmedietly: false,
      lastPromise: null,
      notEmpty: [
        v => !!v || 'This is required',
      ],
    }
  },
  computed: {
  },
  watch: {
    modalDialog() {
      if(!this.modalDialog) this.$emit('closeDialog')
    },
    abortMessageSending() {
      this.lastPromise = null;
    }
  },
  created() {
  },
  beforeDestroy() {
    // if message was sent with typeindicator but window was closed
    this.lastPromise = null;
  },
  methods: {
    delaySaving(key, value){
      clearTimeout(this.waitTosafe)
      let that = this;
      this.waitTosafe = setTimeout(function() {
        that.editMessage(key, value)
      }, 1000);
    },

    saveImage(base64) {
      this.message.image = base64;
      this.errorImageUpload='';
      this.editMessage('image', base64)
    },

    closeDialog() {
      /* this.modalDialog = false */
      this.$emit('closeDialog')
    },

    async editMessage(key, value) {
      // Show type indicator for sent message from history
      if(key === 'sent' && this.showTypeIndicator && !this.sendMessageImmedietly && value === true) {
        const promise = this.displayTypeIndicator(this.message.message.length);
        this.lastPromise = promise // promise;
        await new Promise( ( resolve ) => {
          promise.then( result => {
            // if this sending is not overlapping with another sending
            if ( this.lastPromise === promise ) {
              this.lastPromise = promise;
              resolve(result);
            } else {
              // reject if message was sendMessageImmedietly and cancelled before typing would be finished
              return
            }
          } );
        } );
      } else if(key === 'sent' && this.showTypeIndicator && !this.sendMessageImmedietly && value === false) {
        this.lastPromise = null;
      }

      this.message[key] = value;

      // console.log("dispatch:")
      // Write data to DB
      this.$store.dispatch('mergeData', {
        'collection': 'convos/'+this.convoId+'/messenger/',
          'document': this.message.id,
          'post': {[key]: value}
      }).then(() => {
        if(this.sendMessageImmedietly) this.sendMessageImmedietly = false;
        if(key === 'disabled' && value) this.closeDialog();
        // this.$nextTick(() => {
          //console.log("dispatch success 'sent':", this.message.sent)
          //this.$emit('saveDialog', this.message);
        // })
        // If message was removed, close dialog
      });
    },

    // SHOW TYPE INDICATOR
    async displayTypeIndicator(lengthOfText) {
      this.setTypeIndicator(true);
      let typeDelay = lengthOfText * 0.1 * 1000;  // realistic 0.3
      /* console.log("typeDelay ", lengthOfText, typeDelay) */
      await this.$helpers.sleep(typeDelay);
      this.setTypeIndicator(false);
    },

    setTypeIndicator(value) {
      this.isTyping = value;
      this.$store.dispatch('mergeData', {
          'collection': 'convos/',
          'document': this.convoId,
          'post': {'settings': {'typing': value}}
      })        
    },
  },
}
</script>