<template>
    <v-system-bar dark color="black" class="fill-width">
      <span v-if="settingsSystemBar.time.length" class="pr-1">{{settingsSystemBar.time}}</span>
      <span v-if="settingsSystemBar.provider" class="pr-1 ellipsis">{{settingsSystemBar.provider}}</span>
      <v-spacer></v-spacer>

      <span v-if="settingsSystemBar.email" class="pr-1">
        <v-icon v-if="settingsSystemBar.email == 1">mdi-email</v-icon>
        <span v-else-if="settingsSystemBar.email > 1">
          <v-icon>mdi-email-multiple</v-icon>
          {{settingsSystemBar.email}}
        </span>
      </span>

      <span v-if="settingsSystemBar.sms >= 1" class="pr-1">
        <v-icon>mdi-android-messages</v-icon>
        <span v-if="settingsSystemBar.sms > 1">
          {{settingsSystemBar.sms}}
        </span>
      </span>

      <v-icon v-if="settingsSystemBar.unansweredCall" class="red--text">mdi-phone-missed</v-icon>

      <span v-if="settingsSystemBar.message" class="pr-1 ellipsis">{{settingsSystemBar.message}}</span>
      <v-spacer></v-spacer>

      <!-- mdi-wifi-strength-1 bis 4, 0 für mdi-wifi-strength-alert-outline -->
      <v-icon>{{settingsSystemBar.wlan > 0 ? 'mdi-wifi-strength-'+settingsSystemBar.wlan : 'mdi-wifi-strength-alert-outline'}}</v-icon>

      <!-- mdi-network-strength-1 bis 4, und 0 für mdi-network-strength-off-outline -->
      <v-icon>{{settingsSystemBar.network > 0 ? 'mdi-network-strength-'+settingsSystemBar.network : 'mdi-network-strength-off-outline'}}</v-icon>

      <v-icon
        :class="[settingsSystemBar.battery <= 8 ? 'red--text' : '', settingsSystemBar.battery > 100 ? 'green--text' : '']"
      >
        {{displayBattery(settingsSystemBar.battery)}}
      </v-icon>
      <!-- <v-icon v-if="settingsSystemBar">mdi-battery</v-icon> -->
      <span
        v-if="settingsSystemBar.battery <= 100"
        :class="settingsSystemBar.battery <= 8 ? 'red--text' : ''"
      >{{settingsSystemBar.battery}}%</span>
    </v-system-bar>
</template>

<script>
export default {
  name: 'SystemBar',
  props: {
    settingsSystemBar: Object,
  },

  data () {
    return {
    }
  },
  watch: {
  },
  created() {
  },
  methods: {
    displayBattery(value){
      let icon = '';
      if(value > 100) {  // charging
        icon = 'mdi-battery-charging-50';
      } else if(value > 90) {  // 90-100%
        icon = 'mdi-battery';
      } else if(value > 80) {
        icon = 'mdi-battery-90'
      } else if(value > 70) {
        icon = 'mdi-battery-80'
      } else if(value > 60) {
        icon = 'mdi-battery-70'
      } else if(value > 50) {
        icon = 'mdi-battery-60'
      } else if(value > 40) {
        icon = 'mdi-battery-50'
      } else if(value > 30) {
        icon = 'mdi-battery-40'
      } else if(value > 20) {
        icon = 'mdi-battery-30'
      } else if(value > 10) {
        icon = 'mdi-battery-20'
      } else if(value >= 0) {
        icon = 'mdi-battery-10'  // red below 8
      }
      return icon
    },
  },
}
</script>