<template>
  <div >
    <v-btn-toggle class="mx-2" dense>
        <!-- Send / Unsend -->
        <v-btn
          v-if="!isTyping"
          v-tooltip="message.sent ? 'Unsend': 'Send'"
          icon
          class="grey darken-1 white--text"
          @click="editMessage('sent', !message.sent)"
          @dblclick="[sendMessageImmedietly = true, setTypeIndicator(false), editMessage('sent', true)]"
        >
          <v-icon :color="message.sent ? '' : 'green'">
            {{message.sent ? 'mdi-message-bulleted-off' : 'mdi-send'}}
          </v-icon>
        </v-btn>
        <v-btn v-else v-tooltip="'Send immediately'" icon class="grey darken-1 white--text"
          @click="[sendMessageImmedietly = true, setTypeIndicator(false), editMessage('sent', true)]"
        >
          <div class="dot-typing inline-block op-50 mb-0"></div>
        </v-btn>

        <!-- Set "check for read" -->
        <v-btn
          v-tooltip="message.seen ? 'Mark message as \'read\'' : 'Mark message as \'unread\''"
          icon
          class="grey darken-1 white--text"
          @click="editMessage('seen', !message.seen)"
        >
          <v-icon :color="message.seen ? 'green' : ''">
            {{message.seen ? 'mdi-check-all' : 'mdi-check-bold'}}
          </v-icon>
        </v-btn>

        <!-- HOME LOCK if is already set -->
        <v-btn
          v-if="message.lockHistory && lastElementWithHistoryLock === message.id"
          v-tooltip="'History anchor is set here.<br>When you unsend messages, this is the last message not to be removed.'"
          icon
          class="grey darken-1 white--text"
          :class="message.lockHistory ? 'green--text' : ''"
          @click="editMessage('lockHistory', !message.lockHistory)"
        >
          <v-icon color="green">mdi-anchor</v-icon>
        </v-btn>

        <!-- move up and down -->
        <v-btn
          v-if="!showDates && prevMessageSentDate.seconds"
          v-tooltip="'Move message up'"
          icon
          class="grey darken-1 white--text"
          @click="moveMessage(-1, prevMessageSentDate)"
        >
          <v-icon>mdi-arrow-up-bold</v-icon>
        </v-btn>
        <v-btn
          v-if="!showDates && nextMessageSentDate.seconds"
          v-tooltip="'Move message down'"
          icon
          class="grey darken-1 white--text"
          @click="moveMessage(1, nextMessageSentDate)"
        >
          <v-icon>mdi-arrow-down-bold</v-icon>
        </v-btn>

        <!-- Go to deeper settings -->
        <v-btn v-tooltip="'Edit & details...'" icon class="grey darken-1 white--text" @click="showDialog()">
          <v-icon>mdi-cog</v-icon>
        </v-btn>

        <!-- Delete this message -->
        <!-- <v-btn v-tooltip="'Delete this message'" icon class="grey darken-1 error white--text" @click="editMessage('disabled', true)"> -->
        <v-btn
          v-tooltip="{ content: 'Delete this message', classes: 'error' }"
          icon
          class="grey darken-1 error white--text"
          @click="editMessage('disabled', true)"
        >
          <v-icon>mdi-trash-can</v-icon>
        </v-btn>
      </v-btn-toggle>

      <!-- <v-icon v-if="message.lockHistory" large class="op-50">mdi-anchor</v-icon> -->


  </div>
</template>

<script>
export default {
  name: 'MessageEdit',
  props: {
    message: Object,
    convoId: String,
    abortMessageSending: String,
    showTypeIndicator: Boolean,
    showDates: Boolean,
    characters: Array,
    lastElementWithHistoryLock: String,
    nextMessageSentDate: Object,
    prevMessageSentDate: Object,
  },

  data () {
    return {
      isTyping: false,
      sendMessageImmedietly: false,
      lastPromise: null,
    }
  },

  watch: {
    abortMessageSending() {
      this.lastPromise = null;
    }
  },

  created() {
  },

  methods: {
    showDialog() {
      let messageData = {
        'display': true,
        'message': this.message,
      }
      this.$emit('openEditDialog', messageData);
    },

    async editMessage(key, value) {
      // Show type indicator for sent message from history
      if(key === 'sent' && this.showTypeIndicator && !this.sendMessageImmedietly && value === true) {
        const promise = this.displayTypeIndicator(this.message.message.length);
        this.lastPromise = promise // promise;
        await new Promise( ( resolve ) => {
          promise.then( result => {
            // if this sending is not overlapping with another sending
            if ( this.lastPromise === promise ) {
              this.lastPromise = promise;
              resolve(result);
            } else {
              // reject if message was sendMessageImmedietly and cancelled before typing would be finished
              return
            }
          } );
        } );
      } else if(key === 'sent' && this.showTypeIndicator && !this.sendMessageImmedietly && value === false) {
        this.lastPromise = null;
      }

      // Write data to DB
      this.$store.dispatch('mergeData', {
          'collection': 'convos/'+this.convoId+'/messenger/',
          'document': this.message.id,
          'post': {[key]: value}
      }).then(() => {
        if(this.sendMessageImmedietly) this.sendMessageImmedietly = false;
      })
    },

    moveMessage(direction, adjacentMessageSentDate) {
      let newDate = adjacentMessageSentDate.seconds + direction;
      let fbDate = this.$helpers.restoreFbTimestampFromJsDate({'seconds': newDate})
      this.editMessage('sentDate', fbDate)
    },

    // SHOW TYPE INDICATOR
    async displayTypeIndicator(lengthOfText) {
      this.setTypeIndicator(true);
      let typeDelay = lengthOfText * 0.1 * 1000;  // realistic 0.3
      /* console.log("typeDelay ", lengthOfText, typeDelay) */
      //while(this.isTyping) {
        await this.$helpers.sleep(typeDelay);
        this.setTypeIndicator(false);
      //}
    },

    setTypeIndicator(value) {
      this.isTyping = value;
      this.$store.dispatch('mergeData', {
          'collection': 'convos/',
          'document': this.convoId,
          'post': {'settings': {'typing': value}}
      })        
    },
  }
}
</script>

<style scoped>
</style>