// General
import Vue from 'vue'
import VueRouter from 'vue-router'
import { auth } from '../firebase'

// Views
import Home from '../views/Home.vue'
import Messenger from '../views/Messenger.vue'
import Rabbithole from '../views/Rabbithole.vue'
import Dashboard from '../views/Dashboard.vue'
import Controller from '../views/Controller.vue'
import About from '../views/About.vue'
import License from '../views/License.vue'
import Login from '../views/Login.vue'
import Signup from '../views/Signup.vue'
import Profile from '../views/Profile.vue'
import ForgotPassword from '../views/ForgotPassword.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      fullscreen: true,
    }
  },
  {
    path: '/rabbithole',
    name: 'Rabbithole',
    component: Rabbithole,
    meta: {
      footer: true,
    }
  },
  {
    path: '/messenger',
    name: 'Messenger',
    component: Messenger,
    meta: {
      hideNav: true,
      fullscreen: true,
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
      footer: true,
    }
  },
  {
    path: '/controller',
    name: 'Controller',
    component: Controller,
    meta: {
      fullscreen: true,
      requiresAuth: true,
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      requiresAuth: true,
      footer: true,
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      footer: true,
    }
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: {
      footer: true,
    }
  },
  {
    path: '/license',
    name: 'License',
    component: License,
    meta: {
      footer: true,
    }
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
    meta: {
      footer: true,
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      footer: true,
    }
  },
]

const router = new VueRouter({
  // 'history' for clean url but hard refresh needs special treatment. use in sub- or root domain.
  //    This also does not allow for app being deployed in subfolder eg. url.com/convo, would
  //    need "RewriteEngine On" .htaccess file: https://router.vuejs.org/guide/essentials/history-mode.html
  // mode: 'history',
  // 'hash' adds a # in the url and allows for deployment in subfolder and does not need special URL rewrite treatment
  mode: 'hash',  
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  if (requiresAuth && !auth.currentUser) {
    next('/login?error=authRequiered')
  } else {
    next()
  }
})

export default router
