import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { auth } from "./firebase.js";  ///// httpsCallable ????
import vuetify from "./plugins/vuetify";
import VueMoment from "vue-moment"; // Time calculation
import "../static/vuetify-additions.css";
import "../static/convo.css";
import helpers from "./assets/helpers"; // Use global helper functions
import Toasted from "vue-toasted"; // alerts & notifications https://github.com/shakee93/vue-toasted
import RandomWords from "random-words";  // https://www.npmjs.com/package/random-words
import VTooltip from 'v-tooltip'  // https://www.npmjs.com/package/v-tooltip
import "../static/tooltip.css";
import VueClipboard from 'vue-clipboard2'
import device from "vue-device-detector"  // https://www.npmjs.com/package/vue-device-detector

// add js helpers to use like this.$helpers.textTruncate(deleteDialog.project.name, 20)
const jsHelpers = {
  install() {
    Vue.helpers = helpers;
    Vue.prototype.$helpers = helpers;
    Vue.randomWords = RandomWords;
    Vue.prototype.$randomWords = RandomWords;
  },
};

Vue.use(jsHelpers);
Vue.use(VueMoment);
Vue.use(VTooltip, {defaultOffset: 4, defaultDelay: 700})
Vue.use(VueClipboard)
Vue.use(device)

Vue.config.productionTip = false;  

// DEFINE ALERTS
Vue.use(Toasted, {
  iconPack: "mdi",
  keepOnHover: true,
  className: "roboto weight_normal",  //grayscale  rabbitholeBackground
  containerClass: "",
});
// success
Vue.toasted.register("success", (payload) => {
    if (!payload.msg) { return "Success!"; } else { return payload.msg; }
  },{ type: "success", icon: "mdi-checkbox-marked-circle", duration: 4000, className: "roboto weight_normal successToast"}
);
// info
Vue.toasted.register("info", (payload) => {
    if (!payload.msg) { return "Info"; } else { return payload.msg; }
  },  { type: "info", icon: "mdi-information", duration: 6000, className: "roboto weight_normal infoToast"}  //, className: "className"
);
// error
Vue.toasted.register("error", (payload) => {
    if (!payload.msg) { return "Something Went Wrong. I can feel it."; } else { return payload.msg; }
  },{ type: "error", icon: "mdi-alert-circle", className: "roboto weight_normal errorToast", action : {
      text : 'CLOSE', onClick : (e, toastObject) => { toastObject.goAway(0); }
  },}
);

let app;
auth.onAuthStateChanged((user) => {
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount("#app");
  }

  // if hard reload, fetch logged in user
  if (user) {
    store.dispatch("fetchUserProfile", user);
  }
});
