<template>
  <div>
    <!-- WELCOME DIALOG -->
    <WelcomeDialog v-if="welcomeDialog" :user="user"/>

    <div class="text-h4 my-4">Dashboard</div>

    <div class="text-h6 my-4">Create a new convo</div>
    <p class="maxx-600">
      A <strong class="primary--text">Convo</strong> <v-icon color="primary">mdi-frequently-asked-questions</v-icon> is
      one chat conversation between two or more
      <strong class="primary--text">Characters</strong> <v-icon color="primary">mdi-account-cowboy-hat</v-icon>.
    </p>
    <!-- <p class="maxx-600">
      A <strong class="primary--text">Convo</strong> <v-icon color="primary">mdi-frequently-asked-questions</v-icon> is
      one interaction between two or more <strong class="primary--text">Characters</strong> <v-icon color="primary">mdi-account-cowboy-hat</v-icon>.
      It can be viewed from every Characters point of view and be managed with the controller on this website.
    </p> -->
    <p>
      <v-btn color="primary" class="mr-3" @click="addNewConvo = true" rounded>
        + Create a convo
      </v-btn>
      <v-btn v-if="addNewConvo" color="grey lighten-3" @click="[addNewConvo = false, duplicateConvo = {}]">
        Cancel
      </v-btn>
    </p>

    <NewAndEditConvo v-if="addNewConvo" :editConvoData="duplicateConvo" :auth="auth" :user="user"/>

    <v-divider class="my-6 py-6 op-0"></v-divider>
    <v-divider class="my-6 py-6 mx-16"></v-divider>
    <v-divider class="my-6 py-6 op-0"></v-divider>

    <div class="text-h6 my-4" id="listConvos">Convo Controller & Messenger</div>
    <p class="maxx-600">
      Every <strong class="primary--text">Convo</strong> <v-icon color="primary">mdi-frequently-asked-questions</v-icon>
      has a <strong class="primary--text">Convo Controller</strong> <v-icon color="primary">mdi-podcast</v-icon>
      and a <strong class="secondary--text">Convo Messenger</strong> <v-icon color="secondary">mdi-cellphone-nfc</v-icon>.
      Both are available from this website:
    </p>
    <v-container class="pa-0">
      <v-row class="">
        <v-col cols="12" xs="12" sm="6" md="4" lg="3" xl="2" class="pt-0">
          The <strong class="primary--text">Convo Controller</strong> <v-icon color="primary">mdi-podcast</v-icon>
          is where you can record and control messages for all
          <strong class="primary--text">Characters</strong> <v-icon color="primary">mdi-account-cowboy-hat</v-icon>.
          From the Controller you can send these recorded messages to the Messenger.
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="4" lg="3" xl="2" class="pt-0">
          The <strong class="secondary--text">Convo Messenger</strong> <v-icon color="secondary">mdi-cellphone-nfc</v-icon>
          is where the actress or actor interacts with a prop mobile device.
          You can access it directly from any web browser on the prop mobile device.
          It is available without login, just with the Access Code or by scanning the QRcode.
        </v-col>
      </v-row>
    </v-container>

    <!-- because ListConvos does not get id while hard reaload  -->
    <v-alert
      type="warning"
      v-if="hardRelaodFail"
      @click="hardRelaodFail = !hardRelaodFail"
      icon="mdi-alert"
    >
      An expected error happened while loading your convos. Please click this heart to solve it: <v-icon class="error--text px-1">mdi-heart</v-icon>
    </v-alert>

    <ListConvos
      :auth="auth"
      :user="user"
      @hardRelaodFail="hardRelaodFail = true"
      @duplicate="duplicate($event)"
      :key="hardRelaodFail"
    />


    <v-card-title class="justify-center">
      <v-btn small color="primary" class="mr-3" @click="$vuetify.goTo(0), addNewConvo = true" rounded>
        + Create a convo
      </v-btn>
    </v-card-title>


    <v-divider class="my-6 py-6 op-0"></v-divider>
    <v-divider class="my-6 py-6 mx-16"></v-divider>
    <v-divider class="my-6 py-6 op-0"></v-divider>

    <div v-if="user.role === 'admin' || user.role === 'user'" >
      <Keycodes :auth="auth" :user="user"/>
      <v-divider class="my-6 py-6 op-0"></v-divider>
      <v-divider class="my-6 py-6 mx-16"></v-divider>
      <v-divider class="my-6 py-6 op-0"></v-divider>
    </div>

  </div>
</template>

<script>
import Keycodes from '@/components/Keycodes.vue'
import NewAndEditConvo from '@/components/NewAndEditConvo.vue'
import ListConvos from '@/components/ListConvos.vue'
import WelcomeDialog from '@/components/WelcomeDialog.vue'

export default {
  props: {
    auth: Boolean,
    user: Object,
  },
  name: 'Dashboard',
  components: {
    Keycodes, NewAndEditConvo, ListConvos, WelcomeDialog
  },
  data () {
    return {
      addNewConvo: false,
      hardRelaodFail: false,
      welcomeDialog: false,
      duplicateConvo: {},
    }
  },
  created() {
    if(this.$route.query.dialog === 'welcome') this.welcomeDialog = true
  },
  methods: {
    duplicate: function(convoData) {
      this.addNewConvo = false;  // force reload thing
      this.$nextTick(() => {
        convoData.name = convoData.name + ' - Copy';
        convoData.accessCode = '';
        delete convoData.id;
        delete convoData.createdOn;
        delete convoData.editedOn;
        this.duplicateConvo = convoData;
        this.addNewConvo = true;
        this.$vuetify.goTo(0);
      });
    },
  },
}
</script>
