<template>
  <v-app dark>

    <SiteNav v-if="!$route.matched[0].meta.hideNav" :auth="isLoggedIn" :user="this.userProfile"></SiteNav>
    
    <router-view v-if="$route.matched[0].meta.fullscreen" :auth="isLoggedIn" :user="this.userProfile" class="fill-height"/>

    <v-main v-else>
      <v-container class="pt-10">
        <router-view :auth="isLoggedIn" :user="this.userProfile"/>
      </v-container>
    </v-main>


    <v-card-title v-if="$route.matched[0].meta.footer" class="justify-center caption grey--text fill-width pb-6 mb-6">
      <div class="text-center">
        Proudly made by <a href="http://www.fluescher.ch" target="_blank" class="px-1">Fabian Lüscher</a>
        for you <v-icon x-small class="error--text px-1">mdi-heart</v-icon>
        <br>
        <router-link to="/license" v-tooltip="'See creative commons license'">CC0 1.0</router-link>
        - 2020
        <br>
        Like this? <a href="https://paypal.me/fluescher" target="_blank">Please support this project on paypal</a>
      </div>
    </v-card-title>
    
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
import SiteNav from '@/components/Navigation'

export default {
  name: 'App',

  components: {
    SiteNav
  },

  data () {
    return {
      message: {
        'welcome': 'Welcome to this fantastic experience!',
        'loggedIn': 'You have successfully been logged in.',
        'loggedOut': 'You have been logged out.',
        'authRequiered': 'This page needs authentication.',
        'noExistence': 'This page does not yet exist.',  // currently not used
      }
    }
  },
  watch:{
    $route(){
      if(Object.keys(this.$route.query).length > 0) {
        if(this.$route.query.success) {
          this.$toasted.global.success({
            msg: this.message[this.$route.query.success] ? this.message[this.$route.query.success] : this.$route.query.success,
          });
        } else if(this.$route.query.error) {
          this.$toasted.global.error({
            msg: this.message[this.$route.query.error] ? this.message[this.$route.query.error] : this.$route.query.error,
          });
        } else if(this.$route.query.info) {
          this.$toasted.global.info({
            msg: this.message[this.$route.query.info] ? this.message[this.$route.query.info] : this.$route.query.info,
          });
        }
      }
    }
  },

  computed: {
    ...mapState(['userProfile', 'posts']),
    isLoggedIn() {
      //console.log("User superprofile: ", this.userProfile);
      return Object.keys(this.userProfile).length > 1;
    },
    user() {
      return this.userProfile
    }
  },
};
</script>
