<template>
  <div>
    <!-- SIGNUP FORM -->
    <v-card
      class="mx-auto my-4 pa-4"
      max-width="344"
      outlined
    >
      <v-card-title class="justify-center">Reset password</v-card-title>
      <form @submit.prevent>
        <v-text-field filled background-color="grey lighten-3" v-model.trim="email" type="email" label="email" placeholder="you@email.com" id="email2" autocomplete="email"></v-text-field>
      </form>

      <v-card-actions class="pl-0">
        <v-spacer></v-spacer>
        <v-btn type="submit" :loading="loading" color="primary" @click="resetPassword()" rounded>Send</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
      <div v-if="showSuccess" class="success--text caption">Success! Check your email for a reset link.</div>
      <div v-if="errorMsg !== ''" class="error--text caption">{{ errorMsg }}</div>

      <span class="caption grey--text">
        <router-link to="/login">Back to Log In</router-link>
      </span>
      
    </v-card>
  </div>
</template>

<script>
import { auth } from '@/firebase'

  export default {
    name: 'ForgotPassword',
    data () {
      return {
        email: '',
        showSuccess: false,
        loading: false,
        errorMsg: ''
      }
    },
    methods: {
      async resetPassword() {
        this.loading = true;
        this.errorMsg = ''
        try {
          await auth.sendPasswordResetEmail(this.email)
          this.loading = false;
          this.showSuccess = true
        } catch (err) {
          this.loading = false;
          this.errorMsg = err.message
        }
      }
    },
  }
</script>
