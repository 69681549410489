<template>
  <div>
    <v-card class="mx-auto my-4" max-width="650" outlined>
      <v-card-title class="justify-center mb-0">
        <v-img
          height="50"
          class="mt-6 mb-0 op-75"
          contain
          :src="require('../assets/logoBlack.svg')"
        />
      </v-card-title>

      <v-card-title class="justify-center primary--text my-0 pb-10">
        License: CC0 1.0 Universal (CC0 1.0)
      </v-card-title>

      <v-card-text>
        <p>
          This work has been marked as dedicated to the public domain.<br>
          Free for commercial use. No attribution required.
        </p>
        <p
          xmlns:dct="http://purl.org/dc/terms/"
          xmlns:cc="http://creativecommons.org/ns#"
          class="license-text"
        >
          convo
          by
          <a
            rel="cc:attributionURL dct:creator"
            property="cc:attributionName"
            href="fluescher.ch"
            target="_blank"
            >Fabian Lüscher</a
          >
          is licensed under
          <a
            rel="license"
            href="https://creativecommons.org/publicdomain/zero/1.0"
            target="_blank"
          >
            CC CC0 1.0
            <img
              class="y-25 ml-1 sub op-50"
              :src="require('../assets/cc_icon.png')"/>
            <img
              class="y-25 ml-1 sub op-50"
              :src="require('../assets/cc_zero.png')"/>
            </a>
        </p>
        <p class="text-center pt-8">
          <a
            rel="license"
            href="https://creativecommons.org/publicdomain/zero/1.0"
            target="_blank"
          >
            <img :src="require('../assets/cc_logo.svg')" alt="" class="y-50 mr-4"/>
            <img :src="require('../assets/cc_icon.png')" alt="" class="y-50 mr-4 op-50"/>
            <img :src="require('../assets/cc_zero.png')" alt="" class="y-50 mr-4 op-50"/>
          </a>
        </p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "License",

  data() {
    return {};
  },

  methods: {},
};
</script>
