import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
      dark: false,
      themes: {
        light: {
            primary: "#38B083",  // same as logo
            secondary: '#1783A2',  // blue ish
            error: '#CB517B',
            warning: '#f39025', 
        },
        dark: {  // my very own
            primary: '#2F545B',
            accent: '#CB517B',
            secondary: '#EFD386',
            success: '#52A355',
            info: '#3391DC',
            warning: '#E8A045',
            error: '#FF5555'
        },
      },
    },
})

/* FROM tHEME MAKER */
/* const VuetifyOpts = {
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: '#2F545B',
        accent: '#CB517B',
        secondary: '#EFD386',
        success: '#52A355',
        info: '#3391DC',
        warning: '#E8A045',
        error: '#FF5555'
      },
      light: {
        primary: '#1976D2',
        accent: '#e91e63',
        secondary: '#30b1dc',
        success: '#4CAF50',
        info: '#2196F3',
        warning: '#FB8C00',
        error: '#FF5252'
      }
    }
  }
}*/
