<template>
  <div>
    <v-card-title v-if="!editing" class="justify-center">
      1. Setup name and Access Code
    </v-card-title>

    <!-- Duplication hint -->
    <v-alert
      v-if="!editing && this.editConvoData && Object.keys(this.editConvoData).length > 0"
      type="info"
      icon="mdi-information"
    >
    <!--   class="justify-center info--text title italics pt-0" -->
    Note: Duplication does not copy your messages. Only your settings and all characters get copied.
    </v-alert>

    <!-- Random character hint DIALOG -->
    <v-dialog
      v-model="displayInfoRandomCharacter"
      max-width="600"
    >
      <v-card @click="displayInfoRandomCharacter = false" max-width="800">
        
          <v-card-title>
            <v-icon color="primary" class="mr-2" large>mdi-dice-multiple</v-icon>
            About randomly generated characters
          </v-card-title>
          <v-card-text>
            <p>
              This character is randomly generated.
            </p>
            <p>
              The name, as well as the telephone number, are randomly chosen.
              Though, <span class="bolder primary--text">just by chance, they both might exist</span>. So be careful here.
            </p>
            <p>
              The avatar images are hand-picked and sourced from <a href="https://pixy.org/" target="_blank">pixy.org</a> and as such
              <span class="bolder primary--text">are not protected</span> and in the public domain:
            </p>
            <p class="text-center">
              <img :src="require('../assets/cc_logo.svg')" alt="" class="y-50 mr-4"/>
              <img :src="require('../assets/cc_icon.png')" alt="" class="y-50 mr-4 op-50"/>
              <img :src="require('../assets/cc_zero.png')" alt="" class="y-50 mr-4 op-50"/>
            </p>
            <p>
              CC0 1.0 Universal (CC0 1.0):<br>
              Public Domain Dedication: Free for commercial use. No attribution required.
            </p>
            <p>
              Learn more about the license <a href="https://creativecommons.org/publicdomain/zero/1.0/deed.en" target="_blank">here</a>.
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn
                  class="mb-3 primary"
                  @click="displayInfoRandomCharacter = false"
                >
                  Close
                </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Hard reload detected -->
    <v-alert
      v-if="!editing && editConvoData && editConvoData.length"
      type="info"
      icon="mdi-alert"
    >
      An expected error happened while loading your convos. Please click this heart to solve it: <v-icon class="error--text px-1">mdi-heart</v-icon>
    </v-alert>

    <v-form @submit.prevent v-model="valid" ref="form">
      <v-container grid-list-md text-xs-center class="ma-0 pa-0">
        <v-layout row wrap>
          <v-flex xs12 sm6>
            <!-- NAME OF CONVO -->
            <v-text-field filled background-color="grey lighten-3" label="Name of convo*" v-model.trim="convoData.name" type="text"
            placeholder="Production / Name of movie or Scene 101-1"
            :rules="notEmpty"
            prepend-inner-icon="mdi-frequently-asked-questions"
            hint="It's best to choose a name which helps you orient yourself in the script. This is not displayed anywhere except here."
            >
            </v-text-field>
          </v-flex>

          <v-flex xs12 sm6>
            <!-- ACCESS CODE -->
            <v-text-field filled background-color="grey lighten-3" label="Access Code*" v-model.trim="convoData.accessCode" type="text"
            placeholder="CodeForAccessingConvoEverywhere"
            :rules="accessCodeRules"
            persistent-hint
            hint="This is the code with which everyone (!) can join a convo. Make it unique like a password, but easy to recite. Try the dice to the right for inspiration."
            append-icon="mdi-dice-3"
            @click:append="convoData.accessCode = $helpers.getRandomWords(4)"
            ></v-text-field>
          </v-flex>

          <!-- CHARACTERS -->
          <v-flex xs12 v-if="!editing">
            <v-card-title class="justify-center pb-0">
              2. Add at least two Characters to this Convo
            </v-card-title>
            <v-card-title class="justify-center">
              <v-btn large icon @click="addCharacter(false)" :disabled="convoData.characters.length >= 12" color="white" class="primary">
                <v-icon class="nudge-x-40">mdi-account-cowboy-hat</v-icon>
                <v-icon x-small class="nudge-x--20">mdi-plus-thick</v-icon>
              </v-btn>
            </v-card-title>
          </v-flex>

          <!-- :sm4="!editing" :md3="!editing" :sm6="editing" :md4="editing" -->

          <v-flex xs12 sm6 md4 :lg3="!editing" :xl2="!editing" :lg6="editing" :xl4="editing" mb-4 :mb-14="$vuetify.breakpoint.smAndDown" v-for="(character, i) in convoData.characters" :key="i">
            <v-card>

              <!-- {{character}} -->

              <!-- <pre>{{character}}</pre> -->
              <!-- name -->
              <v-text-field filled background-color="grey lighten-3" dense v-model.trim="character.name" type="text"
              placeholder="Name*"
              :rules="notEmpty"
              :class="character.randomlyGenerated ? 'mr-2' : ''"
              hint="Name of a Character or a group chat title"
              prepend-inner-icon="mdi-account-cowboy-hat"
              append-icon="mdi-dice-multiple"
              :append-outer-icon="character.randomlyGenerated ? 'mdi-information' : ''"
              @click:append="randomizeCharacter(i)"
              @click:append-outer="displayInfoRandomCharacter = true"
              autocomplete="off"
              ></v-text-field>

              <v-select
                v-model="character.role"
                class="mb-2"
                dense
                single-line
                :items="characterRoles"
                open-on-clear
                filled
                background-color="grey lighten-3" 
                label="Role of character"
                hint="Role of character"
                persistent-hint
                @click:clear="character.role = 'else'"
              ></v-select>

              <div small class="grey--text px-3 pb-2 pointer" @click="toggleElement(character.uid+'-characterOptions')">
                <v-icon v-if="toggledElements.includes(character.uid+'-characterOptions')">mdi-menu-up</v-icon>
                <v-icon v-else>mdi-menu-down</v-icon>
                Optionals
              </div>

              <div v-if="toggledElements.includes(character.uid+'-characterOptions')" class="pb-3">
                <!-- phone number -->
                <v-text-field filled background-color="grey lighten-3" dense v-model.trim="character.phone" type="text"
                class="mb-0"
                placeholder="phone number"
                append-icon="mdi-dice-3"
                @click:append="character.phone = $helpers.getRandomPhoneNumber()"
                hint="Phone number or list of group chat participants"
                autocomplete="off"
                ></v-text-field>
                <v-card-text class="py-0">
                  <!-- online status -->
                  <v-switch
                    hide-details
                    class="mt-0 mb-3"
                    inset
                    v-model="character.online"
                    :label="character.online ? 'Status: Online' : 'Status: Offline'"
                  ></v-switch>
                  
                  <!-- AVATAR MANAGER -->
                  <ImageUpload
                    btnClasses="my-1 mr-4 white--text primary lighten-1"
                    labelClasses="grey--text text--darken-2 body-1"
                    :labelText="character.avatar ? 'Change avatar…' : 'Upload avatar…'"
                    :imagesOnly="true"
                    icon="mdi-account-circle"
                    :maxFileSizeMb="8"
                    :imageSize="200"
                    @base64="[character.avatar = $event, errorImageUpload='']"
                    @error="errorImageUpload = $event.message"
                  ></ImageUpload>

                  <!-- avatar preview -->
                  <v-card-text v-if="character.avatar" absolute class="pa-0 pb-4 text-center">
                    <v-avatar size="125" class="elevation-5">
                      <v-img
                        class="grey my-2 elevation-5"
                        :src="character.avatar"
                        @click="character.randomlyGenerated ? swapRandomAvatar(i) : false"
                        v-tooltip="character.randomlyGenerated ? 'Swap for a new random avatar' : false"
                      >
                      </v-img >
                    </v-avatar>

                    <v-btn v-tooltip="'Remove image'"
                      fab x-small class="error--text pa-0"
                      absolute
                      @click="character.avatar=''"
                    ><v-icon>mdi-trash-can</v-icon>
                    </v-btn>
                    <v-btn v-if="character.randomlyGenerated"
                      v-tooltip="'About this random image'"
                      absolute
                      fab x-small class="info--text ml-3 mt-12 pa-0"
                      @click="displayInfoRandomCharacter = true"
                    ><v-icon x-large>mdi-information</v-icon>
                    </v-btn>
                  </v-card-text>

                  <!-- ACCENT COLOR -->
                  <div
                    class="grey--text text--darken-2 body-1 pointer"
                    @click.stop="toggleElement(character.uid+'-colorAccent')"
                  >
                    <v-avatar
                      class="pointer elevation-2 mr-4 my-1"
                      :color="character.colorAccent"
                      size="36"
                    ></v-avatar>
                    Chat accent color
                    <v-btn
                      v-if="character.colorAccent !== '#38B083'"
                      v-tooltip.right="'Set default'"
                      icon
                      small
                      @click="character.colorAccent = '#38B083', toggleElement(character.uid+'-colorAccent')"
                      class="mb-1 mirr-x"
                    >
                      <v-icon small>mdi-refresh</v-icon>
                    </v-btn>
                  </div>
                  <v-color-picker
                    class="mb-3"
                    v-if="toggledElements.includes(character.uid+'-colorAccent')"
                    :value="character.colorAccent"
                    @input="[character.colorAccent = $event, toggleElement(character.uid+'-colorAccent')]"
                    dot-size="25"
                    hide-canvas
                    hide-inputs
                    hide-mode-switch
                    show-swatches
                    swatches-max-height="160"
                  ></v-color-picker>

                  <!-- CHAT BG COLOR -->
                  <div
                    v-if="!character.bgImage"
                    class="grey--text text--darken-2 body-1 pointer"
                    @click.stop="toggleElement(character.uid+'-colorBG')"
                  >
                    <v-avatar
                      class="pointer elevation-2 mr-4 my-1"
                      :color="character.colorBG"
                      size="36"
                    ></v-avatar>
                    Chat color
                    <v-btn 
                      v-if="character.colorBG !== '#607D8B'"
                      v-tooltip.right="'Set default'"
                      icon
                      small
                      @click="character.colorBG = '#607D8B', toggleElement(character.uid+'-colorBG')"
                      class="mb-1 mirr-x"
                    >
                      <v-icon small>mdi-refresh</v-icon>
                    </v-btn>
                  </div>

                  <div v-if="toggledElements.includes(character.uid+'-colorBG') && !character.bgImage">
                    <!-- default BG images -->
                    <!-- <v-row class="px-3 mt-1" >
                      <v-col class="d-flex child-flex pa-1 ma-0" cols="3">
                        <v-img
                          :src="require('../assets/defaultbg/1.png')"
                          height="100"
                          cover
                          class="grey lighten-2"
                        ></v-img>
                      </v-col>
                      <v-col class="d-flex child-flex pa-1 ma-0" cols="3">
                        <v-img
                          :src="require('../assets/defaultbg/2.png')"
                          height="100"
                          cover
                          class="grey lighten-2"
                        ></v-img>
                      </v-col>
                      <v-col class="d-flex child-flex pa-1 ma-0" cols="3">
                        <v-img
                          :src="require('../assets/defaultbg/3.png')"
                          height="100"
                          cover
                          class="grey lighten-2"
                        ></v-img>
                      </v-col>
                      <v-col class="d-flex child-flex pa-1 ma-0" cols="3">
                        <v-img
                          :src="require('../assets/defaultbg/4.png')"
                          height="100"
                          cover
                          class="grey lighten-2"
                        ></v-img>
                      </v-col>
                    </v-row> -->


                    <v-color-picker
                      class="mb-3"
                      :value="character.colorBG"
                      @input="[character.colorBG = $event, toggleElement(character.uid+'-colorBG')]"
                      dot-size="25"
                      hide-canvas
                      hide-inputs
                      hide-mode-switch
                      show-swatches
                      swatches-max-height="160"
                    ></v-color-picker>
                  </div>

                  <!-- BG IMAGE -->
                  <ImageUpload
                    btnClasses="my-1 mr-4 white--text primary lighten-1"
                    labelClasses="grey--text text--darken-2 body-1"
                    :labelText="character.bgImage ? 'Change bg image…' : 'Set background image…'"
                    :imagesOnly="true"
                    icon="mdi-cellphone-android"
                    :maxFileSizeMb="8"
                    :imageSize="200"
                    @base64="[character.bgImage = $event, errorImageUpload='']"
                    @error="errorImageUpload = $event.message"
                  ></ImageUpload>

                  <!-- BG IMAGE preview -->
                  <v-img
                    v-if="character.bgImage"
                    class="grey my-2 rounded"
                    :src="character.bgImage"
                    color=""
                    contain
                    height="120"
                  >
                    <v-btn v-tooltip="'Remove image'"
                      fab x-small absolute right class="error--text mt-2 mr-0 pa-0"
                      @click="character.bgImage=''"
                    ><v-icon>mdi-trash-can</v-icon>
                    </v-btn>
                  </v-img >

                  </v-card-text>
              </div>

              <v-card-text class="pt-0" v-if="convoData.characters.length > 1" align="center">
                  <v-btn small v-tooltip.bottom="{ content: 'Remove this Character', classes: 'error' }" color="white" class="error mb-1" @click="removeCharacter(i)">
                    <v-icon small>mdi-trash-can</v-icon>
                  </v-btn>
              </v-card-text>
            </v-card>
          </v-flex>

          <!-- Empty characters to add with button -->
          <v-flex
            v-for="i in convoData.characters.length < 4 ? 4 - convoData.characters.length : convoData.characters.length >= 12 ? 0 : 1" :key="i+convoData.characters.length"
            xs12 sm6 md4 :lg3="!editing" :xl2="!editing" :lg6="editing" :xl4="editing" 
            :mb-14="$vuetify.breakpoint.smAndDown"
            class="mb-4"
          >
            <v-card class="grey lighten-3 elevation-0 fill-height justify-center">
              <v-card-title v-if="i === 1" class="justify-center fill-height text-break">
                <v-btn v-tooltip="'Add a new empty character'" x-small fab icon @click="addCharacter(false)" color="white" class="primary">
                  <v-icon class="nudge-x-30">mdi-account-cowboy-hat</v-icon>
                  <v-icon x-small class="nudge-x--10">mdi-plus-thick</v-icon>
                </v-btn>
                <br>
                <v-btn v-tooltip="'Add a random character'" small fab icon @click="addCharacter(true)" color="grey darken-1">
                  <v-icon>mdi-dice-multiple</v-icon>
                </v-btn>
              </v-card-title>
            </v-card>
          </v-flex>

          <v-flex v-if="errorImageUpload" xs12>
            <p class="my-3 pl-0 error--text">
              {{errorImageUpload}}
            </p>
          </v-flex>

          <v-flex xs12>
            <v-card-title v-if="!editing" class="justify-center pb-0">
              3. Let's go!
            </v-card-title>
            <v-card-title class="justify-center pb-0">

              <v-btn v-if="!editing" type="submit" :disabled="!valid || convoData.characters.length <= 1" color="primary" @click="createConvo(convoData)" rounded>
                  Create new convo
              </v-btn>

              <div v-else>
                <v-btn type="submit" :disabled="!valid || convoData.characters.length <= 1" color="primary" @click="editConvo(convoData)" rounded>
                    Save changes
                </v-btn>
                <v-btn color="ml-3 grey lighten-3" @click="$emit('cancelEdit')">
                  Cancel
                </v-btn>
              </div>

            </v-card-title>

            <v-card-title class="justify-center py-0">
              <!-- ERROR HANDLING -->
              <span v-if="success" class="secondary--text text-center mt-6">
                {{success}}
                <br>
                <v-btn @click="$vuetify.goTo('#listConvos');" text class="mt-3 mr-2">
                  <v-icon>mdi-menu-down</v-icon>
                  See it here
                </v-btn>
              </span>
              <span v-if="error" class="error--text">
                {{error}}
              </span>
            </v-card-title>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <!-- <pre class="grey">convoData: {{convoData}}</pre> -->

  </div>
</template>

<script>
import ImageUpload from '@/components/ImageUpload'
import RandomName from 'node-random-name'  // https://www.npmjs.com/package/node-random-name
// import ThisPersonDoesNotExist from '@/plugins/thispersondoesnotexist.js';
// import ThisPersonDoesNotExist from 'thispersondoesnotexist-js';


export default {
  name: 'NewAndEditConvo',
  props: {
    auth: Boolean,
    user: Object,
    editing: Boolean,
    editingUid: String,
    editConvoData: Object,
  },
  components: {
    ImageUpload
  },
  data () {
    return {
      success: '',
      error: '',
      errorImageUpload: '',
      valid: false,
      displayInfoRandomCharacter: false,
      toggledElements: [],
      characterRoles: [
        {'text': '…is holding the device', 'value': 'pov'}, 
        {'text': '…is the counterpart', 'value': 'sender'}, 
        {'text': '…is someone else', 'value': 'else'}],
      convoData: {
        accessCode: '',
        createdBy: this.user.uid,
        createdOn: new Date(),
        name: '',
        settings: {
          forceType: false,
          randomTimeIntervals: false,
          showDates: false,
          showTypeIndicator: true,
          referenceDate: new Date(),
          typing: false,
          autoSeeMessages: true,
          systemBar: {
            battery: 92,
            display: true,
            email: 1,
            message: '',
            network: 4,  // mdi-network-strength-1 bis 4, und 0 für mdi-network-strength-off-outline
            provider: 'N-Provider',
            sms: 1,
            time: this.$moment(new Date()).format('HH:mm'),
            unansweredCall: false,
            wlan: 3,  // mdi-wifi-strength-1 bis 4, 0 für mdi-wifi-strength-alert-outline
          }
        },
        characters: [
          //{ uid: this.$helpers.createUid(), name: '', avatar: '', phone: '', online: true },
        ],
      },
      // Rules
      notEmpty: [
        v => !!v || 'This is required',
      ],
      accessCodeRules: [
        v => !!v || 'This is required',
        v => (v.split(' ').length <= 1) || 'No spaces allowed',
        v => (v && v.length >= 8) || 'This must be more than 8 characters',
        v => /^[A-Za-z0-9_-]*$/.test(v) || 'No special characters',
      ],
    }
  },
  created() {
    if(this.editing || (this.editConvoData && Object.keys(this.editConvoData).length > 0)) {
      this.convoData = JSON.parse(JSON.stringify(this.editConvoData));
    }
  },
  computed: {
  },
  methods: {
    toggleElement: function(element) {
    if(this.toggledElements.includes(element)) {
        //console.log("Toggle OFF: ", element);
        this.$helpers.removeFromArray(this.toggledElements, element);
      } else {
        //console.log("Toggle ON: ", element);
        this.toggledElements.push(element);
      }
    },

    // Add empty or randomized character
    async addCharacter(random) {
      let role = this.convoData.characters.length === 0
        ? 'pov'
          : this.convoData.characters.length === 1
            ? 'sender'
            : 'else'

      let newCharacter = { 
        uid: this.$helpers.createUid(),
        name: '',
        avatar: '',
        role: role,
        phone: '',
        online: true,
        colorAccent: '#38B083',  //
        colorBG: '#607D8B',  //
        bgImage: '',  // "base64/..."
      }

      if(random) newCharacter = await this.makeRandomCharacter(newCharacter)

      this.convoData.characters.push(newCharacter);
    },

    // Replace existing character with a random one
    async randomizeCharacter(index) {
      this.convoData.characters[index] = await this.makeRandomCharacter(this.convoData.characters[index]);
    },

    // Create a random character
    async makeRandomCharacter(character) {
      let sex = this.$helpers.randomBetween(0, 1);
      if(sex === 0) {
        sex = 'female'
      } else {
        sex = 'male'
      }
      character.randomlyGenerated = true;
      character.name = RandomName({ gender: sex });
      character.phone = this.$helpers.randomBetween(0, 3) === 0 ? '' : this.$helpers.getRandomPhoneNumber();
      character.online = this.$helpers.randomBetween(0, 1) === 0;
      character.colorAccent = this.$helpers.randomHexColor();
      character.avatar = await this.$helpers.getRandomAvatar(sex)
      return character
    },

    async swapRandomAvatar(index) {
      this.convoData.characters[index].avatar = await this.$helpers.getRandomAvatar();
    },

    removeCharacter: function(index) {
      this.convoData.characters.splice(index, 1);
    },

    createConvo: function(data) {
      if(!data.createdOn) data.createdOn = new Date();  // if data was duplicated, there's no createdOn

      let testMessageDateUnix = data.createdOn.getTime()/1000;

      let testMessages = [
        {
          disabled: false,
          image: '',
          lockHistory: true,
          message: 'Hi '+data.characters[0].name+'! These are test messages.\n\nThis one has a "history anchor" set: It will not be unsent if you unsend messages to start from the top.',
          seen: true,
          sent: true,
          sentDate: this.$helpers.restoreFbTimestampFromJsDate({'seconds': testMessageDateUnix - 20*60}),
          character: data.characters[1].uid,
        },
        {
          disabled: false,
          image: '',
          lockHistory: false,
          message: 'Hello '+data.characters[1].name+'!\nThis is an example response from me, '+data.characters[0].name+', a fellow human ❤️!',
          seen: true,
          sent: true,
          sentDate: this.$helpers.restoreFbTimestampFromJsDate({'seconds': testMessageDateUnix - 45}),
          character: data.characters[0].uid,
        },
        {
          disabled: false,
          image: '',
          lockHistory: false,
          message: 'This message is saved in your history, but not yet sent. If you\'re happy with the content, you can send it now by clicking the "send" button to the right of this message bubble.\n\nOtherwise, you can edit the text and other details by clicking ⚙️ to the right.',
          seen: false,
          sent: false,
          sentDate: this.$helpers.restoreFbTimestampFromJsDate({'seconds': testMessageDateUnix - 40}),
          character: data.characters[1].uid,
        },
        {
          disabled: false,
          image: '',
          lockHistory: false,
          message: 'If Force Type 🤖 is activated in the settings, this unsent message will be automatically typed next by '+data.characters[0].name+'.',
          seen: false,
          sent: false,
          sentDate: this.$helpers.restoreFbTimestampFromJsDate({'seconds': testMessageDateUnix -10}),
          character: data.characters[0].uid,
        },
      ]

      // WRITE CONVO DOC
      this.$store.dispatch('addData', {"collection": "convos", "document": '', "post": data}).then(documentId => {
        console.log("Success!", documentId)

        // CREATE SUBCOLLECTION AND TEST MESSAGES IN /MESSENGER
        this.$store.dispatch('addData', {"collection": "convos/"+documentId+"/messenger", "document": '', "post": testMessages[0]})
        this.$store.dispatch('addData', {"collection": "convos/"+documentId+"/messenger", "document": '', "post": testMessages[1]})
        this.$store.dispatch('addData', {"collection": "convos/"+documentId+"/messenger", "document": '', "post": testMessages[2]})
        this.$store.dispatch('addData', {"collection": "convos/"+documentId+"/messenger", "document": '', "post": testMessages[3]})

        this.success = 'Sucessfully created convo!';
        this.error = '';
        this.clearForm();
      }).catch(error => {
        this.success = '';
        console.log(error)
        console.error(error.message);
        this.error = error.message === 'A document cannot be written because it exceeds the maximum size allowed.'
          ? 'The images you used are too big. Choose smaller ones.'
          : error.message;
      });
    },

    editConvo: function(data) {
      // Set edit date
      data.editedOn = new Date();
      // When loading timestamps from FB, it automatically gets translated to JS object. Make fb Timestamp great again.
      data.createdOn = this.$helpers.restoreFbTimestampFromJsDate(data.createdOn);
      data.settings.referenceDate = this.$helpers.restoreFbTimestampFromJsDate(data.settings.referenceDate);

      // remove numberOfMessages
      delete data.numberOfMessages

      this.$store.dispatch('editData', {"collection": "convos", "document": this.editingUid, "post": data}).then(() => {
        console.log("Success edit!")
        this.$emit('successfulEdit');  // FIXME: FIRST TIME AROUND THIS DOES NOT GET SEND....
      }).catch(error => {
        console.log("UN Success edit!")
        this.success = '';
        console.log(error)
        console.error(error.message);
        this.error = error.message;
      });
    },

    clearForm() {
      this.resetValidation();
      this.convoData = {
        accessCode: '',
        createdBy: this.user.uid,
        createdOn: new Date(),
        name: '',
        settings: {
          forceType: false,
          randomTimeIntervals: false,
          showDates: false,
          showTypeIndicator: true,
          referenceDate: new Date(),
          typing: false,
          autoSeeMessages: true,
          systemBar: {
            battery: 92,
            display: true,
            email: 1,
            message: '',
            network: 4,  // mdi-network-strength-1 bis 4, und 0 für mdi-network-strength-off-outline
            provider: 'N-Provider',
            sms: 1,
            time: this.$moment(new Date()).format('HH:mm'),
            unansweredCall: false,
            wlan: 3,  // mdi-wifi-strength-1 bis 4, 0 für mdi-wifi-strength-alert-outline
          }
        },
        characters: [
          //{ uid: this.$helpers.createUid(), name: '', avatar: '', phone: '', online: true },
        ],
      };
    },
    /* 
    validate () {
      this.$refs.form.validate()
    },
    reset () {
      this.$refs.form.reset()
    }, */
    resetValidation () {
      this.$refs.form.resetValidation()
    },
  },
}
</script>
