<template>
  <v-container>
    <!-- messages<br>
    convo.id: {{convo.id}}<br>
    pov.uid: {{pov.uid}}<br>
    controller: {{controller}}<br>
    <hr>
    messages: <pre>{{messages}}</pre><br>
    <hr> -->

  <!-- MESSAGES -->
  <transition-group name="scale-transition">
    <div v-for="(message, i) in messages" :key="i">
      <!-- DATE DISPLAY IF NECESSARY -->
      <div
        v-if="convo.settings.showDates && datesToDisplay.includes(message.id) && !(!controller && !message.sent)"
        class="italics my-4 pt-8 pb-4 text-center"
        style="clear: both;"
      >
        <v-chip small color="grey">
          <div class="capitalizeFirstLetter">
            {{ displayDate(message.sentDate) }}
          </div>
          </v-chip>
      </div>

      <!-- SAID WHO? if more than 2 people talking -->
      <div
        v-if="
          convo.characters.length >= 3
          && message.character !== pov.uid
          && (controller || message.sent)
          && namesToDisplay.includes(message.id)
        "
        class="italics pl-3 caption nudge-y-75 op-50"
        style="clear: both;"
      >
        {{$helpers.findKey(convo.characters, 'uid',  message.character, "name")}} said:
      </div>

      <!-- HOVER & CONTAINER -->
      <v-hover
        v-slot="{ hover }"
        v-if="controller || (!controller && message.sent)" 
      >
        <div
          class="selectable pt-4 mx-3 fill-height"
          style="clear: both;"
        >
          <div class="rounded elevation-5 maxx-70p white--text mb-2"
            :class="[
              message.character === pov.uid ? 'float-right': 'float-left',
              !message.sent ? 'op-50 grayscale' : '',
              message.image && ! message.message ? 'pa-0' : 'py-2 px-4',
            ]"
            :style="{'backgroundColor': bubbleColor(message.character)}"
          >
          <!-- #607D8B is default BG-->
          <!-- :style="'background-color: '+ message.character === pov.uid ? pov.colorAccent : sender.colorAccent" -->
            <!-- TEXT IMAGE -->
            <v-img
              v-if="message.image"
              class="grey rounded"
              :src="message.image"
              contain
            >
            <!-- MAX-height="..." macht scrolling bei bildern ungenau -->
            </v-img>

            <!-- get color of message character:<br> -->
            <!-- very slow and not working --
            pov.uid: {{pov.uid}}<br>
            message.character: {{message.character}}<br>
            sender.uid: {{sender.uid}}<br>
            sender.colorAccent: {{sender.colorAccent}}<br>
            <pre>{{convo.characters}}</pre>
            message.character accent: {{$helpers.findKey(convo.characters, 'uid',  message.character, "accentColor")}}<br>
            <hr>convo.characters -->

            <!-- TEXT MESSAGE CONTENT -->
            <div
              v-if="message.message"
              style="white-space: pre-wrap;"
              :class="emoticons.includes(message.message) ? 'display-3' : ''"
            >{{message.message}}</div>
            <!-- <pre>{{message.sentDate.seconds}}</pre> -->
            <!-- <span class="caption">{{$moment.unix(message.sentDate.seconds).format('YYYY-MM-DD / HH:mm:ss')}}</span> -->

            <!-- additionals -->
            <div class="absolute mt-1 nudge-x--100" :style="{'textAlign': message.character === pov.uid ? 'left' : 'right'}">
              <v-icon v-if="message.character === pov.uid" small :class="message.seen ? 'green--text' : 'grey--text'">
                {{message.seen ? 'mdi-check-all' : 'mdi-check'}}
              </v-icon>
            </div>

            <!-- SPEECH BUBBLE ARROWS THINGIES -->
            <div
              class="absolute"
              :class="message.character === pov.uid ? 'right triangleRight': 'left triangleLeft'"
            ><v-icon x-small :color="bubbleColor(message.character)">mdi-triangle</v-icon>
            </div>
          </div>

          <!-- CONTROLLER HANDLES -->
          <div v-if="controller" :class="hover || $vuetify.breakpoint.smAndDown ? '' : 'op-25'" :style="{'textAlign': message.character === pov.uid ? 'right' : 'left'}">

            <MessageEdit
              :convoId="convo.id"
              :lastElementWithHistoryLock="lastElementWithHistoryLock"
              :characters="convo.characters"
              :showTypeIndicator="convo.settings.showTypeIndicator"
              :showDates="convo.settings.showDates"
              :message="message"
              :abortMessageSending="abortMessageSending"
              :prevMessageSentDate="messages[i-1] ? messages[i-1].sentDate : {'seconds': 0}"
              :nextMessageSentDate="messages[i+1] ? messages[i+1].sentDate : {'seconds': 0}"
              @openEditDialog="openEditDialog = {
                'display': openEditDialog.display === 'initial'
                              ? true
                              : $event.message.id === openEditDialog.message.id
                                ? !openEditDialog.display
                                : $event.display,
                'message': $event.message
                }"
            />
          </div>
        </div>
      </v-hover>

      <!-- DISPLAY IF NECESSARY -->
      <div v-if="controller && message.lockHistory && lastElementWithHistoryLock === message.id" class="lockHistoryBar my-4 pt-8 pb-4 text-center" style="clear: both;">
        <v-divider class="pb-2"></v-divider>
        <v-chip color="secondary mt-3 mb-6">
          <v-icon dark class="mr-2">mdi-anchor</v-icon>
            History anchor
          </v-chip>
        <p class="italics">
        Everything below this gets 'unsent' when unsending messages.
        </p>
      </div>
    </div>
  </transition-group>

  <!-- Render edit dialog only once  -->
  <MessageEditDialog
    v-if="openEditDialog.display === true"
    :message="openEditDialog.message"
    :convoId="convo.id"
    :lastElementWithHistoryLock="lastElementWithHistoryLock"
    :abortMessageSending="abortMessageSending"
    :characters="convo.characters"
    :showDates="convo.settings.showDates"
    :showTypeIndicator="convo.settings.showTypeIndicator"
    :refDay="$moment.unix(convo.settings.referenceDate.seconds)"
    @closeDialog="openEditDialog = {display: false, message: {}}"
  />

  <!-- Scroll to this when new sent message -->
  <div ref="endOfMessages" class="" style="clear: both; height:25px"></div>

  </v-container>
</template>

<script>
import { db } from '../firebase'
import MessageEdit from '@/components/MessageEdit'
import MessageEditDialog from '@/components/MessageEditDialog'

export default {
  name: 'Messages',
  props: {
    convo: Object,
    controller: Boolean,
    messengerPreviewFromController: Boolean,
    pov: Object,
    sender: Object,
    emoticons: Array,
    forceTypeTrigger: String,
    triggerClearMessenger: String,
    triggerScrolling: String,
    shownMessengerHint: Boolean,
  },

  components: {
    MessageEdit, MessageEditDialog
  },

  data () {
    return {
      lastDate: '',
      lastUnix: 0,
      datesToDisplay: [],
      lastName: '',
      abortMessageSending: '',
      namesToDisplay: [],
      messages: [],
      lastElementWithHistoryLock: '',
      openEditDialog: {display: 'initial', message: {}},
    }
  },
  watch: {
    forceTypeTrigger() {
      this.getFirstUntypedMessage();
    },

    triggerClearMessenger() {
      this.clearMessenger();
    },

    triggerScrolling() {
      this.scrollToElement('endOfMessages');
    },

    messages(current, old) {
      // A message was added OR edited
      // Only necessary because i cannot use ".where("sent", "==", true)" because of fake typing
      let newSentMessages = this.$helpers.sumOfValuesInObject(current, 'sent', true);
      let oldSentMessages = this.$helpers.sumOfValuesInObject(old, 'sent', true);
      let newMessages = newSentMessages - oldSentMessages;
      // console.log("messages added: ", newMessages)
      if(newMessages > 0 && !this.controller) {
        //console.log("scroll now!")
        //this.$nextTick(() => {
          this.scrollToElement('endOfMessages');
          //this.delayScrolling(6000, 'endOfMessages');
        //})
      }
    }
  },
  created() {
    if(this.controller) {
      db.collection('convos/' + this.convo.id + '/messenger')
        .where("disabled", "==", false)
        .orderBy("sentDate", "asc")
        .onSnapshot(querySnapshot => {
          let newData = [];
          querySnapshot.forEach(doc => {
            let f = doc.data();
            f.id = doc.id;
            this.setupDatesToDisplay(f.id, f.sentDate.seconds);
            this.setupNamesToDisplay(f.id, f.character);
            if(f.lockHistory) this.lastElementWithHistoryLock = f.id
            newData.push(f);
          });
          this.messages = newData;
        });
    } else {
      db.collection('convos/' + this.convo.id + '/messenger')
        // .where("sent", "==", true) // so i can fetch the next message for fake typing..
        .where("disabled", "==", false)
        .orderBy("sentDate", "asc")
        .onSnapshot(querySnapshot => {
          let newData = [];
          querySnapshot.forEach(doc => {
            let f = doc.data();
            f.id = doc.id;
            this.setupDatesToDisplay(f.id, f.sentDate.seconds);
            this.setupNamesToDisplay(f.id, f.character);
            newData.push(f);
          });
          this.messages = newData;
        });
    }
  },

  /* updated() {
      // whenever data changes and the component re-renders, this is called.
      this.$nextTick(() => this.scrollToEnd());
  }, */

  methods: {
    bubbleColor(messageCharacterId) {
      //console.log(messageCharacterId)
      let color = '#1783A2';
      color = messageCharacterId === this.pov.uid
          ? this.pov.colorAccent
          : messageCharacterId === this.sender.uid
            ? this.sender.colorAccent
            : this.$helpers.findKey(this.convo.characters, 'uid',  messageCharacterId, "colorAccent")

      if(color === '#38B083') color = messageCharacterId === this.sender.uid ? '#1783A2' : color // if default bg AND sender take secondary
      return color;
      /* messageCharacterId === pov.uid
      ? pov.colorAccent
      : sender.colorAccent ==='#38B083'  // if default bg...
        ? '#1783A2'  // ...take secondary
        : sender.colorAccent */
    },

    setupDatesToDisplay(messageId, unix) {
      //console.log(unix - this.lastUnix, unix - this.lastUnix > 15*60);
      if(unix - this.lastUnix > 12*60) this.datesToDisplay.push(messageId)
      this.lastUnix = unix;
    },

    /* Check if last name showed was the same as that one now */
    setupNamesToDisplay(messageId, messageCharacter) {
      if(this.lastName !== messageCharacter) {
        this.namesToDisplay.push(messageId)
        this.lastName = messageCharacter;
      }
    },

    async delayScrolling(sleep, elementRef) {
        await this.$helpers.sleep(sleep)
        this.scrollToElement(elementRef)
    },
    
    scrollToElement(refName) {
      // const el = this.$el.getElementsByClassName(elClassName)[0];
      let item = this.$refs[refName];
      if (item) {
        //el.scrollIntoView(false);  // WORKS but not smooth
        /* {
          behavior: "auto"  | "instant" | "smooth",
          block:    "start" | "center" | "end" | "nearest",  // vert
          inline:    "start" | "center" | "end" | "nearest",  // horz
        } */
        this.$nextTick(() => {
          let scrollprops = {
            'behavior': 'smooth',
            'block': 'end',
          }
          item.scrollIntoView(scrollprops);  // false for element bottom
        })
      }
    },
    
    getFirstUntypedMessage() {
      let firstUntypedMessage = ''
      let that = this;
      // Iterate until something returns true
      let messageFound = this.messages.some(message => {
        firstUntypedMessage = message;
        return message.character === that.pov.uid && !message.sent;
      });
      // console.log(firstUntypedMessage.message, firstUntypedMessage)
      //console.log(messageFound ? "next message is "+firstUntypedMessage.message.substring(0,11)+'...' : 'notting')
      this.$emit("firstUntypedMessage", messageFound ? firstUntypedMessage : {})
    },

    clearMessenger: function() {
      this.abortMessageSending = Math.random().toString();
      // Set all messages from the back until lockHistory is true
      // Iterate until something returns true
      let lockedMessageFound = 0;
      this.messages.slice().reverse().some(message => {
        if(!message.lockHistory && message.sent) {
          //console.log('remove this:'+message.message)
          this.$store.dispatch('mergeData', {
            'collection': 'convos/'+this.convo.id+'/messenger/',
            'document': message.id,
            'post': {'sent': false}
          }).then(() => {
            this.$emit('clearedMessenger');
          })
          lockedMessageFound++;
        }
        //if(message.lockHistory) console.log(message.id)  // the newest message to contain lockHistory=true
        return message.lockHistory;  // stop if found lock history
      });

      // If .some returns nothing, disable loader again
      if(!lockedMessageFound) this.$emit('clearedMessenger');
    },
    /* 
    checkLastDate: function(date) {
      return this.$helpers.fbTimeToRelativeToNow(date);
    }, */

    displayDate: function(date) {
      let pointInTime = this.$helpers.fbTimeToRelativeToNow(date, this.$moment.unix(this.convo.settings.referenceDate.seconds));
      this.lastDate = pointInTime;
      return pointInTime;
    },
  },
}
</script>

<style scoped>
.triangleRight {
  opacity: 0.8;
  transform: translateX(-0.8em) translateY(-1.2em) rotate(90deg)
}
.grayscale .triangleRight {
  opacity: 0.8;
  transform: translateX(0.8em) translateY(-1.2em) rotate(90deg)
}
.triangleLeft {
  opacity: 0.8;
  transform: translateX(0.8em) translateY(-1.2em) rotate(270deg)
}
.grayscale .triangleLeft {  /* bceause somehow greyscale fucks transform: up */
  transform: translateX(-0.8em) translateY(-1.2em) rotate(270deg)
}
</style>