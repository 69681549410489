var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('transition-group',{attrs:{"name":"scale-transition"}},_vm._l((_vm.messages),function(message,i){return _c('div',{key:i},[(_vm.convo.settings.showDates && _vm.datesToDisplay.includes(message.id) && !(!_vm.controller && !message.sent))?_c('div',{staticClass:"italics my-4 pt-8 pb-4 text-center",staticStyle:{"clear":"both"}},[_c('v-chip',{attrs:{"small":"","color":"grey"}},[_c('div',{staticClass:"capitalizeFirstLetter"},[_vm._v(" "+_vm._s(_vm.displayDate(message.sentDate))+" ")])])],1):_vm._e(),(
        _vm.convo.characters.length >= 3
        && message.character !== _vm.pov.uid
        && (_vm.controller || message.sent)
        && _vm.namesToDisplay.includes(message.id)
      )?_c('div',{staticClass:"italics pl-3 caption nudge-y-75 op-50",staticStyle:{"clear":"both"}},[_vm._v(" "+_vm._s(_vm.$helpers.findKey(_vm.convo.characters, 'uid', message.character, "name"))+" said: ")]):_vm._e(),(_vm.controller || (!_vm.controller && message.sent))?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var hover = ref.hover;
return [_c('div',{staticClass:"selectable pt-4 mx-3 fill-height",staticStyle:{"clear":"both"}},[_c('div',{staticClass:"rounded elevation-5 maxx-70p white--text mb-2",class:[
            message.character === _vm.pov.uid ? 'float-right': 'float-left',
            !message.sent ? 'op-50 grayscale' : '',
            message.image && ! message.message ? 'pa-0' : 'py-2 px-4' ],style:({'backgroundColor': _vm.bubbleColor(message.character)})},[(message.image)?_c('v-img',{staticClass:"grey rounded",attrs:{"src":message.image,"contain":""}}):_vm._e(),(message.message)?_c('div',{class:_vm.emoticons.includes(message.message) ? 'display-3' : '',staticStyle:{"white-space":"pre-wrap"}},[_vm._v(_vm._s(message.message))]):_vm._e(),_c('div',{staticClass:"absolute mt-1 nudge-x--100",style:({'textAlign': message.character === _vm.pov.uid ? 'left' : 'right'})},[(message.character === _vm.pov.uid)?_c('v-icon',{class:message.seen ? 'green--text' : 'grey--text',attrs:{"small":""}},[_vm._v(" "+_vm._s(message.seen ? 'mdi-check-all' : 'mdi-check')+" ")]):_vm._e()],1),_c('div',{staticClass:"absolute",class:message.character === _vm.pov.uid ? 'right triangleRight': 'left triangleLeft'},[_c('v-icon',{attrs:{"x-small":"","color":_vm.bubbleColor(message.character)}},[_vm._v("mdi-triangle")])],1)],1),(_vm.controller)?_c('div',{class:hover || _vm.$vuetify.breakpoint.smAndDown ? '' : 'op-25',style:({'textAlign': message.character === _vm.pov.uid ? 'right' : 'left'})},[_c('MessageEdit',{attrs:{"convoId":_vm.convo.id,"lastElementWithHistoryLock":_vm.lastElementWithHistoryLock,"characters":_vm.convo.characters,"showTypeIndicator":_vm.convo.settings.showTypeIndicator,"showDates":_vm.convo.settings.showDates,"message":message,"abortMessageSending":_vm.abortMessageSending,"prevMessageSentDate":_vm.messages[i-1] ? _vm.messages[i-1].sentDate : {'seconds': 0},"nextMessageSentDate":_vm.messages[i+1] ? _vm.messages[i+1].sentDate : {'seconds': 0}},on:{"openEditDialog":function($event){_vm.openEditDialog = {
              'display': _vm.openEditDialog.display === 'initial'
                            ? true
                            : $event.message.id === _vm.openEditDialog.message.id
                              ? !_vm.openEditDialog.display
                              : $event.display,
              'message': $event.message
              }}}})],1):_vm._e()])]}}],null,true)}):_vm._e(),(_vm.controller && message.lockHistory && _vm.lastElementWithHistoryLock === message.id)?_c('div',{staticClass:"lockHistoryBar my-4 pt-8 pb-4 text-center",staticStyle:{"clear":"both"}},[_c('v-divider',{staticClass:"pb-2"}),_c('v-chip',{attrs:{"color":"secondary mt-3 mb-6"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"dark":""}},[_vm._v("mdi-anchor")]),_vm._v(" History anchor ")],1),_c('p',{staticClass:"italics"},[_vm._v(" Everything below this gets 'unsent' when unsending messages. ")])],1):_vm._e()],1)}),0),(_vm.openEditDialog.display === true)?_c('MessageEditDialog',{attrs:{"message":_vm.openEditDialog.message,"convoId":_vm.convo.id,"lastElementWithHistoryLock":_vm.lastElementWithHistoryLock,"abortMessageSending":_vm.abortMessageSending,"characters":_vm.convo.characters,"showDates":_vm.convo.settings.showDates,"showTypeIndicator":_vm.convo.settings.showTypeIndicator,"refDay":_vm.$moment.unix(_vm.convo.settings.referenceDate.seconds)},on:{"closeDialog":function($event){_vm.openEditDialog = {display: false, message: {}}}}}):_vm._e(),_c('div',{ref:"endOfMessages",staticStyle:{"clear":"both","height":"25px"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }