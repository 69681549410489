<template>
  <div>
    <!-- LOGIN FORM -->
    <v-card
      class="mx-auto my-4 pa-4"
      max-width="344"
      outlined
    >
      <v-card-title class="justify-center text-h4">Login</v-card-title>
      <form @submit.prevent>
        <v-text-field filled background-color="grey lighten-3" v-model.trim="loginForm.email" type="text" placeholder="Email" autocomplete="email" autocapitalize="off">
        </v-text-field>
        <v-text-field filled background-color="grey lighten-3" v-model.trim="loginForm.password" type="password" placeholder="Password" autocomplete="current-password">
        </v-text-field>

        <v-card-actions class="pl-0">
          <v-spacer></v-spacer>
          <v-btn type="submit" :loading="loading" color="primary" @click="login()" rounded>Log in</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>

        <!-- ERROR HANDLING -->
        <div v-if="error" class="error--text caption">
          {{error}}
        </div>

        <span class="caption grey--text">
          <router-link to="/forgot-password">Forgot Password</router-link>
          |
          <router-link to="/signup">Create an Account</router-link>
        </span>
      </form>
    </v-card>

  </div>
</template>

<script>
  export default {
    name: 'Login',
    
    data () {
      return {
        error: '',
        loading: false,
        loginForm: {
          email: '',
          password: '',
        }
      }
    },

    methods: {
      login() {
        this.loading = true;
        this.$store.dispatch('login', {
          email: this.loginForm.email,
          password: this.loginForm.password
        }).then(() => {
          //this.loading = false;
          console.log('Sucessful login.')
          this.error = '';
        }).catch(error => {
          this.loading = false;
          this.loginForm.password = '';
          console.log(error);
          this.error = error.message;
        });
      }
    }

  }
</script>

