import Vue from 'vue'
import Vuex from 'vuex'
import * as fb from '../firebase'
import router from '../router/index'

Vue.use(Vuex)

// realtime firebase
// TODO: .where("userId", "==", fb.auth.currentUser.uid)
fb["db"].collection('data').orderBy('createdOn', 'desc').onSnapshot(snapshot => {
  let dataArray = []
  snapshot.forEach(doc => {
    let dataFile = doc.data()
    dataFile.id = doc.id

    dataArray.push(dataFile)
  })
  store.commit('setData', dataArray)
})

// STORE STUFF
const store = new Vuex.Store({
  state: {
    userProfile: {},
    dataFiles: []
  },

  mutations: {
    setUserProfile(state, val) {
      // console.log("setUserProfile...:::", val)
      state.userProfile = val
    },
    setData(state, val) {
      state.dataFiles = val
    }
  },

  getters: {
    dataFiles: state => {
      return state.dataFiles;  // todos.filter(todo => todo.done)
    }
  },

  actions: {
    async login({ dispatch }, form) {
      // sign user in
      await fb.auth.signInWithEmailAndPassword(form.email, form.password).then(function({ user }) {
        // fetch user profile and set in state
        dispatch('fetchUserProfile', user);
        fb["db"].collection('users').doc(user.uid).update({'lastLogin': new Date()})
        return true
      }).catch(function(error) {
        throw error;
      });
    },

    async logout({ commit }) {
      await fb.auth.signOut()
      // clear userProfile and redirect to /login
      commit('setUserProfile', {})
      router.push('/login?success=loggedOut')
    },

    async fetchUserProfile({ commit }, user) {
      // fetch user profile
      return fb.usersCollection.doc(user.uid).get().then(function(userProfile) {
        // set user profile in state
        commit('setUserProfile', {...userProfile.data(), 'uid': user.uid, 'email': user.email })
        // change route to dashboard
        if (router.currentRoute.path === '/login' || router.currentRoute.path === '/') {
          //console.log("pushed to home")
          router.push('/dashboard?success=loggedIn')
        }
      }).catch(error => {
        console.error("could not write file to usersCollection", error)
        throw error
      });
    },

    async signup({ dispatch }, form) {
      // check if invite file exists in keycodes and contains the same email
      // fetch user profile
      return fb["db"].collection("keycodes").doc(form.keycode).get().then(function(documentFile) {
        let document = documentFile.data()
        if(!document) throw {'message': "Key or email wrong"};  // document war nicht auffindbar
        let granted = form.email === document.email
        if(!granted) throw {'message': "Key or email wrong"};  // email stimmt nicht überein
      
        // sign user up
        let credentials = {};
        return fb.auth.createUserWithEmailAndPassword(form.email, form.password).then(function(user) {
          // create user profile object in userCollections
          credentials = user.user;
          return fb.usersCollection.doc(credentials.uid).set({  // return ?????
            name: form.name,
            email: form.email,
            role: 'user',
            uid: user.user.uid,
            createdOn: new Date(),
          }).then(async function() {
            // fetch user profile and set in state
            await dispatch('fetchUserProfile', credentials)
            // remove key credential document
            fb["db"].collection("keycodes").doc(form.keycode).delete();
            // change route to dashboard
            if (router.currentRoute.path === '/signup') {
              //console.log("pushed to home")
                router.push('/dashboard?dialog=welcome')
            }
            return true;
          }).catch(function(error) {
            console.log("error at doc(user.uid).set", error);
            throw error;
          });
        }).catch(error => {
          console.log("error at createUserWithEmailAndPassword", error);
          throw error;
        });
      }).catch(error => {
        console.error("This key does not exist or something else", error)
        throw error
      });
    },

    // ADD OR OVERWRITE IF DOC EXSITS
    async addData({ state }, {collection, document, post}) {
      // make sure to not write in not allowed collections if not admin

      // FIXME: DOES NOT WORK ON SUBCOLLECTIONS. maybe collection.startswith any of ["convos"]?

      //console.log("collection:::", collection);
      //console.log("helper:::", Vue.prototype.$helpers.startsWithAnyElement(["convos", "test"], collection));
/*       console.log(["convos", "test"].some(function (e) {
        console.log("le loop: ", collection, e, collection.startsWith(e))
          return collection.startsWith(e);
      })) */

      //console.log(["convosXXX", "testXXX"].filter( Vue.prototype.$helpers.startsWithAnyElement()));  //collection??

      if(state.userProfile.role !== 'admin' && !Vue.prototype.$helpers.startsWithAnyElement(['convos', 'keycodes'], collection)) {
        console.error("Error writing to collection '"+collection+"': Not allowed");
        // makes the dispatch command in component go "catch" as error
        throw {'message': "Error writing document: Not allowed"};
      }

      // If there was a filename set:
      if(document) {
        return await fb["db"].collection(collection).doc(document).set(post).then(function() {
          return document
        }).catch(function(error) {
          throw error;
        });
      } else {
        return await fb["db"].collection(collection).add(post).then(doc => {
          //console.log("worked", doc.id)
          return doc.id
        }).catch(function(error) {
          console.log("error addData", error)
          throw error;
        });
      }
    },

    // DOC MUST EXIST, MERGE DATA
    async editData({ state }, {collection, document, post}) {
      // make sure to not write in not allowed collections if not admin
      if(state.userProfile.role !== 'admin' && !["convos"].includes(collection)) {
        console.error("Error writing document: Not allowed");
        // makes the dispatch command in component go "catch" as error
        throw {'message': "Error writing document: Not allowed"};
      }
      await fb["db"].collection(collection).doc(document).update(post).then(function() {
        return true
      }).catch(function(error) {
        throw error;
      });
    },

    async mergeData({ state }, {collection, document, post}) {

      if(state.userProfile.role !== 'admin' && !Vue.prototype.$helpers.startsWithAnyElement(["convos"], collection)) {
        console.error("X Error writing to collection '"+collection+"': Not allowed");
        // makes the dispatch command in component go "catch" as error
        throw {'message': "XXX Error writing document: Not allowed"};
      }

      /* db.collection('users').doc('random-id').set({
        "friends": {
          "friend-uid-3": true
        }
      },{merge:true}) */

      await fb["db"].collection(collection).doc(document).set(post, {merge:true}).then(function() {
        return true
      }).catch(function(error) {
        throw error;
      });
    },

    async deleteData({ state }, {collection, document}) {
      // make sure to not write in not allowed collections if not admin
      if(state.userProfile.role !== 'admin' && !["convos"].includes(collection)) {
        console.error("Error writing document: Not allowed");
        // makes the dispatch command in component go "catch" as error
        throw {'message': "Error writing document: Not allowed"};
      }
      await fb["db"].collection(collection).doc(document).delete().then(function() {
        return true
      }).catch(function(error) {
        throw error;
      });
    },

    /* deletePath({ state }, path) {
      // Use firebase function to delete document AND its nested subcollections

      // technically not necessary but i cant get rid of { state } in this functions declaration....
      if(state.userProfile.role !== 'admin' && !["convos"].includes(path)) {
        console.error("Error writing document: Not allowed");
        // makes the dispatch command in component go "catch" as error
        throw {'message': "Error writing document: Not allowed"};
      }

      // var deleteFn = firebase.functions().httpsCallable('recursiveDelete');
      let deleteFn = fb.httpsCallable('recursiveDelete');
      deleteFn({ path: path })
          .then(function(result) {
              console.log('Delete success: ' + JSON.stringify(result));
          })
          .catch(function(err) {
              console.log('Delete failed, see console,');
              console.warn(err);
          });
    } */
  
  }
})


export default store
