<template>
    <div class="pr-3">
      <!-- <pre> {{settings}}</pre> -->
      <!-- showDates -->
      <v-switch
        hide-details
        class="mb-3 pt-0 mt-0 ml-1 mr-4"
        inset
        v-model="settings.showDates"
        @change="editSettings('showDates', settings.showDates)"
        :label="settings.showDates ? 'Work with dates: Use fixed time reference' : 'Work with dates: No'"
        append-icon="mdi-help-circle"
        @click:append="toggleElement('showDates')"
      ></v-switch>
      <v-card-text v-if="toggledElements.includes('showDates')" class="white--text mt-0 mb-8 pa-3 grey darken-2 rounded">
        Display dates and time in between messages and use a fixed time reference for calculation.
      </v-card-text>

      <!-- Reference Date -->
      <div v-if="settings.showDates" :class="$vuetify.breakpoint.smAndUp ? 'ml-6' : ''">
          <v-text-field
            dense
            readonly
            hide-details
            class="ma-0 mb-3 mr-4"
            prepend-icon="mdi-calendar-star"
            append-outer-icon="mdi-help-circle"
            @click:append-outer="toggleElement('referenceDate')"
            @click="toggleElement('referenceDate')"
            filled background-color="grey" 
            :value="this.$helpers.fbTimeToString(settings.referenceDate)"
          ></v-text-field>

          <v-card-text v-if="toggledElements.includes('referenceDate')" class="white--text mt-0 mb-8 pa-3 grey darken-2 rounded">
            <v-card-title class="justify-center body-1 pa-0 mb-2">
              <DateEdit
                label=""
                btnColor="grey darken-1"
                btnTextColor="white--text"
                :hideMinutesManipulation="true"
                :lastDate="this.$moment.unix(settings.referenceDate.seconds)"
                @newDate="[
                  editSettings('referenceDate', $event.fbTimestamp),
                  editSettings('systemBar', {'time': $moment($event.jsDate).format('HH:mm')})
                ]"
              />
            </v-card-title>

            Use this date picker to determine which point in time is "now".<br>
            All time is frozen. A message sent 4 minutes ago will always be displayed as "4 minutes ago",
            no matter how long you look at the screen.
            <br>
            This time is also visible in the system bar if it is activated.
          </v-card-text>
      </div>

      <v-divider v-if="settings.showDates" class="pt-2 pb-2"></v-divider>

      <!-- randomTimeIntervals -->
      <v-switch
        v-if="settings.showDates"
        hide-details
        class="mb-3 pt-0 mt-0 ml-1 mr-4"
        :class="highlights.includes('autoIncrement') ? 'warning' : ''"
        inset
        v-model="settings.randomTimeIntervals"
        @change="editSettings('randomTimeIntervals', settings.randomTimeIntervals)"
        :label="settings.randomTimeIntervals ? 'Advance time randomly: After every sent message' : 'Advance time randomly: Deactivated'"
        append-icon="mdi-help-circle"
        @click:append="toggleElement('randomTimeIntervals')"
      ></v-switch>
      <v-card-text v-if="toggledElements.includes('randomTimeIntervals')" class="white--text mt-0 mb-8 pa-3 grey darken-2 rounded">
        If set, every message is sent with a time in between 1 and 4 mins.
      </v-card-text>

      <v-divider class="pt-2 pb-2"></v-divider>

      <!-- forceType -->
      <v-switch
        hide-details
        class="mb-3 pt-0 mt-0 ml-1 mr-4"
        inset
        v-model="settings.forceType"
        @change="editSettings('forceType', settings.forceType)"
        :label="settings.forceType ? 'Force type: Activated' : 'Force type: Deactivated (allow freetext)'"
        append-icon="mdi-help-circle"
        @click:append="toggleElement('forceType')"
      ></v-switch>
      <v-card-text v-if="toggledElements.includes('forceType')" class="white--text mt-0 mb-8 pa-3 grey darken-2 rounded">
        If enabled, the user with the prop device is not able to type freely.
        Instead what appears on screen and what is "sent" is always the next message recorded by you in history mode.
      </v-card-text>

      <v-divider class="pt-2 pb-2"></v-divider>

      <!-- showTypeIndicator -->
      <v-switch
        hide-details
        class="mb-3 pt-0 mt-0 ml-1 mr-4"
        inset
        v-model="settings.showTypeIndicator"
        @change="editSettings('showTypeIndicator', settings.showTypeIndicator)"
        :label="settings.showTypeIndicator ? 'Type indicator: Show' : 'Type indicator: Hide'"
        append-icon="mdi-help-circle"
        @click:append="toggleElement('showTypeIndicator')"
      ></v-switch>
      <v-card-text v-if="toggledElements.includes('showTypeIndicator')" class="white--text mt-0 mb-8 pa-3 grey darken-2 rounded">
          Display a type indicator before a message is sent from the controller to the messenger.
          The longer the message, the longer takes typing.<br>
          This delays the actual receiving of messages on the prop device.
          You can also send freetext messages there, so no need for a history if you wish.
      </v-card-text>

      <v-divider class="pt-2 pb-2"></v-divider>

      <!-- autoSeeMessages -->
      <v-switch
        hide-details
        class="mb-3 pt-0 mt-0 ml-1 mr-4"
        inset
        v-model="settings.autoSeeMessages"
        @change="editSettings('autoSeeMessages', settings.autoSeeMessages)"
        :label="settings.autoSeeMessages ? 'Auto-\'see\' message: Immediately' : 'Auto-\'see\' message: Messages are received unseen'"
        append-icon="mdi-help-circle"
        @click:append="toggleElement('autoSeeMessages')"
      ></v-switch>
      <v-card-text v-if="toggledElements.includes('autoSeeMessages')" class="white--text mt-0 mb-8 pa-3 grey darken-2 rounded">
          Every message sent from the prop device is immediately checked as "seen",
          as if the recipient is directly looking on the chat history.
      </v-card-text>
    </div>
</template>

<script>
// import { db } from '../firebase'
import DateEdit from '@/components/DateEdit'

export default {
  props: {
    convoId: String,
    settings: Object,
    highlights: Array,
  },

  components: {
    DateEdit
  },

  data () {
    return {
      waitTosafe: false,
      toggledElements: [],
    }
  },
  computed: {
  },
  watch: {
    /* settings: {
      // the callback will be called immediately after the start of the observation
      //immediate: true, 
      deep: true, 
      async handler () {
        //clearTimeout(this.waitTosafe)
        //let that = this;
        //this.waitTosafe = setTimeout(function() {
          this.safeSettings();  // that
        //}, 1000);
      }
    } */
  },
  created() {
  },
  methods: {
    toggleElement: function(element) {
    if(this.toggledElements.includes(element)) {
        this.$helpers.removeFromArray(this.toggledElements, element);
      } else {
        this.toggledElements.push(element);
      }
    },

    editSettings(key, value) {
      //console.log(key, value)
      this.$helpers.removeFromArray(this.highlights, 'autoIncrement');

      // Write data to DB
      this.$store.dispatch('mergeData', {
        'collection': 'convos/',
          'document': this.convoId,
          'post': {'settings': {[key]: value}}
      }).then(() => {
        //console.log("successsss!");
      });
    },


    /* editSettings() {
      this.$helpers.removeFromArray(this.highlights, 'autoIncrement');
      db.collection("convos")
        .doc(this.convoId)
        .update({'settings': this.settings})
        .then(() => {
          console.log("success written!")
        })
    }, */
  },
}
</script>