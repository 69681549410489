<template>
    <div>
      <!-- <pre>{{settings.systemBar}}</pre> -->

      <!-- "display": true, -->

      <!-- @input="delaySaving('message', message.message)" -->

      <v-switch
        hide-details
        class="mb-3 pt-0 mt-0 ml-1"
        inset
        v-model="settings.systemBar.display"
        :label="settings.systemBar.display ? 'System bar: Show' : 'System bar: Hide'"
        @change="editSettings('display', settings.systemBar.display)"
      ></v-switch>

      <div v-if="settings.systemBar.display">
        <!-- "time": "14:05", -->
        <v-text-field
          class="mb-2 inline-block mr-2"
          :class="$vuetify.breakpoint.smAndDown ? 'x-30p' : 'x-25p'"
          dense
          hide-details
          filled
          clearable
          v-model.trim="settings.systemBar.time"
          label="time"
          type="time"
          :value="settings.systemBar.time"
          @input="editSettings('time', settings.systemBar.time)"
        >
        </v-text-field>

        <!-- "provider": "N-Provider", -->
        <v-text-field
          class="mb-2 mr-2 inline-block"
          :class="$vuetify.breakpoint.smAndDown ? 'x-60p' : 'x-70p'"
          dense
          hide-details
          filled
          v-model.trim="settings.systemBar.provider"
          label="provider"
          type="text"
          :value="settings.systemBar.provider"
          @input="delaySaving('provider', settings.systemBar.provider)"
        >
        </v-text-field>

        <!-- "email": 4, -->
        <v-text-field
          class="mb-2 mr-2 inline-block x-20p"
          dense
          hide-details
          filled
          v-model.trim="settings.systemBar.email"
          label="Emails"
          type="number"
          :value="settings.systemBar.email"
          @input="editSettings('email', settings.systemBar.email)"
        >
        </v-text-field>

        <!-- "sms": 4, -->
        <v-text-field
          class="mb-2 mr-2 inline-block x-20p"
          dense
          hide-details
          filled
          v-model.trim="settings.systemBar.sms"
          label="sms"
          type="number"
          :value="settings.systemBar.sms"
          @input="editSettings('sms', settings.systemBar.sms)"
        >
        </v-text-field>

        <!-- "unansweredCall": true -->
        <v-switch
          hide-details
          class="mb-3 mt-0 ml-1 pt-0 nudge-y--50 inline-block"
          :class="$vuetify.breakpoint.smAndDown ? 'mt-5' : ''"
          inset
          v-model="settings.systemBar.unansweredCall"
          :label="settings.systemBar.unansweredCall ? '1 unanswered call' : 'No unanswered call'"
          @change="editSettings('unansweredCall', settings.systemBar.unansweredCall)"
        ></v-switch>

        <!-- "message": "incoming message", -->
        <v-text-field
          class="mb-2"
          dense
          hide-details
          filled
          v-model.trim="settings.systemBar.message"
          label="Var. message"
          type="text"
          :value="settings.systemBar.message"
          @input="delaySaving('message', settings.systemBar.message)"
        >
        </v-text-field>

        <!-- "wlan": 4, -->
        <v-card-title class="justify-center mb-2 pa-2 grey darken-2 op-50 rounded relative"
          :class="$vuetify.breakpoint.smAndDown ? 'pt-6' : ''"
        >
          <div class="caption ml-3 mt-1 absolute top left">Wlan connection</div>
          <v-icon
            large
            @click="settings.systemBar.wlan = 0, editSettings('wlan', 0)"
            class="mr-3"
            :class="settings.systemBar.wlan === 0 ? 'red--text' : ''"
          >mdi-wifi-strength-alert-outline</v-icon>
          <v-icon
            large
            @click="settings.systemBar.wlan = 1, editSettings('wlan', 1)"
            class="mr-3"
            :class="settings.systemBar.wlan === 1 ? 'white--text' : ''"
          >mdi-wifi-strength-1</v-icon>
          <v-icon
            large
            @click="settings.systemBar.wlan = 2, editSettings('wlan', 2)"
            class="mr-3"
            :class="settings.systemBar.wlan === 2 ? 'white--text' : ''"
          >mdi-wifi-strength-2</v-icon>
          <v-icon
            large
            @click="settings.systemBar.wlan = 3, editSettings('wlan', 3)"
            class="mr-3"
            :class="settings.systemBar.wlan === 3 ? 'white--text' : ''"
          >mdi-wifi-strength-3</v-icon>
          <v-icon
            large
            @click="settings.systemBar.wlan = 4, editSettings('wlan', 4)"
            class="mr-3"
            :class="settings.systemBar.wlan === 4 ? 'white--text' : ''"
          >mdi-wifi-strength-4</v-icon>
        </v-card-title>

        <!-- "network": 0, -->
        <v-card-title class="justify-center mb-2 pa-2 grey darken-2 op-50 rounded relative"
          :class="$vuetify.breakpoint.smAndDown ? 'pt-6' : ''"
        >
          <div class="caption ml-3 mt-1 absolute top left">Network connection</div>
          <v-icon
            large
            @click="settings.systemBar.network = 0, editSettings('network', 0)"
            class="mr-3" :class="settings.systemBar.network === 0 ? 'red--text' : ''"
          >mdi-network-strength-off-outline</v-icon>
          <v-icon
            large
            @click="settings.systemBar.network = 1, editSettings('network', 1)"
            class="mr-3" :class="settings.systemBar.network === 1 ? 'white--text' : ''"
          >mdi-network-strength-1</v-icon>
          <v-icon
            large
            @click="settings.systemBar.network = 2, editSettings('network', 2)"
            class="mr-3" :class="settings.systemBar.network === 2 ? 'white--text' : ''"
          >mdi-network-strength-2</v-icon>
          <v-icon
            large
            @click="settings.systemBar.network = 3, editSettings('network', 3)"
            class="mr-3" :class="settings.systemBar.network === 3 ? 'white--text' : ''"
          >mdi-network-strength-3</v-icon>
          <v-icon
            large
            @click="settings.systemBar.network = 4, editSettings('network', 4)"
            class="mr-3" :class="settings.systemBar.network === 4 ? 'white--text' : ''"
          >mdi-network-strength-4</v-icon>
        </v-card-title>

        <!-- "battery": 45, -->
        <v-card-title class="justify-center mb-2 pa-2 pb-0 grey darken-2 op-50 rounded relative">
          <div class="caption ml-3 mt-1 absolute top left">Battery charge {{ settings.systemBar.battery > 100 ? 'Charging' : settings.systemBar.battery+'%' }}</div>
          <v-slider
            hide-details
            class="mt-4"
            v-model.trim="settings.systemBar.battery"
            :value="settings.systemBar.battery"
            max="105"
            min="0"
            @change="editSettings('battery', settings.systemBar.battery)"
          >
            <template v-slot:thumb-label>
              {{ settings.systemBar.battery > 100 ? 'Char.' : settings.systemBar.battery+'%' }}
            </template>
          </v-slider>

          <v-icon
            large
            :class="[
            settings.systemBar.battery <= 8 ? 'red--text' : '',
            settings.systemBar.battery > 100 ? 'green--text' : '',
            ]"
          >
            {{displayBattery(settings.systemBar.battery)}}
          </v-icon>
        </v-card-title>
      </div>

    </div>
</template>

<script>
// import { db } from '../firebase'

export default {
  props: {
    convoId: String,
    settings: Object,
  },

  data () {
    return {
      waitTosafe: false,
    }
  },
  computed: {
  },

  watch: {
    /* settings: {
      // the callback will be called immediately after the start of the observation
      //immediate: true, 
      deep: true, 
      async handler (newData) {
        clearTimeout(this.waitTosafe)
        let that = this;
        this.waitTosafe = setTimeout(function() {
          that.safeSettings(newData);
        }, 1000);
      }
    } */
  },
  created() {
    // console.log("creatd SYSTEMBAREDIT");
  },
  methods: {
    delaySaving(key, value){
      clearTimeout(this.waitTosafe)
      let that = this;
      this.waitTosafe = setTimeout(function() {
        that.editSettings(key, value)
      }, 1000);
    },

    editSettings(key, value) {
      //console.log(key, value)
      if(key === 'time' && !value) value = '';  // because "clearable" time field makes null
      // Write data to DB
      this.$store.dispatch('mergeData', {
        'collection': 'convos/',
          'document': this.convoId,
          'post': {'settings': {'systemBar': {[key]: value}}}
      })
      /* .then(() => {
        console.log("successsss!");
      }); */
    },

    /* safeSettings(data) {
      if(!data.systemBar.time) data.systemBar.time = '';  // because "clearable" time field makes null
      db.collection("convos")
        .doc(this.convoId)
        .update({'settings': data})
        .then(() => {
          console.log("success written!")
        })
    }, */

    displayBattery(value){
      let icon = '';
      if(value > 100) {  // charging
        icon = 'mdi-battery-charging-50';
      } else if(value > 90) {  // 90-100%
        icon = 'mdi-battery';
      } else if(value > 80) {
        icon = 'mdi-battery-90'
      } else if(value > 70) {
        icon = 'mdi-battery-80'
      } else if(value > 60) {
        icon = 'mdi-battery-70'
      } else if(value > 50) {
        icon = 'mdi-battery-60'
      } else if(value > 40) {
        icon = 'mdi-battery-50'
      } else if(value > 30) {
        icon = 'mdi-battery-40'
      } else if(value > 20) {
        icon = 'mdi-battery-30'
      } else if(value > 10) {
        icon = 'mdi-battery-20'
      } else if(value >= 0) {
        icon = 'mdi-battery-10'  // red below 8
      }
      return icon
    },
  },
}
</script>