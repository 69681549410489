<template>
  <v-container>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <router-link to="/">
        <v-img
          width="125"
          contain
          :src="require('../assets/logo.svg')"
          transition="scale-transition"
        />
      </router-link>
      <v-spacer></v-spacer>
      <!-- <a href="#" :onClick="'MyWindow=window.open(\''+baseUrl + '/#\',\'MyWindow\',\'width=600,height=300,toolbar=no, location=no, directories=no,  status=no, menubar=no, \'); return false;'">
      Open in Popup
      </a>
      <v-spacer></v-spacer> -->

      <!-- DESKTOP NAV -->
      <v-toolbar-items v-if="$vuetify.breakpoint.mdAndUp">
        <v-btn v-if="!auth" to="/rabbithole" color="primary" depressed class="ml-2">Convo</v-btn>
        <v-btn v-if="auth" to="/dashboard" color="primary" depressed class="ml-2">Dashboard</v-btn>
        <v-btn v-if="auth" to="/profile" color="primary" depressed class="ml-2">Profile</v-btn>
        <v-btn to="/about" color="primary" depressed class="ml-2">About</v-btn>
        <v-btn v-if="!auth" to="/login" color="primary" depressed class="ml-2">Login</v-btn>
        <v-btn v-if="auth" small fab icon @click="logout()" color="white" class="primary" v-tooltip="'Logout'">
          <v-icon small>mdi-location-exit</v-icon>
        </v-btn>
      </v-toolbar-items>
      
      <!-- JAMBURGER -->
      <v-app-bar-nav-icon v-else @click.stop="drawer = !drawer">
        <v-icon large>mdi-microsoft-xbox-controller-menu</v-icon>
      </v-app-bar-nav-icon>

    </v-app-bar>

    <!-- MOBILE NAV -->
    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
    >

      <v-list-item class=" pt-1">
        <v-list-item-content>
          <v-list-item-title>
            <v-img
              width="125"
              contain
              class="op-25"
              :src="require('../assets/logoBlack.svg')"
            />
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
          active-class="primary--text grey lighten-5"
        >
          <v-list-item v-if="!auth" to="/rabbithole">
            <v-list-item-title>
                Convo
            </v-list-item-title>
          </v-list-item>

          <v-list-item v-if="auth" to="/dashboard">
            <v-list-item-title>
                Dashboard
            </v-list-item-title>
          </v-list-item>

          <v-list-item v-if="auth" to="/profile">
            <v-list-item-title>
                Profile
            </v-list-item-title>
          </v-list-item>

          <v-list-item to="/about">
            <v-list-item-title>
                About
            </v-list-item-title>
          </v-list-item>

          <v-list-item v-if="!auth" to="/login">
            <v-list-item-title>
                Login
            </v-list-item-title>
          </v-list-item>

          <v-list-item v-if="auth" @click="logout()">
            <v-list-item-title>
                Logout
            </v-list-item-title>
          </v-list-item>         
        </v-list-item-group>
      </v-list>

      <template v-slot:append>
        <div class="pa-4">
          <div v-if="auth" x-small class="grey--text caption">
            <v-icon color="grey" x-small>mdi-account-circle</v-icon>
            {{user.name}}
          </div>
        </div>
      </template>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
export default {
  props: {
    auth: Boolean,
    user: Object,
  },

  data () {
    return {
      drawer: false,
      group: null,
    }
  },
  watch: {
    group () {
      this.drawer = false
    },
  },
  computed: {
    baseUrl() {
      return window.location.protocol + "//" + window.location.host + '/#'
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('logout')
    }
  },
}
</script>