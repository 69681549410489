<template>
  <div>
    <v-container v-if="convos.length" class="pa-0">
      <p class="py-4">
        There {{$helpers.humanizeAmount(convos.length, 'is', 'are', true)}} currently <strong class="primary--text">{{$helpers.humanizeAmount(convos.length, 'Convo')}}</strong> made by you:
      </p>

      <v-layout row wrap class="pa-0 ">
          <v-flex xs12 lg6 v-for="(convo, i) in convos" :key="i" class="pa-3 pb-16 ">
            <v-card class="mb-16">
              <v-toolbar :dense="$vuetify.breakpoint.lgAndUp" color="primary white--text elevation-1">
                <!-- CONVO TITLE -->
                <v-avatar v-if="$vuetify.breakpoint.smAndUp" class="mr-3 white" size="36">
                  <v-icon color="primary ma-0">mdi-frequently-asked-questions</v-icon>
                </v-avatar>
                <v-toolbar-title class="text-break">{{convo.name}}</v-toolbar-title>
                <v-spacer></v-spacer>

                <v-card-subtitle
                  class="caption text-right white--text pa-0 ellipsis" 
                >
                  <!-- CONVO INFOS -->
                  <div class="ellipsis">
                    Created {{ $helpers.fbTimeToRelativeToNow(convo.createdOn) }}
                    <span v-if="convo.editedOn">
                      <span v-if="$vuetify.breakpoint.mdAndUp && !$vuetify.breakpoint.lgOnly">&mdash;</span>
                      <br v-else>
                      Edited {{ $helpers.fbTimeToRelativeToNow(convo.editedOn) }} </span>
                  </div>
                </v-card-subtitle>
              </v-toolbar>

              <v-container class="grey lighten-5 pa-0">
                <v-row no-gutters v-if="!toggledElements.includes(convo.id+'-editConvo')">

                  <!-- INFO AND EDIT -->
                  <!-- mdOnly <v-col cols="12" md="4" class="pa-3"> -->
                  <v-col cols="12" sm="5" md="4" class="pa-3" :class="$vuetify.breakpoint.xsOnly ? 'pb-8' : ''">
                    <div class="mb-2 text-center">
                      <div class="caption">
                        {{convo.characters.length}} Characters,
                        {{getMessageCounter(convo.id)}}:
                      </div>
                      {{getAllCharacters(convo.characters)}}
                    </div>

                    <v-divider class="my-3"></v-divider>

                    <!-- EDIT -->
                    <v-btn
                      :block="$vuetify.breakpoint.smAndUp"
                      :class="$vuetify.breakpoint.xsOnly ? 'mr-2' : ''"
                      class="mb-2"
                      @click="toggleElement(convo.id+'-editConvo')"
                    >
                      <v-icon class="mr-2">mdi-pencil</v-icon>
                      Edit
                    </v-btn>

                    <!-- Duplicate -->
                    <v-btn
                      :block="$vuetify.breakpoint.smAndUp"
                      :class="$vuetify.breakpoint.xsOnly ? 'mr-2' : ''"
                      class="mb-2"
                      @click="$emit('duplicate', JSON.parse(JSON.stringify(convo)))"
                    >
                      <!-- <span v-if="$vuetify.breakpoint.mdAndUp" class="mr-2"> -->
                      <v-icon small class="mr-2">mdi-content-duplicate</v-icon>
                      Duplicate
                    </v-btn>
                      
                    <!-- Delete -->
                    <v-btn
                      :block="$vuetify.breakpoint.smAndUp"
                      :class="$vuetify.breakpoint.xsOnly ? 'mr-2' : ''"
                      class="mb-2 error"
                      v-if="!toggledElements.includes(convo.id+'-editConvoSureDelete')" 
                      @click="toggleElement(convo.id+'-editConvoSureDelete')"
                    >
                      <v-icon class="mr-2">mdi-trash-can</v-icon>
                      Delete
                    </v-btn>
                    <!-- abort delete -->
                    <v-btn
                      icon
                      v-if="toggledElements.includes(convo.id+'-editConvoSureDelete')"
                      color="white"
                      class="primary mr-4"
                      @click="toggleElement(convo.id+'-editConvoSureDelete')"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <!-- delete now -->
                    <v-btn
                      v-if="toggledElements.includes(convo.id+'-editConvoSureDelete')"
                      color="white"
                      class="error"
                      @click="deleteConvo(convo.id); toggleElement(convo.id+'-editConvoSureDelete')"
                    >
                      <v-icon class="mr-2">mdi-alert</v-icon>
                      Are you sure?
                    </v-btn>

                    <v-divider class="my-3"></v-divider>

                    <!-- CONTROLLER -->
                    <v-btn
                      block rounded
                      class="primary mr-2"
                      :to="'/controller?convo='+convo.accessCode"
                    >
                      <v-icon class="mr-2">mdi-podcast</v-icon>
                      Controller
                    </v-btn>

                    <!-- MESSENGER BTN -->
                    <v-btn
                      block rounded
                      class="secondary mt-2"
                      v-if="$device.mobile && $vuetify.breakpoint.smAndDown"
                      :to="'/messenger?convo='+convo.accessCode" target="_blank"
                    >
                      <v-icon class="mr-2">mdi-cellphone-nfc</v-icon>
                      Messenger
                    </v-btn>
                  </v-col>


                  <!-- MESSENGER -->
                  <!-- mdOnly <v-col cols="12" md="8" class="pa-0 grey"> -->
                  <v-col cols="12" sm="7" md="8" class="pa-0 grey darken-2">
                    <v-container class="pa-0">
                      <!-- MESSENGER TITLE -->
                      <v-row no-gutters class="pa-3 pb-1 white--text">
                        <v-col cols="12" sm="12" class="pa-0">
                          <v-card-title class="justify-center py-0 fill-width subtitle">
                            Choose a way to open the <strong class="secondary--text text--lighten-1 px-2">Messenger</strong>
                            <v-icon color="secondary lighten-1">mdi-cellphone-nfc</v-icon>:
                            <!-- {{!$device.mobile ? 'on a mobile device' : 'on another mobile device'}}: -->
                          </v-card-title>
                        </v-col>
                      </v-row>

                      <!-- MESSENGER SHARING -->
                      <v-row no-gutters>
                        <!-- qrcode -->
                        <v-col cols="12" sm="12" md="5" lg="12" xl="5" class="pa-3 pr-0" >
                          <div class="white--text pl-1 mb-2" v-if="$vuetify.breakpoint.xsOnly">
                            <span class="bolder secondary--text text--lighten-1">Option A:</span>
                            Scan this QRcode with a camera app or use the scanner on {{baseUrl}}/rabbithole:
                          </div>
                          <div
                            v-tooltip="'Option A: Scan this QRcode with a camera app or use the scanner on '+baseUrl+':'"
                            @click="bigTimeQrCode(baseUrl+'/messenger?convo='+convo.accessCode)"
                            class="text-center fill-width"
                          >
                            <div class="inline-block maxx-400">
                              <vueQrCode
                                class="fill-width rounded elevation-10"
                                :margin="3"
                                :value="baseUrl+'/messenger?convo='+convo.accessCode"
                              />
                            </div>
                          </div>
                        </v-col>

                        <!-- Sharing -->
                        <v-col cols="12" sm="12" md="7" lg="12" xl="7" class="pa-3" >
                          
                          <div class="white--text pl-1 mb-2" v-if="$vuetify.breakpoint.xsOnly">
                            <span class="bolder secondary--text text--lighten-1">Option B:</span>
                            Share this URL with the device:
                          </div>
                          <div
                            v-tooltip="'Option B: Share this URL with the device:'"
                            class="relative rounded grey secondary--text lighten-5 pa-2 mx-1 pointer elevation-4"
                            @click="$helpers.copyClipBoard(baseUrl+'/messenger?convo='+convo.accessCode)"
                          > 
                            <div class="absolute top left pa-2 nudge-y--12">
                              <v-icon color="secondary">mdi-share-variant</v-icon>
                            </div>
                            <div class="ellipsis px-7">
                              {{baseUrl}}/messenger?convo={{convo.accessCode}}
                            </div>
                            <div class="absolute top right pa-2 nudge-y--12">
                              <v-icon color="grey" small class="mirr-y">mdi-checkbox-multiple-blank</v-icon>
                            </div>
                          </div>
                          <v-divider class="mb-4"></v-divider>
                          <div class="white--text pl-1 mb-2" v-if="$vuetify.breakpoint.xsOnly">
                            <span class="bolder secondary--text text--lighten-1">Option C:</span>
                            Open {{baseUrl}} and enter this:
                          </div>
                          <div
                            v-tooltip="'Option C: Open '+baseUrl+' and enter this:'"
                            class="rounded grey lighten-5 pa-2 pl-0 mx-1 pointer elevation-4"
                            @click="$helpers.copyClipBoard(convo.accessCode)"
                          > 
                            <div class="inline-block nudge-y-12">
                              <v-icon color="secondary" style="transform: translate(0.3em, -0.17em)">mdi-face-woman</v-icon>
                              <v-icon small color="secondary" class="rot-90">mdi-wifi</v-icon>
                            </div>
                            <span class="secondary--text mx-2">{{convo.accessCode}}</span>
                            <v-icon color="grey" small class="mirr-y">mdi-checkbox-multiple-blank</v-icon>
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                </v-row>

                <!-- EDIT -->
                <v-row no-gutters v-else class="pa-3 pb-0">
                  <NewAndEditConvo
                    class="mb-6"
                    :editing="true"
                    :editConvoData="convo"
                    :editingUid="convo.id"
                    :auth="auth"
                    :user="user"
                    @cancelEdit="toggleElement(convo.id+'-editConvo')"
                    @successfulEdit="successfulEdit(convo.id, convo.name)"
                  />
                </v-row>
              </v-container>
            </v-card>
          </v-flex>
      </v-layout>

      
    </v-container>

    <p v-else class="py-4">
      There are currently no Convos by you.
    </p>
 
    <!-- <pre class="grey pa-4">convos {{convos}}</pre> -->

    <!-- ERROR HANDLING -->
    <p v-if="error" class="ml-3 error--text">
      {{error}}
    </p>

    <!-- QR CODE DIALOG -->
    <v-dialog
      v-model="qrCodeDialog"
      max-width="600"
    >
      <v-card @click="qrCodeDialog = false" max-width="800">
          <vueQrCode
            class="fill-width fill-height"
            :scale="12"
            :margin="3"
            :value="qrCodeDialogQrValue"
          />
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { db } from '../firebase'
import NewAndEditConvo from '@/components/NewAndEditConvo.vue'
// https://www.npmjs.com/package/vue-qrcode
import vueQrCode from "vue-qrcode";

export default {
  name: 'ListConvos',
  props: {
    auth: Boolean,
    user: Object,
  },
  components: {
    NewAndEditConvo, vueQrCode
  },
  data () {
    return {
      convoName: '',
      error: '',
      valid: false,
      qrCodeDialog: false,
      qrCodeDialogQrValue: '',
      convos: [],
      toggledElements: [],
      messagesCounter: [],
    }
  },
  mounted() {
    // because ListConvos does not get id while hard reaload
    if(!this.user.uid) this.$emit('hardRelaodFail');  // haha i'm lazy

    // get all documents from live collection
    db.collection('convos')
      .where('createdBy', '==', this.user.uid)
        .orderBy("createdOn", "desc") //.sortBy('createdOn') asc desc?
          .onSnapshot(querySnapshot => {
            let newData = [];
            querySnapshot.forEach(doc => {
              let f = doc.data();
              f.id = doc.id;
              f.numberOfMessages = 0
              this.messagesCounter = []
              this.getAllMessages(f.id)
              newData.push(f);
            });
            this.convos = newData;
          });    
  },

  computed: {
    baseUrl() {
      return window.location.protocol + "//" + window.location.host + '/#'
    },
    /* mailText() {
      return this.$helpers.toUrlText('Hello!\n\nYour private keycode for signing up on convo is '+this.result+'. Note that this coe is matched to your email address ('+this.email+').\n\nCopy paste that or just visit this link:\n'+this.baseUrl+'/signup?keycode='+this.result+'\n\nThis is a free tool for non-commercial or student productions, or if you wish to test this. For other type of productions, a once-per-production-fee that works for us both should be negotiated beforehand.\nThank you for using my service.\n\nBest,\nFabian')
    }, */

    /* getQrCode: function(refContainer, accessCode) {
      console.log(refContainer, accessCode, this.$refs[refContainer]);
      /* return new QRCode(document.getElementById("qrcode"), accessCode);
      let test = new this.$qrcode(document.getElementById(refContainer), accessCode);
      console.log(test);
      return true;
      //return this.$qrcode(this.$refs[refContainer], accessCode);
    }, */
  },
  methods: {
    getAllMessages: function (convoId) {
      db.collection('convos/'+convoId+'/messenger')
        .where("disabled", "==", false)
        .get()
        .then(querySnapshot => {
          this.messagesCounter.push({'uid': convoId, 'messageCount': querySnapshot.size}) // will return the collection size
        });
    },

    getMessageCounter(convoUid) {
      let numberOfMessages = this.$helpers.findKey(this.messagesCounter, 'uid', convoUid, "messageCount")
      return this.$helpers.humanizeAmount(numberOfMessages, 'Message')
    },

    getAllCharacters: function(characters) {
      let allNames = [];
      for(let i = 0; i < characters.length; i++){
        // get from "First Last" => "First L."
        let name = characters.length > 2 ? this.$helpers.firstNameInitial(characters[i].name) : characters[i].name;
        allNames.push(name);
      }
      return this.$helpers.joinHumanList(allNames);
    },

    toggleElement: function(element) {
      if(this.toggledElements.includes(element)) {
        this.$helpers.removeFromArray(this.toggledElements, element);
      } else {
        this.toggledElements.push(element);
      }
    },

    successfulEdit(id, convoName) {
      this.$toasted.global.info({msg: 'Successfully edited "'+convoName+'"'});
      this.toggleElement(id+'-editConvo')
    },

    deleteConvo: function(id) {
      console.log("REMOVE MANUALLY ALL DOCUMENTS FROM MESSENGER AND RECORDING FIRST", id);
      console.log("OR BETTER USE A CLOUD FUNCTION TO DO THAT......");

      // TODO:
      // REMOVE MANUALLY ALL DOCUMENTS FROM MESSENGER AND RECORDING FIRST
      // THEN DELETE CONVO...
      // MAKE A STORE FUNCTION OUT OF THIS WITHOUT ALL THE CRAP TRY/CATCH


      this.$store.dispatch('deleteData', {'collection': 'convos', 'document': id}).then(() => {
        console.log('Sucessful removed convo.', id)
        this.$toasted.global.info({msg: 'Successfully removed convo.'});
        /* this.success = 'Sucessful removed post';
        this.error = ''; */
      }).catch(error => {
        console.log(error)
        console.error(error.message);
        /* this.error = error.message;
        this.success = ''; */
      });
    },
    bigTimeQrCode: function(qrCodeString) {
      //console.log(qrCodeString)
      this.qrCodeDialogQrValue = qrCodeString;
      this.qrCodeDialog = true;
    },
  },
}
</script>
