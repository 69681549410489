<template>
  <div>
    <div class="text-h4 my-4">Profile</div>
    <!-- <p class="x-450">
      You are logged in as {{user.name}} / {{user.role}}
      <pre v-if="user.role === 'admin'" class="pt-10 grey lighten-3 pa-3">user {{user}}</pre>
    </p> -->

    <form @submit.prevent="saveData">
    <v-container grid-list-md text-xs-center class="ma-0 pa-0">
      <v-layout row wrap>
        <v-flex xs12 sm6 md4>
          <v-text-field filled background-color="grey lighten-3" dense class="input" label="Name" type="text" placeholder="Name" v-model="profile.name" required></v-text-field>
        </v-flex>
        <!-- <v-flex xs12 sm6 md4>
          <v-text-field filled background-color="grey lighten-3" dense class="input" label="Role" type="text" placeholder="Role" v-model="profile.role" disabled></v-text-field>
        </v-flex> -->
        <v-flex xs12 sm6 md4>
          <v-text-field filled background-color="grey lighten-3" dense class="input" label="Email" type="text" placeholder="Email" v-model="profile.email" disabled></v-text-field>
        </v-flex>
      </v-layout>
    </v-container>

    <p class="caption grey--text hint">
      Please note that you cannot change your your email address here.
      If you have to change it, please <a :href="'mailto:info@filmkulissen.ch?subject=CONVO%20Email%20reset&body=%0A%0A%0APlease%20send%20your%20user%20ID%20with%20this%20email%3A%20%0A'+user.uid">contact me</a>.
    </p>  
      
      <v-btn type="submit" color="primary" rounded class="mr-3">Update
        <v-progress-circular
          :size="16"
          :width="2"
          v-if="loading"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-btn>

      <span v-if="success" class="success--text">
        Saved to database!
      </span>

      <span v-if="somethingWrong">
        &emsp;<v-icon color="error">mdi-thumb-down</v-icon>&emsp;
      </span>
      
    </form>
  </div>
</template>

<script>
import { db } from '../firebase'

  export default {
    name: 'Test',
    props: {
      user: Object,
    },

    data () {
      return {
        profile: JSON.parse(JSON.stringify(this.user)),  // does not work on hard refresh!
        success: false,
        somethingWrong: false,
        loading: false,
      }
    },

    mounted() {
    },

    methods: {
      saveData () {
        try {
          this.loading = true;
          // Restore JS timestamps
          this.profile.createdOn = this.$helpers.restoreFbTimestampFromJsDate(this.profile.createdOn);
          this.profile.lastLogin = this.$helpers.restoreFbTimestampFromJsDate(this.profile.lastLogin);
          this.profile.lastEdited = new Date();
          db.collection("users")
            .doc(this.user.uid)
            .set(this.profile)
            .then(() => {
              this.success = true;
              console.log('updated profile')
              this.$store.dispatch('fetchUserProfile', this.user)
              this.loading = false;
            })
        } catch (err) {
          this.somethingWrong = true;
        }
      },
    },
  }
</script>
