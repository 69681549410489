<template>
  <div>
    <div class="text-h6 my-4">Like it? Invite others!</div>
    <p class="maxx-600">
      Create a key code for them to sign up. Send code via email by hitting the link after the click.
    </p>
    <v-form @submit.prevent v-model="valid" ref="form">
      <v-container grid-list-md text-xs-center class="ma-0 pa-0">
        <v-layout row wrap>
          <v-flex xs12 sm6 md4>
            <v-text-field filled background-color="grey lighten-3" dense v-model.trim="keycode" type="text" placeholder="Key code (leave empty for max. security)" :rules="keycodeRules">
            </v-text-field>
          </v-flex>
          <v-flex xs12 sm6 md4>
            <v-text-field filled background-color="grey lighten-3" dense v-model.trim="email" type="email" placeholder="Email" :rules="emailRules">
            </v-text-field>
          </v-flex>
          <v-flex xs12 sm12 md4>
            <v-btn type="submit" :disabled="!valid" color="primary" class="mr-3" @click="createKeycode(keycode, email)">Get key</v-btn>
            <v-btn color="grey lighten-2" v-if="keycode.length + email.length > 0" @click="clearForm()">Clear</v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <p v-if="result">
      The keycode for user {{email}} is <pre class="inline grey lighten-3 pa-2 mx-1">{{result}}</pre>
      <v-btn icon @click="$helpers.copyClipBoard(result, 'Access code')">
        <v-icon color="grey" small class="mirr-y">mdi-checkbox-multiple-blank</v-icon>
      </v-btn>
      <a :href="'mailto:'+email+'?subject=Your%20Keycode%20for%20CONVO&body='+mailText">
        <strong class="primary--text">
          Send email to {{email}}
        </strong>
      </a>
    </p>
    <!-- ERROR HANDLING -->
    <p v-if="error" class="ml-3 error--text">
      {{error}}
    </p>

  </div>
</template>

<script>
export default {
  name: 'Keycodes',
  props: {
    auth: Boolean,
    user: Object,
  },
  components: {
  },
  data () {
    return {
      keycode: '',
      email: '',
      result: '',
      error: '',
      valid: false,
      /* nameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length <= 10) || 'Name must be less than 10 characters',
      ], */
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      keycodeRules: [
        v => (v.split(' ').length <= 1) || 'No spaces allowed',
        v => /^[A-Za-z0-9_-]*$/.test(v) || 'No special characters',
      ]
    }
  },
  created() {
  },
  computed: {
    baseUrl() {
      return window.location.protocol + "//" + window.location.host + '/#'
    },
    mailText() {
      return this.$helpers.toUrlText('Hello!\n\nYour private keycode for signing up to '+this.baseUrl+' is:\n\n'+this.result+'\n\nNote that this code is matched to your email address ('+this.email+').\n\nCopy paste that or just visit this link:\n\n'+this.baseUrl+'/signup?keycode='+this.result+'AMPERSANDemail='+this.email+'\n\nThis is a free tool for non-commercial or student productions. For other type of productions, a once-per-production-fee that works for us both should be negotiated beforehand with fabian from info@filmkulissen.ch.\nThank you for using this service.\n\nBest,\n'+this.user.name)
    },
  },
  methods: {
    createKeycode: function(keycode, email) {
      console.log(keycode, email);
      let document = keycode ? keycode : '';
      this.$store.dispatch('addData', {"collection": "keycodes", "document": document, "post": {'email': email}}).then(keycodeFromDocName => {
        console.log("Success!")
        this.result = keycodeFromDocName;
        this.error = '';
      }).catch(error => {
        this.result = '';
        console.log(error)
        console.error(error.message);
        this.error = error.message;
      });
    },
    clearForm() {
      this.keycode = '';
      this.email = '';
      this.result = '';
      this.resetValidation();
    },
    /* validate () {
      this.$refs.form.validate()
    },
    reset () {
      this.$refs.form.reset()
    }, */
    resetValidation () {
      this.$refs.form.resetValidation()
    },
  },
}
</script>
