<template>
  <v-container fill-height fluid class="pa-0 ma-0 grey darken-4"
    :class="$vuetify.breakpoint.mdAndUp ? 'pt-10' : 'pt-8'"
  >
      <v-row
        class="fill-height"
        align="center"
        no-gutters
      >
        <v-col
        class="ma-0 pa-0 fill-height"
        :class="$vuetify.breakpoint.mdAndUp ? 'pr-1' : ''"
        cols="12"
        md="6">
          <Messenger :auth="auth" :user="user" :controller="true" :convoAccessCodeFromController="this.$route.query.convo"/>
        </v-col>

        <v-col
        v-if="this.$vuetify.breakpoint.mdAndUp"
        class="ma-0 pa-0 fill-height"
        :class="$vuetify.breakpoint.mdAndUp ? 'pl-1' : ''"
        cols="12"
        md="6">
          <Messenger :auth="auth" :user="user" :messengerPreviewFromController="true" :convoAccessCodeFromController="this.$route.query.convo"/>
        </v-col>
      </v-row>
    </v-container >
</template>

<script>
import Messenger from '@/views/Messenger.vue'

export default {
  name: 'Controller',
  components: {
    Messenger
  },
  props: {
    auth: Boolean,
    user: Object,
    //convoId: String,
  },
  data () {
    return {
    }
  },
  created() {
  },
  methods: {
  },
}
</script>
