<template>
  <div>
    <p>
      <span class="bolder primary--text">Convo</span>, a web-app for faking a messenger app on set &mdash;
      quickfacts:
    </p>
    <p v-for="(fact, i) in facts" :key="i">
      <span v-tooltip="!details ? fact.description : false" class="bolder primary--text uppercase">{{fact.title}}</span>
      <span v-if="details"><br>{{fact.description}}</span>
    </p>
  </div>
</template>

<script>

export default {
  name: 'Facts',
  props: {
    details: Boolean,
  },

  data () {
    return {
      facts: [
        {title: 'Send messages to a prop device', description: 'Send and control text messages in our messenger. Reset. From the top.'},
        {title: 'All in-camera', description: 'No additional costs for greenscreen-replacement in post production.'},
        {title: 'installation-free', description: 'Runs directly in your browser on any device. Factory-reset or not.'},  // oder anmeldung
        {title: 'No take wasted', description: 'Do not waste time on set due to typos.'},  // of actors or actresses
        {title: 'No copyright headaches', description: 'This work and all its contents is dedicated to the public domain.'},
      ]
    }
  },
  watch: {
  },
  created() {
  },

  methods: {
  }
}
</script>

<style scoped>
</style>