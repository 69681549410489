<template>
  <div>
    <!-- RABBIT FORM -->
    <v-card
      class="mx-auto my-4 pa-4"
      max-width="344"
      outlined
    >
      <v-card-title class="justify-center text-h4 pb-8">JOIN THE CONVO</v-card-title>
      <form v-if="!qrCode" @submit.prevent="enter(accessCode)">
        <v-text-field filled background-color="grey lighten-3" v-model.trim="accessCode" type="text" placeholder="Enter access code" autocomplete="off">
        </v-text-field>

        <v-card-actions class="pl-0">
          <v-spacer></v-spacer>
          <v-btn type="submit" :disabled="accessCode.length === 0" color="primary" rounded>Join</v-btn>
          <v-btn v-if="$vuetify.breakpoint.smAndDown || $device.ios" color="secondary" @click="qrCode = true" rounded><v-icon>mdi-qrcode-scan</v-icon></v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </form>
      
      <div v-else>
        <qrcode-stream @detect="onDetect" @init="onInit" :camera="cameraMode" class="mb-4">
          <v-container
            v-if="loadingCamera || errorCamera"
            class="grey lighten-4"
            fill-height
            fluid 
            @click="resetCamera()"
          >
            <v-row align="center"
              justify="center">
              <v-col class="text-center">
                <v-progress-circular
                  v-if="loadingCamera"
                  indeterminate
                  size="44"
                  width="3"
                  color="primary"
                  class="ma-a"
                ><v-icon>mdi-camera</v-icon></v-progress-circular>

                <span v-if="errorCamera" class="error--text">
                  {{errorCamera}}
                </span>
              </v-col>
            </v-row>
          </v-container>
        </qrcode-stream>
        <!-- <qrcode-drop-zone></qrcode-drop-zone> -->
        <!-- <qrcode-capture></qrcode-capture> -->

        <v-card-actions class="pl-0">
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="qrCode = false" rounded>Go back</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>

      </div>

      <!-- ERROR HANDLING -->
      <span v-if="error" class="ml-3 error--text">
        {{error}}
      </span>
    </v-card>

    <v-card
      v-if="$router.currentRoute.path !== '/rabbithole' && !qrCode"
      class="mx-auto my-4 pa-3"
      max-width="344"
      outlined
    >
    <v-card-text>
      <v-card-title class="justify-center pt-0 italics primary--text">Join the what..?</v-card-title>
      <TheFacts :details="false" class="text-left word-break"/>
      <v-btn color="primary" rounded to="/about" class="mb-4">More about this</v-btn>
    </v-card-text>
    </v-card>

  </div>
</template>

<script>
// https://www.npmjs.com/package/vue-qrcode-reader
import { QrcodeStream } from 'vue-qrcode-reader'  // QrcodeDropZone, QrcodeCapture
import TheFacts from '@/components/TheFacts.vue'

  export default {
    name: 'Rabbithole',

    components: {
      QrcodeStream,
      TheFacts
      //QrcodeDropZone,
      // QrcodeCapture
    },
    
    data () {
      return {
        error: '',
        errorCamera: '',
        cameraMode: 'auto',
        accessCode: '',
        qrCode: false,
        loadingCamera: false,
      }
    },

    created() {
      /* console.log(document.URL);
      console.log(window.location.origin); */
      //console.log(this.$router);
    },


    methods: {
      enter: function (accessCode) {
        this.$router.push('/messenger?convo='+accessCode)
      },

      async onDetect (promise) {
        try {
          const {
            // imageData,    // raw image data of image/frame
            content,      // decoded String
            // location      // QR code coordinates
          } = await promise

          // console.log("onDetect:", imageData, content, location)
          let baseUrl = window.location.protocol + "//" + window.location.host + '/#'
          if(content.startsWith(baseUrl)) {
            //console.log(content.substring(baseUrl.length, content.length))
            this.$router.push(content.substring(baseUrl.length, content.length))
          } else {
            this.errorCamera = "This is not a convo access code"
          }
        } catch (error) {
          console.log("onDetect:", error);
          this.errorCamera = error;
        }
      },

      async onInit (promise) {
        // show loading indicator
        this.loadingCamera = true;
        try {
          //const { capabilities } = 
          await promise
          //console.log("onInit", capabilities);
          // successfully initialized
        } catch (error) {
          if (error.name === 'NotAllowedError') {
            console.log("user denied camera access permisson");
            this.errorCamera = "Please allow for camera access";
          } else if (error.name === 'NotFoundError') {
            console.log("No suitable camera device installed");
            this.errorCamera = "There is no suitable camera device installed";
          } else if (error.name === 'NotSupportedError') {
            console.log("Page is not served over HTTPS (or localhost)");
            this.errorCamera = "Page is not served over HTTPS (or localhost)";
          } else if (error.name === 'NotReadableError') {
            console.log("maybe camera is already in use");
            this.errorCamera = "Your camera is already in use";
          } else if (error.name === 'OverconstrainedError') {
            console.log("did you requested the front camera although there is none?");
            this.errorCamera = "Did you requested the front camera although there is none?";
          } else if (error.name === 'StreamApiNotSupportedError') {
            console.log("browser seems to be lacking features");
            this.errorCamera = "Your browser does not support this camera feature";
          }
        } finally {
          //console.log("hide loading indicator");
          this.loadingCamera = false;
        }
      },

      resetCamera() {
        this.cameraMode = 'rear';
        this.errorCamera = '',
        this.cameraMode = 'auto';
      },
    }
  }
</script>

