<template>
  <v-container fill-height fluid class="pa-0">
      <v-row
        no-gutters
        class="fill-height"
        align="center"
      >
        <v-col
        class="rabbitholeBackground"
        :class="this.$vuetify.breakpoint.smAndUp ? 'fill-height' : ''"
        cols="12"
        align="center"
        md="6">
          <v-card-title class="justify-center fill-width fill-height">
            <Rabbithole/>
          </v-card-title>
        </v-col>

        <v-col
        class="loginBackground"
        :class="this.$vuetify.breakpoint.smAndUp ? 'fill-height' : ''"
        cols="12"
        md="6">
          <v-card-title class="justify-center fill-width fill-height">
            <Login v-if="!auth"/>
            <v-card
            v-else
              class="mx-auto my-4 pa-4"
              max-width="344"
              outlined
            >
              <v-card-title class="justify-center text-h4">Go to Dashboard</v-card-title>
              <v-card-actions class="pl-0">
                <v-spacer></v-spacer>
                  <v-btn color="primary" to="/dashboard" rounded>Dashboard</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-card-title>
        </v-col>
      </v-row>
    </v-container >
</template>

<script>
import Login from '@/views/Login.vue'
import Rabbithole from '@/views/Rabbithole.vue'

export default {
  name: 'Home',
  components: {
    Login, Rabbithole
  },
  props: {
    auth: Boolean,
    user: Object,
  },
  data () {
    return {
    }
  },
  created() {
  },
  methods: {
  },
}
</script>
