<template>
  <v-main class="ma-0 pa-0 fill-width fill-height overflow-hidden">

    <!-- FULLSCREEN HACK AFTER IMAGE SELECTION -->
    <v-overlay
      v-if="addImageOverlayFullscreenHack"
      :opacity="0.75"
    >
      <div
        class="fixed top left fill-height fill-width "
        style="z-index=999"
        @click="addImageOverlayFullscreenHack = false, enterFullscreen()"
        >
      </div>
      <v-progress-circular
        indeterminate
        color="secondary"
        class="ma-a"
        @click="addImageOverlayFullscreenHack = false, enterFullscreen()"
      />
    </v-overlay>
    
    <!-- LOADER -->
    <v-overlay
      v-if="!controller"
      :value="loading"
      :opacity="0.75"
    >
      <v-progress-circular
        indeterminate
        size="85"
        width="3"
        color="secondary"
        class="ma-a"
        @click="loading = false"
      ><v-icon x-large color="primary">mdi-frequently-asked-questions</v-icon></v-progress-circular>
    </v-overlay>

    <!-- IOS WARNING -->
    <v-dialog
      v-model="iosFullscreenWarning"
      fullscreen
      persistent
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card class="pa-3">
        <v-card-title class="headline primary white--text py-2 pl-2">
          <v-icon class="mr-2 white--text">mdi-apple</v-icon>
          iOS: Fullscreen mode
        </v-card-title>
        <v-card-text class="pa-3">
          <p>
          Unfortunately, this device does not allow to display a web app directly in fullscreen mode.
          </p>
          <p>
            To hide the URL bar, add this website to the homescreen:
          </p>

          <div class="text-center" :class="replayIosVideo ? 'grey lighten-3' : ''">
              <video
                v-if="!replayIosVideo"
                :controls="false" class="maxy-350 inline-block"
                autoplay muted playsinline
                @ended="iosVideoEnded()"
                onClick="this.currentTime = 0"
              >
                <source :src="require('../assets/help/addToHomescreen.mp4')" type="video/mp4">
              </video>
            <v-btn v-else rounded @click="replayIosVideo = false" class="my-16">
              <v-icon small class="mr-2">mdi-replay</v-icon>
              Replay
            </v-btn>
          </div>

          <div v-if="auth && convos[convoIndex].settings.systemBar.display">
            Furthermore, you have to disable the system bar app in your controller settings because the
            native system bar does not get hidden even as a homescreen web app.
            <br>
          </div>
        </v-card-text>

        <v-card-actions>
            <v-btn v-if="auth && convos[convoIndex].settings.systemBar.display"
            class="primary" rounded @click="$router.push('/controller?convo='+convos[convoIndex].accessCode)">
              <v-icon class="mr-2">mdi-podcast</v-icon>
              Controller
            </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            rounded
            color="primary lighten-2"
            class="mr-2"
            @click="gotTheIosGist()"
          >
            Got it
          </v-btn>
          <v-spacer v-if="!auth" ></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- MAIN MESSENGER CONTAINER -->
    <v-card
      v-if="convos[convoIndex] && (convos.length === 1 || hasChosenFromMultipleConvos)"
      class="d-flex align-start flex-column fill-height"
      :class="[
        pov.colorBG === '#607D8B' && !pov.bgImage ? 'rabbitholeBackground' : '',
        pov.colorBG || pov.bgImage ? '' : 'rabbitholeBackground',
        controller? '' : 'no-select'
      ]"
      
      :style="{
        'backgroundColor': pov.colorBG ? pov.colorBG : '',
        'backgroundImage': pov.bgImage ? 'url('+pov.bgImage+')' : '',
        'backgroundSize': 'cover',
        'backgroundPosition': 'center center',
        }"
      flat
      tile
    > 
    <!-- CONTROLLER LABEL -->
    <v-card-title
      v-if="controller"
      class="grey darken-3 fill-width primary--text italics justify-center pt-0 pb-1"
    >
      CONTROLLER <v-icon color="primary" class="mx-3">mdi-podcast</v-icon>
      <!-- OPEN SETTINGS -->
      <div v-if="controller && !displaySettings" class="nudge-y--12">
        <v-btn rounded @click="displaySettings = true" small color="primary px-0 mr-3">
          <div v-if="$vuetify.breakpoint.mdAndUp" class="nudge-y-12 pr-3">
            <v-icon>mdi-menu-down</v-icon>
            Settings
          </div>
          <v-icon v-else>mdi-cog</v-icon>
        </v-btn>
        <v-btn v-if="$vuetify.breakpoint.smAndDown" rounded small
          color="secondary"
          :to="$vuetify.breakpoint.smAndDown
            ? '/messenger?convo='+convos[convoIndex].accessCode
              : '/dashboard'"
        ><v-icon>mdi-cellphone-nfc</v-icon>
        </v-btn>
      </div>
      <!-- CLOSE SETTINGS -->
      <div v-else class="nudge-y--12">
        <v-btn rounded @click="displaySettings = false" small color="grey px-0">
          <div v-if="$vuetify.breakpoint.mdAndUp" class="nudge-y-12 pr-3">
            <v-icon>mdi-menu-up</v-icon>
            Hide
          </div>
          <v-icon v-else>mdi-cog-off</v-icon>
        </v-btn>
      </div>
    </v-card-title>

    <!-- PREVIEW LABEL -->
    <v-card-title
      v-if="messengerPreviewFromController"
      class="grey darken-3 fill-width justify-center py-1"
    >
      <div class="secondary--text italics fill-width text-center">MESSENGER <v-icon color="secondary" class="mx-3">mdi-cellphone-nfc</v-icon> PREVIEW</div>
      <div class="caption white--text">as seen by {{pov.name}}</div>
    </v-card-title>

    <!-- :class="[pov.colorBG || pov.bgImage ? '' : 'rabbitholeBackground', controller? '' : 'no-select']" -->
      <!-- SYSTEM BAR -->
      <SystemBar
        v-if="!controller && setPov && convos[convoIndex].settings.systemBar.display"
        :settingsSystemBar="convos[convoIndex].settings.systemBar"
      />

<!-- v-if="false && !controller && setPov && convos[convoIndex].settings && convos[convoIndex].settings.systemBar.display"-->

      <!-- CHAT HEADER -->
      <v-toolbar v-if="setPov && !controller" dark class="fill-width pl-1"
        :class="!controller && !messengerPreviewFromController ? 'sticky top' : ''"
        :style="{'backgroundColor': sender.colorAccent ? sender.colorAccent : ''}"
      >
      <!--  && deviceOs.ios -->
        <v-app-bar-nav-icon>
        <!-- <v-app-bar-nav-icon @click="setPov = false, controller ? randomAvatar = Math.random(0,100) : false"> -->
          <v-avatar :color="sender.colorAccent === '#38B083' ? 'secondary' : sender.colorAccent" >
            <!--  :key="randomAvatar" -->
            <!-- src="https://thispersondoesnotexist.com/image" -->
            <v-img
              v-if="sender.avatar"
              :src="sender.avatar"
              alt="avatar"
              transition="scale-transition"
            />
            <!-- <img
              v-else
              src="https://thispersondoesnotexist.com/image"
              alt="avatar"
              transition="scale-transition"
            > -->
            <span v-else class="">{{this.$helpers.initials(sender.name, '', 2)}}</span>
          </v-avatar>
        </v-app-bar-nav-icon>
        
        <v-toolbar-title class="text-break pl-2" @click="setPov = false">
          <!-- <div>{{convos[convoIndex].characters[sender].name}}</div> -->
          <div>{{sender.name}}</div>
          <div v-if="sender.phone || sender.online" class="op-50 caption">
            <span v-if="sender.online" class="pr-2">
              <v-icon small class="green--text white--icon-background">mdi-check-circle</v-icon> Online now
            </span>
            <span v-if="sender.phone">{{sender.phone}}</span>
          </div>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon class="disabledEvents">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
        <v-app-bar-nav-icon
          @click="!messengerPreviewFromController ? openMenu = true : false"
        ></v-app-bar-nav-icon>
      </v-toolbar>

      <v-card 
        v-if="controller && displaySettings"
        color="grey darken-2 elevation-8"
        dark
        class="mb-auto pa-2 fill-width"
        :class="displaySettings ? '' : 'op-75'"
        flat
        tile
      > 
        <!-- SETTINGS -->
        <div>
          <v-card-title class="justify-center text-h4">
            <v-icon color="primary" class="mr-2">mdi-frequently-asked-questions</v-icon>
            {{convos[convoIndex].name}}
            <!-- TODO: jump to anchor tag of dashboard -->
            <!-- <router-link  to="/dashboard">
              <v-icon small v-tooltip="'Change convo'" class="ml-2 ">mdi-frequently-asked-questions</v-icon>
            </router-link> -->
          </v-card-title>

          <!-- BUTTONS! -->
          <v-btn-toggle  dense class="mr-2 mb-4">
            <v-btn
              class="px-3 grey darken-2"
              :class="toggledElements.includes('Options') ? 'secondary' : ''"
              @click="toggleElement('Options'), hideElement('SystemBarEditOptions'), hideElement('help')"
            >
            {{$vuetify.breakpoint.smAndDown ? 'Settings' : 'Settings'}}
            </v-btn>
            <v-btn
              class="px-3 grey darken-2"
              :class="toggledElements.includes('SystemBarEditOptions') ? 'secondary' : ''"
              @click="toggleElement('SystemBarEditOptions'), hideElement('Options'), hideElement('help')"
            >
              {{$vuetify.breakpoint.smAndDown ? 'Syst.Bar' : 'Edit System Bar'}}
            </v-btn>
            <v-btn
              class="px-3 grey darken-2"
              :class="toggledElements.includes('help') ? 'secondary' : ''"
              @click="toggleElement('help'), hideElement('Options'), hideElement('SystemBarEditOptions')"
            >
              Help
            </v-btn>
          </v-btn-toggle>


          <!-- EDIT SYSTEM BAR -->
          <v-card v-if="toggledElements.includes('SystemBarEditOptions')"
            light class="mb-6 pa-3 grey darken-1" flat>
            <SystemBarEdit :convoId="convos[convoIndex].id" :settings="convos[convoIndex].settings"/>
          </v-card>
            
          <!-- EDIT GENERAL SETTINGS -->
          <v-card v-if="toggledElements.includes('Options')" light class="mb-2 pa-3 grey darken-1" flat>
            <SettingsEdit :convoId="convos[convoIndex].id" :settings="convos[convoIndex].settings" :highlights="highlights"/>
          </v-card>
            
          <v-card v-if="toggledElements.includes('help')" light class="mb-2 pa-3 grey darken-1" flat>
            <p v-if="$vuetify.breakpoint.mdAndUp">
              On the left half of the screen you see the 
              <strong class="primary--text">Controller</strong> <v-icon color="primary">mdi-podcast</v-icon>.
            </p>
            <p v-else>
              Here you see the <strong class="primary--text">Controller</strong> <v-icon color="primary">mdi-podcast</v-icon>.
            </p>
            <p>
              If "work with dates" is not enabled in settings, you can send messages from
              the controller directly to the messenger as you were in a regular chat conversation.
              You can change sides though by selecting the "sender" on the bottom.
              <br>
              Click the "unsend messages" button the temporarly "unsend" your messages to start from the top.
            </p>
            <p>If you have "work with dates" enabled, there are two modes: <span class="primary--text">History</span> and <span class="error--text">Live</span>.
              In <span class="primary--text">History mode</span>, you can safe a message in history at a specific point in time.
              Historic messages do not get sent immedietly.
              <br>
              In <span class="error--text">Live mode</span>, you can send these saved messages to the Messenger,
              or send typed messages directly.
              <br>
              Toggle between those modes on the bottom right.
            </p>
            <v-divider class="pt-2 pb-2"></v-divider>
            <p v-if="$vuetify.breakpoint.mdAndUp">
              To the right there is the preview of the 
              <strong class="primary--text">Messenger</strong> <v-icon color="primary">mdi-cellphone-nfc</v-icon>.
              This is exactly like the prop device sees it. You can test your on-set-workflow here.
            </p>
            <p v-else>
              If you want to check what the prop device is displaying, head over to the
              <strong class="primary--text">Messenger</strong> <v-icon color="primary">mdi-cellphone-nfc</v-icon>:
              <br>
              You'll find the <v-icon color="secondary darken-2">mdi-cellphone-nfc</v-icon> button on the bottom of this box to switch.
              <br>
              To switch back, press the <v-icon color="secondary darken-2">mdi-menu</v-icon> menu button.
            </p>
            <v-divider class="pt-2 pb-2"></v-divider>
            <p>You can change settings of the Characters (like colors, names and pictures)
              in the <router-link to="/dashboard">dashboard</router-link>.</p>
            <p></p>
          </v-card>

          <!-- HIDE SETTINGS -->
          <v-card-actions class="pa-0 pt-2">
            <v-spacer></v-spacer>
              <v-btn rounded @click="displaySettings = false" small color="grey">
                <v-icon>mdi-menu-up</v-icon>
                Hide settings
              </v-btn>
              <v-btn v-if="$vuetify.breakpoint.smAndDown" rounded small
                color="secondary"
                :to="$vuetify.breakpoint.smAndDown
                  ? '/messenger?convo='+convos[convoIndex].accessCode
                    : '/dashboard'"
              >
                <v-icon>mdi-cellphone-nfc</v-icon>
              </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </div>
      </v-card>


      <!-- CHAT WINDOW -->
      <v-container 
        v-if="!setPov"
        fill-height fluid class="pa-0"
      >
        <v-row
          no-gutters
          class="fill-height"
          align="center"
          justify="center"
        >
          <v-col :cols="$vuetify.breakpoint.xsOnly ? 11 : $vuetify.breakpoint.smOnly ? 8 : 4" align="center" class="minx-300">
            <v-card-title class="justify-center text-center text-break white--text text-h5 pt-8 pb-0">
              This device is in the hands of…
            </v-card-title>
              <v-list nav color="transparent" dense class="pt-0">
                <v-list-item-group>
                  <v-radio-group v-model="povUid">
                    <v-list-item
                      v-for="(character, i) in convos[convoIndex].characters"
                      :key="i"
                      :readonly="senderUid === character.uid"
                      :class="senderUid === character.uid ? 'op-50' : ''"
                      @click="senderUid === character.uid ? senderUid = '' : false"
                    >
                      <v-list-item-content>
                        <v-radio
                          :value="character.uid"
                          :label="character.phone ? character.name + ' - ' +character.phone : character.name"
                        ></v-radio>
                      </v-list-item-content>
                    </v-list-item>
                    </v-radio-group>
                </v-list-item-group>
              </v-list>
            <br>
            <v-card-title class="justify-center text-center text-break white--text text-h5 py-0">
              
              {{povUid ? $helpers.findKey(this.convos[this.convoIndex].characters, 'uid', povUid, 'name').split(" ")[0]+"s" : 'And the'}}
              counterpart is…
            </v-card-title>
            <v-list nav color="transparent" dense class="pt-0">
                <v-list-item-group>
                  <v-radio-group v-model="senderUid">
                    <v-list-item
                      v-for="(character, i) in convos[convoIndex].characters"
                      :key="i"
                      :readonly="povUid === character.uid"
                      :class="povUid === character.uid ? 'op-50' : ''"
                      @click="povUid === character.uid ? povUid = '' : false"
                    >
                      <v-list-item-content>
                        <v-radio
                          :value="character.uid"
                          :label="character.phone ? character.name + ' - ' +character.phone : character.name"
                        ></v-radio>
                      </v-list-item-content>
                    </v-list-item>
                    </v-radio-group>
                </v-list-item-group>
              </v-list>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  rounded
                  :disabled="!pov.uid || !sender.uid"
                  @click="setNewPov(pov.uid)"
                  color="primary"
                  class="px-12 py-6 mb-3"
                >Go</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
              This is only temporary. To change the defaults, go to the dashboard and change the settings of the Characters.
              <!-- , !controller && !messengerPreviewFromController ? enterFullscreen() : null -->
          </v-col>
        </v-row>
      </v-container>

      <!-- MESSAGE HINT -->
      <!-- !shownMessengerHint && !controller && !messengerPreviewFromController -->
      <v-card v-else-if="!shownMessengerHint && !controller && !messengerPreviewFromController && !iosFullscreenWarning"
        class="align-self-stretch pa-0 fill-width fill-height"
        style="background-color: rgba(0,0,0,0)"
        tile
      >
        <div class="absolute fill-width fill-height overflow-y-auto">
          <!-- v-if="!iosFullscreenWarning" -->
          <MessengerHint
            @enter-fullscreen="!controller && !messengerPreviewFromController ? enterFullscreen() : false"
            @notedTheHint="shownMessengerHint = true"
          />
        </div>
      </v-card>



      <!-- MESSAGES -->
      <v-card
        v-else-if="!iosFullscreenWarning"
        class="align-self-stretch pa-0 fill-width fill-height"
        style="background-color: rgba(0,0,0,0)"
        tile
      >
        <div class="absolute fill-width fill-height overflow-y-auto">
          <!-- id="scrollTargetMessages"  -->
        <!-- convos[convoIndex].name {{convos[convoIndex].name}}<br>
        convoIndex on chat window? {{convoIndex}}<br>

        <p v-for="(index, i) in 32" :key="i">adsf asf asfd {{i}}</p> -->
        <!-- v-if="false" -->
        <!--  && convos[convoIndex] && pov && sender -->
          <Messages
            :controller="controller"
            :convo="convos[convoIndex]"
            :pov="pov"
            :sender="sender"
            :forceTypeTrigger="forceTypeTrigger"
            :triggerClearMessenger="triggerClearMessenger"
            :triggerScrolling="triggerScrolling"
            :emoticons="emoticonsAscii"
            :messengerPreviewFromController="messengerPreviewFromController"
            @firstUntypedMessage="forcedMessage = $event"
            @clearedMessenger="loadingClearMessenger=false"
          />
          <!-- @firstUntypedMessage="disableForceTypeTemporarily($event),  -->
          <!-- :characters="convos[convoIndex].characters" -->
        </div>
      </v-card>
      <div v-else class="white error--text fill-width pa-4">
        Error: Nothing to display.
      </div>

      <!-- FOOTER -->
      <!-- OPTIONS FOOTER -->
        <!-- v-if="controller && setPov" -->
      <v-card 
        v-if="controller && setPov"
        color="grey darken-2"
        dark
        class="pa-2 fill-width"
        flat
        tile
      >

        <!-- VAR BUTTONS -->
        <v-card-actions class="pa-0">
          <!-- change POV -->
          <v-btn small class="mr-2" @click="setPov = false">
            {{$vuetify.breakpoint.smAndDown ? 'POV' : 'set POV'}}
          </v-btn>

          <!-- unsend messages -->
          <v-btn small :loading="loadingClearMessenger" class="ml-0" @click="triggerClearMessenger = Math.random().toString(), loadingClearMessenger=true">
            {{$vuetify.breakpoint.smAndDown ? 'Unsend' : 'Unsend messages'}}
          </v-btn>

          <v-spacer></v-spacer>

          <!-- Mode toggle -->
          <v-btn-toggle v-if="convos[convoIndex].settings.showDates" class="ma-0 ml-2 nudge-y-06">
            <v-btn small class="grey" @click="mode = 'history'" :class="mode === 'history' ? 'primary' : ''">
              {{$vuetify.breakpoint.smAndDown ? 'HISTORY' : 'HISTORY MODE'}}
            </v-btn>
            <v-btn small class="grey" @click="mode = 'messenger'" :class="mode === 'messenger' ? 'error' : ''">
              {{$vuetify.breakpoint.smAndDown ? 'LIVE' : 'LIVE MODE'}}
            </v-btn>
          </v-btn-toggle>
        </v-card-actions>

      </v-card>

      <!-- ERRORS -->
      <v-card 
        v-if="errorImageUpload || error"
        color="grey darken-3"
        dark
        class="error--text text-center pa-2 fill-width"
        flat
        tile
      >
        {{errorImageUpload}}{{error}}
      </v-card>

      <!-- IMAGE PREVIEW -->
      <v-card 
        v-if="message.image"
        color="grey darken-3"
        dark
        class="pa-2 pb-0 pt-2 fill-width"
        flat
        tile
      >
        <img
          class="grey inline-block rounded maxy-150 maxx-300"
          :src="message.image"
        />
          <v-btn small icon class="grey nudge-y--75 darken-1 ml-2 white--text" @click="message.image = ''">
            <v-icon small>mdi-trash-can</v-icon>
          </v-btn>
      </v-card>
      

      <!-- CHAT FOOTER -->
      <v-card v-if="setPov" dark class="px-2 fill-width elevation-7   "
      flat tile
        :class="[
          controller && mode === 'messenger' ? 'error' : pov.colorBG ? '' : 'secondary',
          !controller && !messengerPreviewFromController ? 'sticky bottom' : ''
        ]"
        :style="{
          'backgroundColor': pov.colorAccent ? pov.colorAccent : '',
          }"
      >
      <!-- <pre>{{message.character}}</pre> -->
      <!-- <pre>
lastDate:      {{$moment(lastDate).format('YYYY-MM-DD / HH:mm:ss')}}
referenceDate: {{$moment.unix(convos[convoIndex].settings.referenceDate.seconds).format('YYYY-MM-DD / HH:mm:ss')}}
sentDate:      {{message.sentDate ? $moment.unix(message.sentDate.seconds).format('YYYY-MM-DD / HH:mm:ss') : 'XX'}}</pre> -->
      <!--  !controller && !messengerPreviewFromController && deviceOs.ios -->

        <!-- TYPE INDICATOR -->
        <!-- TODO: Overlaps image sending preview -->
        <div v-if="!this.controller && convos[convoIndex].settings.typing && convos[convoIndex].settings.showTypeIndicator"
          class="absolute top nudge-y--175"
        >
          <div class="italics body-2 op-75 pl-4 black--text">
            {{this.$helpers.firstName(sender.name)}} is typing <div class="dot-typing inline-block op-50 ml-5 mb-0"></div>
          </div>
        </div>


        <v-card-title
          v-if="mode === 'history'"
          class="justify-center body-1 mt-1 px-2 pt-4 pb-0 rounded relative"
          style="background-color: rgba(0,0,0,0.15)"
        >
          <div class="caption ml-3 absolute top left">
            This message will be sent:
            <span v-if="convos[convoIndex].settings.randomTimeIntervals" class="ml-3 pointer"
              @click="displaySettings = true, highlights.push('autoIncrement')"
            >
              <v-icon small class="green--text">mdi-check-circle</v-icon> Auto-increment
            </span>
          </div>
          <DateEdit
            label=""
            btnColor="grey darken-1"
            btnTextColor="white--text"
            class="pt-2 pb-1"
            :lastDate="lastDate"
            @newDate="dateIsSet($event)"
            :refDay="$moment.unix(convos[convoIndex].settings.referenceDate.seconds)"
            :triggerTimeUpdate="triggerTimeUpdate"
          />
        </v-card-title>

        <!-- CHANGE SENDER OF CURRENT MESSAGE -->
        <!--  @change="editMessage('character', message.character) -->
        <v-radio-group v-if="controller" label="Sender:" hide-details row class="ml-1 mt-1" v-model="message.character">
          <v-radio
            mandatory
            v-for="character in convos[convoIndex].characters"
            :key="character.uid"
            :label="convos[convoIndex].characters.length > 2 ? $helpers.firstNameInitial(character.name) : character.name"
            :value="character.uid"
          ></v-radio>
        </v-radio-group>

        <!-- FORCE TYPE CHAT BOX -->
        <v-form @submit.prevent="submitForceType()"
          v-if="!controller && convos[convoIndex].settings.forceType && !disableForceType"
          class="op-0 forceType red absolute bottom left fill-width ovfl--hidden nobr"
        >
          <input
            ref="forceChatBox"
            class="forceChatBox fill-height absolute inline-block bottom" 
            type="text"
            autocomplete="off"
            spellcheck="false"
            autocorrect="off"
            v-model="forcedTypedText"
            @input="forceType($event)"
            @focus="getForcedMessage(), triggerDelayScrolling(500)"
          >
          <input
            v-if="forcedTypedText.length"
            value="X"
            class="clear fill-height absolute"
            type="button"
            @click="forcedTypedText = '', forcedMessage = {}, message.message = '', clearMessage()"
          >
          <input
            value="OK"
            class="submit fill-height absolute"
            type="button"
            @click="submitForceType()"
          >
        </v-form>

        <ImageUpload
          class="op-0 fileUpload absolute fill-height inline-block"
          btnClasses="fill-height pa-8 warning"
          :tile="true"
          :imagesOnly="true"
          :maxFileSizeMb="8"
          :imageSize="300"
          @base64="addImageToMessage($event)"
          @error="errorImageUpload = $event.message"
        ></ImageUpload>

        <!-- lastDate: {{lastDate}}<br>
        message.sentDate: {{message.sentDate}}<br> -->
        <!-- CHAT BOX -->
        <v-form @submit.prevent="sendTextMessage(message)">
          <v-container class="pa-0">
            <v-row>
              <v-col cols="12" class="ma-0">
                <v-card-actions class="pa-0">
                  <!-- EMOTICONS -->
                  <v-popover offset="4">
                    <v-icon class="mr-2 pointer">mdi-emoticon</v-icon>
                    <template slot="popover">
                      <div class="tooltip-content no-select" style="padding:5px; font-size:1.5em; text-align: center">
                        <div tabIndex="-1"
                          @click="insertEmoticon(i)"
                          v-close-popover
                          v-for="(emo, i) in emoticonsAscii" :key="i"
                          style="display:inline-block; cursor:pointer; padding:0 0.25em;"
                        >{{emo}}</div>
                      </div>
                    </template>
                  </v-popover>

                  <!-- CHAT BAR -->
                  <v-textarea
                    auto-grow
                    rows="1"
                    class="maxy-300 scroll-y"
                    dense
                    ref="chatBox"
                    v-model="message.message"
                    append-icon="mdi-paperclip"
                    :append-outer-icon="mode === 'history' && controller ? 'mdi-content-save' : message.message || message.image ? 'mdi-send' : 'mdi-microphone'"
                    filled
                    hide-details
                    clear-icon="mdi-close-circle"
                    clearable
                    placeholder="Message"
                    rounded
                    type="text"
                    @input="controller && mode === 'messenger' && convos[convoIndex].settings.showTypeIndicator ? showLiveTypeIndicator() : false"
                    @focus="triggerDelayScrolling(500)"
                    @keydown.enter.exact.prevent
                    @keyup.enter.exact="sendTextMessage(message)"
                    @click:append-outer="sendTextMessage(message)"
                    @click:clear="clearMessage()"
                  ></v-textarea>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card>

    </v-card>


    <!-- WRONG ACCESS CODE -->
    <v-card-title
      v-else-if="!convos.length && !loading"
      class="pa-5 fill-width rabbitholeBackground fill-height justify-center"
    >
    <div>
      <v-img
        height="40"
        class="my-6 op-25 block"
        contain
        :src="require('../assets/logoBlack.svg')"
      />
      <v-alert dark class="warning"
        type="warning"
        icon="mdi-head-question"
      >
        There is no matching convo to this access Code.
        <br>
        Please <router-link :to="auth ? '/dashboard' : '/'" class="error--text">go back and try again</router-link>.
      </v-alert>
      </div>
    </v-card-title>

    <!-- MULTIPLE CONVOS WITH SAME ACCESS CODE -->
    <v-card-title
      v-else-if="
      (convos[convoIndex] && convos.length > 1 && !hasChosenFromMultipleConvos)
      ||
      (convos[convoIndex] && convos.length > 1 && !hasChosenFromMultipleConvos && !auth)"
      class="pa-5 fill-width rabbitholeBackground fill-height justify-center"
    >
      <v-alert  dark class="warning"
        type="warning"
        icon="mdi-alert"
      >
        <p v-if="!auth">
          There are multiple convos with this access code.
          <br>
          You must <router-link to="/login" class="error--text">login</router-link>
          and change your Access code.
        </p>

        <!-- If convos with the same access codes are not from you  -->
        <div v-else-if="auth && convos.filter(convo => { return convo.createdBy === user.uid }).length < 2">
          <p>
            There are multiple convos with this access code.
            <br>
            <router-link to="/dashboard" class="error--text">
              You have to change yours.
            </router-link>
          </p>
        </div>

        <!-- if you have more than 1 convo with the same access code -->
        <div v-else>
          <p>
            You have multiple convos with the same access code.
          </p>
          <p class="error--text">
            You can acces it here for now, but a user without login cannot access this.
            <router-link to="/dashboard" class="error--text">
              Make sure every convo has a unique access code.
            </router-link>
          </p>
          <v-list nav dense color="transparent">
            <v-list-item-group>
              <v-list-item
                v-for="(currentConvo, i) in convos.filter(convo => { return convo.createdBy === user.uid })"
                :key="i"
                @click="convoIndex = i, hasChosenFromMultipleConvos = true"
              >
              <!-- @click="convoS = currentConvo" -->
                <v-list-item-title>
                      <v-radio
                        :label="`${currentConvo.name} - (Created: ${$helpers.fbTimeToRelativeToNow(currentConvo.createdOn)})`"
                      ></v-radio>
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </v-alert>
    </v-card-title>


    <!-- MENU -->
    <v-dialog
      v-model="openMenu"
      width="500"
    >
      <v-card class="pa-3">
        <v-card-text class="px-0 py-3 text-center">
          <!-- <v-btn class="mb-4" rounded @click="openMenu = false, enterFullscreen()">
            enter fullscreen 
          </v-btn>
          <v-btn class="mb-4" rounded @click="openMenu = false, exitFullscreen()">
            exit fullscreen 
          </v-btn> -->

          <p v-if="!deviceOs.ios" class="mb-4">
            <v-btn text @click="openMenu = false, toggleFullscreen()">
              Toggle fullscreen
            </v-btn>
          </p>
          <p v-else class="mb-4">
            <v-btn text @click="openMenu = false, iosFullscreenWarning = true">
              Show fullscreen instructions
            </v-btn>
          </p>
          <p class="mb-4">
            <v-btn text @click="openMenu = false, setPov = false">
              Set POV 
            </v-btn>
          </p>
          <p class="mb-4">
            <v-btn text @click="$router.push('/rabbithole')">
              Go to another convo
            </v-btn>
          </p>
          <p v-if="auth" class="mb-4">
            <v-btn text @click="$router.push('/dashboard')">
              Go to dashboard
            </v-btn>
          </p>
          <p v-if="auth">
            <v-btn rounded class="primary" @click="$router.push('/controller?convo='+convos[convoIndex].accessCode)">
              <v-icon class="mr-2">mdi-podcast</v-icon>
              Switch to controller
            </v-btn>
          </p>
          <p v-else class="mb-4">
            <v-btn rounded class="primary" @click="$router.push('/login')">
              Login
            </v-btn>
          </p>
          
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="pb-0">
          <v-spacer></v-spacer>
          <v-btn
            small
            rounded
            color="grey lighten-2"
            @click="openMenu = false"
          >
            Close
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <v-card
      class="d-flex align-start flex-column fill-height"
      flat
      tile
    >
      <v-card
        class="pa-2 fill-width secondary"
        tile
      >
        chat header
      </v-card>
      <v-card
        v-if="controller" 
        dark
        class="mb-auto pa-2 fill-width grey darken-2"
        tile
      >
        options header<br>...
      </v-card>

      <v-card
        class="align-self-stretch pa-0 fill-width fill-height"
        tile
      >
        <div class="absolute fill-width fill-height overflow-y-auto">
          start<br>
          <p v-for="p in 40" :key="p">article paragraph {{ p }}....</p>
          end<br>
        </div>
      </v-card>

      <v-card
        v-if="controller" 
        dark
        class="pa-2 fill-width grey darken-2"
        tile
      >
        options footer<br>...<br>...
      </v-card>
      <v-card
        class="pa-2 fill-width primary"
        tile
      >
        chat footer
      </v-card>
    </v-card> -->

  </v-main>
</template>

<script>
import { db } from '../firebase'
import SystemBar from '@/components/SystemBar.vue'
import SystemBarEdit from '@/components/SystemBarEdit.vue'
import SettingsEdit from '@/components/SettingsEdit.vue'
import Messages from '@/components/Messages.vue'
import MessengerHint from '@/components/MessengerHint.vue'
import ImageUpload from '@/components/ImageUpload'
import DateEdit from '@/components/DateEdit'
import screenfull from "screenfull";  // https://www.npmjs.com/package/screenfull

  export default {
    name: 'Messenger',
    props: {
      auth: Boolean,
      user: Object,
      controller: Boolean,
      convoAccessCodeFromController: String,
      messengerPreviewFromController: Boolean,
    },
    components: {
      SystemBar, SystemBarEdit, SettingsEdit, Messages, MessengerHint, ImageUpload, DateEdit
    },
    
    data () {
      return {
        loading: true,
        setPov: false,
        displaySettings: false,
        openMenu: false,
        iosFullscreenWarning: false,
        replayIosVideo: false,
        addImageOverlayFullscreenHack: false,
        shownMessengerHint: false,
        deviceOs: '',
        //mode: this.controller ? 'history' : 'messenger',
        mode: 'messenger',
        povUid: '',
        //pov: {'uid': ''},
        senderUid: '',
        convoIndex: 0,
        //lastDate: '',   ///// get referenceDate from settings
        triggerTimeUpdate: {},
        triggerClearMessenger: '',
        loadingClearMessenger: false,
        triggerScrolling: '',
        toggledElements: ['Options'],
        highlights: [],
        error: '',
        message: {
          'disabled': false,
          'image': '',
          'lockHistory': false,
          'message': '',
          'seen': false,
          'sent': false,
          'sentDate': null,
          'character': '',
        },
        errorImageUpload: '',
        forceTypeTrigger: '',
        forcedMessage: {},
        forcedTypedText: '',
        typingInProgress: false,
        disableForceType: false,
        hasChosenFromMultipleConvos: false,
        convos: [],
        randomAvatar: 1,
        emoticonsAscii: ['😂','😊','🤣','❤️','🥰','😍','😭','🥺','🔥','🤓','😡','💔', '🍹', '🤖', '🔪', '👌', '👍', '✊'],
      }
    },
    created() {
      // get cookie shownMessengerHint
      this.shownMessengerHint = this.$helpers.readCookie('shownMessengerHint') === 'true';

      let convoAccessCode;
      
      if(this.convoAccessCodeFromController) {
        convoAccessCode = this.convoAccessCodeFromController;
      } else {
        convoAccessCode = this.$route.query.convo;
      }

      // get all documents from live collection
      db.collection('convos').where('accessCode', '==', convoAccessCode)
        .orderBy("createdOn", "desc")
        .onSnapshot(querySnapshot => {
          if (querySnapshot.empty) {
            // Snapshot did not return a thing
            this.loading = false;
            return
          }
          let newData = [];
          querySnapshot.forEach(doc => {
            let f = doc.data();
            f.id = doc.id;
            newData.push(f);
          });
          this.convos = newData;

          // Set default senderUid & povUid acccording to when convo was created
          this.senderUid = this.$helpers.findKey(this.convos[this.convoIndex].characters, 'role', 'sender', 'uid');
          this.povUid = this.$helpers.findKey(this.convos[this.convoIndex].characters, 'role', 'pov', 'uid');

          // Set initial message to POV
          if(!this.message.character) {
            this.message.character = this.povUid;
            // reverse message character if controller
            if(this.controller) this.message.character = this.senderUid;
          }
          this.setPov = true;
          this.loading = false;
        }, error => {
          // permission error in firebase
          console.log("Snapshot error:", error);
          this.loading = false;
        });
    },

    mounted() {
      this.deviceOs = this.$device;
      // get cookie shownIosHint
      let alreadyShownIosHint = this.$helpers.readCookie('shownIosHint') === 'true';

      if(!alreadyShownIosHint && this.deviceOs.ios && !this.controller && !this.messengerPreviewFromController) {
        this.iosFullscreenWarning = true;
      }
    },

    watch: {
    },

    computed: {
      icon () {
        return this.icons[this.iconIndex]
      },
      sender () {
        return this.$helpers.findKey(this.convos[this.convoIndex].characters, 'uid', this.senderUid, "object")
      },
      pov () {
        return this.$helpers.findKey(this.convos[this.convoIndex].characters, 'uid', this.povUid, "object")
      },
      lastDate() {
        return this.message.sentDate
                ? this.$moment.unix(this.message.sentDate.seconds)
                : this.$moment.unix(this.convos[this.convoIndex].settings.referenceDate.seconds)
      },
    },

    methods: {
      toggleFullscreen() {
        if (screenfull.isEnabled) {
          screenfull.toggle();
        }
      },
      enterFullscreen() {
        if (screenfull.isEnabled) {
          screenfull.request();
        }
      },
      exitFullscreen() {
        if (screenfull.isEnabled) {
          screenfull.exit();
        }
      },

      dateIsSet(date) {
        this.message.sentDate = date.fbTimestamp;
      },

      setNewPov(povUid) {
        this.setPov = true
        this.message.character = povUid
      },

      toggleElement: function(element) {
      if(this.toggledElements.includes(element)) {
          this.$helpers.removeFromArray(this.toggledElements, element);
        } else {
          this.toggledElements.push(element);
        }
      },

      hideElement: function(element) {
        this.$helpers.removeFromArray(this.toggledElements, element);
      },
      
      addImageToMessage(base64String) {
        this.message.image = base64String;
        this.errorImageUpload=''
        if(!this.deviceOs.ios) this.addImageOverlayFullscreenHack = true;
      },

      getForcedMessage() {
        console.log("get new message to force")
        //if(this.forcedTypedText.length === 1) {
          this.forceTypeTrigger = Math.random().toString();
          // console.log(this.forceTypeTrigger);
        //}
      },

      forceType(input) {
        //console.log(this.forcedMessage)
        if(Object.keys(this.forcedMessage).length === 0) {
          this.getForcedMessage()
        }
        //console.log(this.forcedMessage)
        //console.log(this.forcedMessage)
        if(input.data) { // A button was hit
          if(this.forcedMessage.message) {
            this.message.message = this.forcedMessage.message.substring(0, this.forcedTypedText.length)
          //} else {
          //  this.message.message += input.data;
          }
        } else {  // backspace pressed
          this.message.message = this.message.message.substring(0, this.message.message.length-1);
        }
      },

      submitForceType() {
        if(this.forcedMessage.id) {
          // Submit forced type event
          console.log("submit forced message");
          this.$store.dispatch('mergeData', {
            'collection': 'convos/'+this.convos[this.convoIndex].id+'/messenger/',
            'document': this.forcedMessage.id,
            'post': {'sent': true}
          }).then(() => {
            // reset for next force typing stuff
            this.getForcedMessage();
          })
          this.forcedTypedText='';
          this.forcedMessage={};
          this.clearMessage();
          this.getForcedMessage();
        } else {
          console.log("No more forced messages for you.")
          this.forcedTypedText = '';
        }
      },

      disableForceTypeTemporarily() {  //message
        // If there is no next message, disalbe force type field
        /* if ('message' in message) {
          console.log("yes message");
          this.disableForceType = false;
          this.$refs.forceChatBox.focus();
        } else {
          console.log("disableForceTypeTemporarily?", !message.message);
          this.disableForceType = true;
          this.$refs.chatBox.focus();
          // set focus to regular inpt field
        } */
      },

      async sendTextMessage(message) {
        if(message.message || message.image) {
          // if controller in history mode, take date set by date picker
          if(this.mode === 'history') {
            message.sentDate = this.message.sentDate;
          } else {
            message.sentDate = this.convos[this.convoIndex].settings.referenceDate;
          }

          // If randomTimeIntervals is set, update random amount of minutes
          if(this.convos[this.convoIndex].settings.randomTimeIntervals) {
            message.sentDate.seconds += this.$helpers.randomBetween(60,240);
          } else {
            // Add a seconds to reference time anyways for character or controller in live mode: 
            message.sentDate.seconds += 60;
          }

          // Make FB conform date out of this
          message.sentDate = this.$helpers.restoreFbTimestampFromJsDate(message.sentDate)

          // Do not update referenceDate if message was sent in the past
          if(this.mode !== 'history') {
            //console.log("update referenceDate")
            // Update referenceDate ("now" time)
            this.$store.dispatch('mergeData', {
              'collection': 'convos/',
                'document': this.convos[this.convoIndex].id,
                'post': {'settings': {'referenceDate': message.sentDate}}
            })
          }

          // trigger time update to display correctly in picker
          this.triggerTimeUpdate = this.$moment.unix(message.sentDate.seconds)

          /* console.log(message.sentDate); */
          if(!message.character) message.character = this.pov.uid;

          // If autoSeeMessages, set always to 'seen' 
          if(this.convos[this.convoIndex].settings.autoSeeMessages) message.seen = true;

          // Do always send immedietly if controller in messenger or character
          message.sent = this.mode === 'messenger';
          
          /* HIDE TYPE INDICATOR for when live typed message is sent */
          this.setTypeIndicator(false);

          this.$store.dispatch('addData', {
            'collection': 'convos/'+this.convos[this.convoIndex].id+'/messenger',
            'document': '',
            'post': message}
          ).then(() => {
            // Re-enable force type field, when forced typing lead to regular typing due t no further messages.
            /* if(this.convos[this.convoIndex].settings.forceType && this.disableForceType) {
              this.getForcedMessage();
            } */
          });
        this.clearMessage()
        }

        // Refocus chat box
        this.$refs.chatBox.focus();
      },

      setTypeIndicator(value) {
        this.$store.dispatch('mergeData', {
            'collection': 'convos/',
            'document': this.convos[this.convoIndex].id,
            'post': {'settings': {'typing': value}}
        })
      },

      showLiveTypeIndicator() {
        // Display "X is typing" when controller is live sending
        if(!this.typingInProgress) {
          this.setTypeIndicator(true);
        } else {
          clearTimeout(this.typingInProgress)
        }
        let that = this;
        this.typingInProgress = setTimeout(function() {
          that.setTypeIndicator(false);
          that.typingInProgress = false;
        }, 2000);
      },

      async triggerDelayScrolling (sleep) {
        if (!this.controller) {
          await this.$helpers.sleep(sleep)
          this.triggerScrolling = Math.random().toString()
        }
      },

      clearMessage () {
        this.message.message = ''
        this.message.image = ''
        this.errorImageUpload = '';
      },

      iosVideoEnded() {
        this.replayIosVideo = true;
      },

      gotTheIosGist() {
        this.iosFullscreenWarning = false
        // set Cookie
        this.$helpers.createCookie('shownIosHint', true, 4)
      },

      insertEmoticon(index) {
        if(this.message.message && this.message.message.length) {
          this.message.message += ' '+this.emoticonsAscii[index];
        } else {
          this.message.message = this.emoticonsAscii[index];
        }
      },
    }
  }
</script>

<style scoped>

.forceType {
  height: 64px;
  z-index: 999;
}
.forceType .forceChatBox {
  width: calc(100% - 120px);
  background-color: white;
}
.fileUpload {
  right: 50px;
  width: 40px;
  overflow: hidden;
  z-index: 1000;
}
.forceType .clear {
  right: 90px;
  width: 30px;
  background-color: yellow;
}
.forceType .submit {
  right: 0;
  width: 50px;
  background-color: grey;
}
</style>