<template>
  <v-dialog
      v-model="welcomeDialog"
      max-width="600"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card max-width="800" class="pt-4">
        
          <v-card-title class="fill-width justify-center mb-4">
            <img :src="require('../assets/logoBlack.svg')" alt="" class="y-50 mr-4 mb-4"/>
            Thanks for joining, {{user.name}}!
          </v-card-title>
          <v-card-text>
            <TheFacts :details="true"/>
            <div v-tooltip="'More about this license'" class="inline-block hover">
              <router-link to="/license">
                <img :src="require('../assets/cc_logo.svg')" alt="" class="y-25 sub mr-3"/>
                <img :src="require('../assets/cc_icon.png')" alt="" class="y-25 mr-3 sub op-50"/>
                <img :src="require('../assets/cc_zero.png')" alt="" class="y-25 mr-3 sub op-50"/>
              </router-link>
            </div>
          </v-card-text>
          <p class="text-center mb-3 mt-6">
            <v-btn to="/about">Read more about what this is</v-btn>
          </p>
          <p class="text-center pb-1 mb-0">
            <v-btn
                class="mb-3 px-4"
                rounded color="primary"
                @click="welcomeDialog = false"
              >
                Get started
              </v-btn>
            </p>
      </v-card>
    </v-dialog>
</template>

<script>
import TheFacts from '@/components/TheFacts.vue'

export default {
  name: 'WelcomeDialog',
  props: {
    user: Object,
  },
  components: {
    TheFacts
  },

  data () {
    return {
      welcomeDialog: true
    }
  },
  watch: {
  },
  created() {
  },

  methods: {
  }
}
</script>

<style scoped>
</style>