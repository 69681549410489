<template>
  <div>
    <!-- OVERLAY -->
    <div
      class="nudge-y--100 absolute top left fill-width">
        <v-toolbar dark dense color="transparent" class="fill-width pl-1 elevation-0">
          <v-app-bar-nav-icon class="op-0">
          </v-app-bar-nav-icon>
          <v-toolbar-title class="text-break pa-0 pl-2">
            <v-popover offset="0" trigger="manual" :open="true" :auto-hide="false">
              <template slot="popover">
                <div class="tooltip-content no-select" style="padding:5px; max-width:150px;">
                  Change temporary who is holding the device or who's your counterpart
                </div>
              </template>
            </v-popover>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-popover offset="40" trigger="manual" :open="true" :auto-hide="false">
            <!-- <div class="white absolute rounded" style="height:10px; width:3px; transform: translate(1.4em, 1.3em);"></div>
            <div class="white absolute rounded" style="height:23px; width:3px; transform: translate(1.4em, 4.5em);"></div> -->
            <v-btn disabled icon></v-btn>
              <template slot="popover">
                <div style="position:absolute; right:3.79em; margin-top:-72px; background-color:white; border-radius:1px; height:10px; width:3px; transform: translate(0,0);"></div>
                <div style="position:absolute; right:3.79em; margin-top:-22px; background-color:white; border-radius:1px; height:24px; width:3px; transform: translate(0,0);"></div>
                <div class="tooltip-content no-select" style="padding:5px; max-width:125px">
                  Does nothing. Looks great.
                </div>
              </template>
            </v-popover>
          <v-app-bar-nav-icon
          >
            <v-popover offset="0" trigger="manual" :open="true" :auto-hide="false">
              <template slot="popover">
                <div class="tooltip-content no-select" style="padding:5px;">
                  Fullscreen & options
                </div>
              </template>
            </v-popover>
          </v-app-bar-nav-icon>
        </v-toolbar>
    </div>

    <!-- HOW TO OVERLAY -->
    <!-- fixed
      class="nudge-y-500" -->
    <v-overlay
      absolute
      :z-index="1"
      :opacity="0.75"
    >
      <v-card color="transparent elevation-0">
        <v-card-title class="justify-center text-h5 italics pb-0">Beware:</v-card-title>
        <v-card-title class="justify-center text-h4 pt-1 pb-8">Fake buttons.</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            rounded
            color="primary"
            class="px-12 py-6"
            @click="messengerHintNoted(), $emit('enter-fullscreen')"
          >
            Enter in fullscreen mode
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-overlay>

  </div>
</template>

<script>

export default {
  name: 'MessengerHint',
  props: {
  },

  data () {
    return {
    }
  },
  watch: {
  },
  created() {
  },

  methods: {
    messengerHintNoted() {
      // set cookie 4d shownMessengerHint
      this.$helpers.createCookie('shownMessengerHint', true, 4)
      this.shownMessengerHint = true;
      this.$emit('notedTheHint');
    },
  }
}
</script>

<style scoped>
</style>