import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
// import 'firebase/fbFunctions'

// firebase init - add your own config here
const firebaseConfig = {
  apiKey: "AIzaSyCyNK8jiJEoSPWxR_tJFTT7kE-MvSuKFEs",
  authDomain: "convo-44752.firebaseapp.com",
  databaseURL: "https://convo-44752.firebaseio.com",
  projectId: "convo-44752",
  storageBucket: "convo-44752.appspot.com",
  messagingSenderId: "88702512291",
  appId: "1:88702512291:web:df578d1c41489812caa316",
  measurementId: "G-HT6YV32YV3"
}
firebase.initializeApp(firebaseConfig)

// utils
const fb = firebase
const db = firebase.firestore()
const auth = firebase.auth()

// collection references
const usersCollection = db.collection('users')
const postsCollection = db.collection('posts')
const commentsCollection = db.collection('comments')
const likesCollection = db.collection('likes')

// export utils/refs
export {
  fb,
  db,
  auth,
  usersCollection,
  postsCollection,
  commentsCollection,
  likesCollection
}