<template>
  <div>
    <label v-if="label" class="mr-2">{{label}}</label>
    <v-menu
      v-model="dateModal"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="slide-y-reverse-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          class="inline-block mr-2 px-2 py-1 rounded elevation-2"
          :class="btnColor || btnTextColor ? btnTextColor+' '+btnColor : 'grey black--text darken-1'"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon v-if="$vuetify.breakpoint.mdAndUp" :class="btnTextColor ? btnTextColor : 'black--text'">mdi-calendar</v-icon>
          {{$vuetify.breakpoint.mdAndUp ? dateOfSent : dateOfSent.substr(5, 5)}}
        </div>
      </template>
      <v-date-picker
        v-model="dateOfSent"
        no-title
        @input="dateModal = false, timeModal = true"
        first-day-of-week="1"
        show-current
        scrollable
      >
      </v-date-picker>
      <!-- day-format -->
    </v-menu>
    <!-- ADD DATE -->
    <div v-if="!hideMinutesManipulation" class="inline-block mr-2">
      <v-btn x-small :class="btnTextColor ? btnTextColor : 'black--text'" v-tooltip="'Minus one day'" icon @click="changeDay(-1)"><v-icon>mdi-calendar-minus</v-icon></v-btn>
      <v-btn x-small :class="btnTextColor ? btnTextColor : 'black--text'" v-tooltip="'Plus one day'" icon @click="changeDay(1)"><v-icon>mdi-calendar-plus</v-icon></v-btn>
    </div>

    <v-menu
      ref="menu"
      v-model="timeModal"
      :close-on-content-click="false"
      :nudge-right="40"
      :return-value.sync="timeOfSent"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          class="inline-block mr-2 px-2 py-1 rounded elevation-2"
          :class="btnColor || btnTextColor ? btnTextColor+' '+btnColor : 'grey black--text darken-1'"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon v-if="$vuetify.breakpoint.mdAndUp" :class="btnTextColor ? btnTextColor : 'black--text'">mdi-clock-time-four-outline</v-icon>
          {{$vuetify.breakpoint.mdAndUp ? timeOfSent : timeOfSent.substr(0, 5)}}
        </div>
      </template>
      <v-time-picker
        format="24hr"
        no-title
        v-if="timeModal"
        v-model="timeOfSent"
        full-width
        use-seconds
        @click:minute="$refs.menu.save(timeOfSent)"
      >
      <template>
        <div class="text-center fill-width mb-2">
          {{date.string}}
          <v-btn small rounded @click="timeModal = false" class="ml-2 grey">Set</v-btn>
        </div>
      </template>
      </v-time-picker>
    </v-menu>

    <!-- ADD TIME -->
    <div v-if="!hideMinutesManipulation" class="inline-block mr-2">
      <v-btn x-small :class="btnTextColor ? btnTextColor : 'black--text'" v-tooltip="'Minus 3 minutes'" icon @click="changeMinutes(-3)"><v-icon>mdi-minus-box</v-icon></v-btn>
      <v-btn x-small :class="btnTextColor ? btnTextColor : 'black--text'" v-tooltip="'Plus 3 minutes'" icon @click="changeMinutes(3)"><v-icon>mdi-plus-box</v-icon></v-btn>
    </div>

    <!-- NOW -->
    <v-btn
      v-tooltip="'Set to today:<br>'+$moment().format('dd DD.MM. @ HH:mm')"
      class="inline-block mr-2 px-0 py-1 rounded elevation-2"
      :class="btnColor || btnTextColor ? btnTextColor+' '+btnColor : 'grey black--text darken-1'"
      :style="{'minWidth': $vuetify.breakpoint.mdAndDown ? '35px': ''}"
      @click="setSpecificTime(new Date())"
    > 
      <!-- Today -->
      <v-icon class="">mdi-calendar-today</v-icon>
    </v-btn>

    <!-- REF DAY -->
    <v-btn
      v-if="refDay"
      v-tooltip="'Set to reference day:<br>'+$moment(refDay).format('dd DD.MM. @ HH:mm') + '<br><small>(Change this in the settings)</small>'"
      class="inline-block mr-2 px-0 py-1 rounded elevation-2"
      :class="btnColor || btnTextColor ? btnTextColor+' '+btnColor : 'grey black--text darken-1'"
      :style="{'minWidth': $vuetify.breakpoint.mdAndDown ? '35px': ''}"
      @click="setSpecificTime(refDay)"
    >
      <!-- Ref.Day -->
      <v-icon class="">mdi-calendar-star</v-icon>
    </v-btn>

    <!-- <pre>{{date}}</pre> -->
    <!-- <pre>triggerTimeUpdate: {{triggerTimeUpdate}}</pre> -->

  </div>
</template>

<script>

export default {
  name: 'TimeEdit',
  props: {
    lastDate: Object, //Object,
    label: String,
    btnColor: String,
    btnTextColor: String,
    triggerTimeUpdate: Object,
    hideMinutesManipulation: Boolean,
    refDay: Object,
  },

  data () {
    return {
      dateOfSent: this.$moment(this.lastDate).format('YYYY-MM-DD'),
      dateModal: false,
      timeOfSent: this.$moment(this.lastDate).format('HH:mm:ss'),
      timeModal: false,
    }
  },
  watch: {
    triggerTimeUpdate(value) {
      this.setSpecificTime(value);
    },
  },
  computed: {
    date() {
      return this.setDate(this.dateOfSent+'T'+this.timeOfSent);  //+':00'
    }
  },
  created() {
    if(this.lastDate) {
      this.setDate(this.lastDate);
    }
  },
  methods: {
    setDate(timestamp) {
      let jsDate = this.$moment(timestamp)
      let unix = jsDate.unix();
      let fbTimestamp = this.$helpers.restoreFbTimestampFromJsDate({'seconds': unix});
      let newDate = {
          'string': this.dateOfSent +' '+ this.timeOfSent,
          'fbTimestamp': fbTimestamp,
          'jsDate': jsDate,
          'unix': unix,
          'human': this.$helpers.jsTimeRelativeToNow(jsDate),
        }
      this.$emit('newDate', newDate);
      return newDate;
    },



    incrementTime() {
/*       let minutes = this.$helpers.randomBetween(1,12);
      let timestamp = this.$moment(this.lastDate).add(minutes, 'minutes');
      this.setDate(this.lastDate);
 */    },

    setSpecificTime(timestamp) {
      this.dateOfSent = this.$moment(timestamp).format('YYYY-MM-DD');
      this.timeOfSent = this.$moment(timestamp).format('HH:mm:ss');
      this.setDate(timestamp)
    },
    changeDay(days) {
      let timestamp = this.$moment(this.date.jsDate).add(days, 'days');
      // console.log("changeDay: ", days, timestamp);
      this.dateOfSent = this.$moment(timestamp).format('YYYY-MM-DD');
      this.timeOfSent = this.$moment(timestamp).format('HH:mm:ss');
      this.setDate(timestamp)
    },
    changeMinutes(minutes) {
      let timestamp = this.$moment(this.date.jsDate).add(minutes, 'minutes');
      // console.log("changeMinutes: ", minutes, timestamp);
      this.dateOfSent = this.$moment(timestamp).format('YYYY-MM-DD');
      this.timeOfSent = this.$moment(timestamp).format('HH:mm:ss');
      this.setDate(timestamp)
    },
    changeSeconds(seconds) {
      let timestamp = this.$moment(this.date.jsDate).add(seconds, 'seconds');
      // console.log("changeseconds: ", seconds, timestamp);
      this.dateOfSent = this.$moment(timestamp).format('YYYY-MM-DD');
      this.timeOfSent = this.$moment(timestamp).format('HH:mm:ss');
      this.setDate(timestamp)
    },
  }
}
</script>

<style scoped>
</style>